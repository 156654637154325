import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { Artist } from "types";
import ReactECharts from "echarts-for-react";
import * as eChart from "echarts/core";
import italyJson from "resources/geo/italy_regions.json";

interface EventsByRegionsChartProps {
  startDate: string;
  endDate: string;
  selectedArtists: Artist[] | undefined;
}

type EventsByRegionsData = {
  name: string;
  value: number;
};

const EventsByRegionsChart: React.FC<EventsByRegionsChartProps> = ({
  startDate,
  endDate,
  selectedArtists,
}) => {
  const [regionsEvents, setRegionEvents] = useState<EventsByRegionsData[]>([]);
  const [t] = useTranslation();

  const getRegionEvents = useCallback(async () => {
    try {
      const { data } = await axios.get<{ data: EventsByRegionsData[] }>(
        "/api/analytics/region-events",
        {
          params: {
            start: startDate,
            end: endDate,
            artistIds: selectedArtists?.map((a) => a.id).join(","),
          },
        }
      );
      setRegionEvents(data.data);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          window.location.href = "/login";
        }
        toast.error(error.response?.data);
      } else {
        toast.error("Something went wrong! Cannot fetch locations");
      }
    }
  }, [startDate, endDate, selectedArtists]);

  useEffect(() => {
    getRegionEvents();
  }, [startDate, endDate, selectedArtists, getRegionEvents]);

  eChart.registerMap("IT", italyJson as any);

  const options = {
    title: {
      text: t("Events by Region"),
      left: "start",
      textStyle: {
        fontFamily: "Public Sans",
        fontSize: "1rem",
        fontWeight: 600,
        color: "#25252D",
      },
    },
    tooltip: {
      trigger: "item",
    },
    visualMap: {
      left: 0,
      inRange: {
        color: ["#87CEFA", "#FFFF00", "#ff4500"],
      },
      calculable: true,
      min: 0,
      max: 250,
      text: [t("High"), t("Low")],
    },
    series: [
      {
        name: t("Region"),
        type: "map",
        roam: false,
        map: "IT",
        colorBy: "data",
        label: {
          show: true,
          formatter: (p: any) => {
            return Number.isNaN(p.value) ? "" : p.value;
          },
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        data: regionsEvents,
      },
    ],
  };
  return <ReactECharts option={options} style={{ height: "450px" }} />;
};

export default EventsByRegionsChart;
