import { FieldValidationResponse, FormValidationResponse, ValidationModel } from "../types/Validators";

/**
 * Function that validate a field of a form
 * 
 * @param {string} fieldName The field name to validate
 * @param {string | Array<any>} fieldValue The field value to validate
 * @param {ValidationModel} validationModel The validation model
 * @param {F} form The form object
 * @returns {FieldValidationResponse} If the field is valid or not and, if not, the errors to display
 */
export const validateInput = <F,>(fieldName: string, fieldValue: string | Array<any>, validationModel: ValidationModel, form: F): FieldValidationResponse => {
    let validation: FieldValidationResponse = { isValid: true, errors: [] }
    let field = validationModel.find((v) => v.fieldName === fieldName)
    if (field) {
        Object.entries(field.validators).forEach(([key, value]) => {
            switch (key) {
                case "required":
                    if (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0)) {
                        validation.isValid = false
                        validation.errors = [...validation.errors, value.message]
                    }
                    break;
                case "email":
                    if (typeof fieldValue === "string" && !/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(fieldValue)) {
                        validation.isValid = false
                        validation.errors = [...validation.errors, value.message]
                    }
                    break;
                case "minLength":
                    if (fieldValue.length < value.value) {
                        validation.isValid = false
                        validation.errors = [...validation.errors, value.message]
                    }
                    break;
                case "equalTo":
                    if (fieldValue !== form[value.value as keyof F]) {
                        validation.isValid = false
                        validation.errors = [...validation.errors, value.message]
                    }
                    break;
                case "greaterThan":
                    if (fieldValue <= value.value) {
                        validation.isValid = false
                        validation.errors = [...validation.errors, value.message]
                    }
                    break;
                case "lessThan":
                    if (fieldValue >= value.value) {
                        validation.isValid = false
                        validation.errors = [...validation.errors, value.message]
                    }
                    break;
                case "requiredIf":
                    if (!fieldValue && form[value.value as keyof F] === true) {
                        validation.isValid = false
                        validation.errors = [...validation.errors, value.message]
                    }
                    break;
                default:
                    break;
            }
        })
    }
    return validation
}

/**
 * Function that validate all the fields of a form
 * 
 * @param {F} form The form object
 * @param {ValidationModel} validationModel The validation model
 * @returns {FormValidationResponse<F>} If the form is valid or not and if not, all the fields that have an error
 */
export const validateForm = <F extends object,>(form: F, validationModel: ValidationModel): FormValidationResponse<F> => {
    let validation: FormValidationResponse<F> = { isValid: true, errors: {} }
    Object.entries(form).forEach(([fieldName, fieldValue]) => {
        let inputValidation = validateInput(fieldName, fieldValue, validationModel, form)
        if (!inputValidation.isValid) {
            validation.isValid = false;
            validation.errors = { ...validation.errors, [fieldName]: inputValidation.errors };
        }
    })
    return validation
}