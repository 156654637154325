import { GridSortModel } from "@mui/x-data-grid";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { EventLocation } from "types";
import { fetchAllLocations } from "utils";

interface LocationsContextProps {
  locations: EventLocation[];
  sort: GridSortModel;
  filter: string;
  totalItems: number;
  getLocations: () => void;
  onSetLocations: (locations: EventLocation[]) => void;
  onSetSort: (sortModel: GridSortModel) => void;
  onSetFilter: (filter: string) => void;
}

const LocationsContext = React.createContext<LocationsContextProps>({
  locations: [],
  sort: [{ field: "created_at", sort: "asc" }],
  filter: "",
  totalItems: 0,
  getLocations: () => {},
  onSetLocations: (locations: EventLocation[]) => {},
  onSetSort: (sortModel: GridSortModel) => {},
  onSetFilter: (filter: string) => {},
});

const LocationsProvider = ({ children }: { children: React.ReactNode }) => {
  const [locations, setLocations] = useState<EventLocation[]>([]);
  const [sort, setSort] = useState<GridSortModel>([
    { field: "created_at", sort: "asc" },
  ]);
  const [filter, setFilter] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  const getLocations = useCallback(async () => {
    const data = await fetchAllLocations({
      sort: `${sort[0].field}:${sort[0].sort}`,
      search: filter,
    });
    if (data) {
      setLocations(data.data);
      setTotalItems(data.total);
    }
  }, [sort, filter]);

  const onSetLocations = (locations: EventLocation[]) => {
    setLocations(locations);
  };

  const onSetSort = (sortModel: GridSortModel) => {
    setSort(sortModel);
  };
  const onSetFilter = (filter: string) => {
    setFilter(filter);
  };

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  let value: LocationsContextProps = {
    locations,
    sort,
    filter,
    totalItems,
    getLocations,
    onSetLocations,
    onSetSort,
    onSetFilter,
  };

  return (
    <LocationsContext.Provider value={value}>
      {children}
    </LocationsContext.Provider>
  );
};

export default LocationsProvider;

export const useEventLocations = () => {
  const locations = useContext(LocationsContext);
  return locations;
};
