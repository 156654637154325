import React from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Sheet,
  Typography,
} from "@mui/joy";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import FmdGoodRoundedIcon from "@mui/icons-material/FmdGoodRounded";
import TheaterComedyRoundedIcon from "@mui/icons-material/TheaterComedyRounded";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "context/AuthorizationContext";
import { IllustrationImg } from "components/styled_components";
import adminIllustration from "resources/admin_illustration.svg";
import { useAuthentication } from "context/AuthenticationContext";
import { Delete } from "@mui/icons-material";

interface AdminLayoutProps {}

const AdminLayout: React.FC<AdminLayoutProps> = () => {
  const [t] = useTranslation();
  const rootAdminMatch = useMatch("admin");
  const usersMatch = useMatch("admin/users/*");
  const locationsMatch = useMatch("admin/locations/*");
  const artistsMatch = useMatch("admin/artists/*");
  const trashMatch = useMatch("admin/trash");
  const navigate = useNavigate();
  const { isAllowed } = useAuthorization();
  const { user } = useAuthentication();

  return (
    <Box display="flex" height="100%">
      {/* Sidemenu */}
      <Box flex="0 1 250px" py={2} pl={2}>
        <List
          color="primary"
          sx={{
            "--ListItem-minHeight": "2rem",
            "--ListItem-radius": "8px",
            "--ListItem-paddingY": "0.3rem",
          }}
        >
          {isAllowed("users.read") ? (
            <ListItemButton
              selected={usersMatch != null}
              variant={usersMatch != null ? "soft" : "plain"}
              onClick={() => navigate("users")}
            >
              <ListItemDecorator>
                <GroupRoundedIcon />
              </ListItemDecorator>
              <ListItemContent>{t("Users")}</ListItemContent>
            </ListItemButton>
          ) : null}
          {isAllowed("locations.read") ? (
            <ListItemButton
              selected={locationsMatch != null}
              variant={locationsMatch != null ? "soft" : "plain"}
              onClick={() => navigate("locations")}
            >
              <ListItemDecorator>
                <FmdGoodRoundedIcon />
              </ListItemDecorator>
              <ListItemContent>{t("Locations")}</ListItemContent>
            </ListItemButton>
          ) : null}
          {isAllowed("artists.read") ? (
            <ListItemButton
              selected={artistsMatch != null}
              variant={artistsMatch != null ? "soft" : "plain"}
              onClick={() => navigate("artists")}
            >
              <ListItemDecorator>
                <TheaterComedyRoundedIcon />
              </ListItemDecorator>
              <ListItemContent>{t("Artists")}</ListItemContent>
            </ListItemButton>
          ) : null}
          {isAllowed("documents.read") && user?.isSystem ? (
            <ListItemButton
              selected={trashMatch != null}
              variant={trashMatch != null ? "soft" : "plain"}
              onClick={() => navigate("trash")}
            >
              <ListItemDecorator>
                <Delete />
              </ListItemDecorator>
              <ListItemContent>{t("Trash")}</ListItemContent>
            </ListItemButton>
          ) : null}
        </List>
      </Box>
      <Box flex="1 1" p={2}>
        <Sheet
          color="neutral"
          sx={{
            width: "100%",
            height: "100%",
            borderRadius: 16,
            boxSizing: "border-box",
            p: 2,
          }}
        >
          {/* If the user enter the root admin section a welcome screen will be displayed */}
          {rootAdminMatch !== null ? (
            <Box
              display="flex"
              width={"100%"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <IllustrationImg
                src={adminIllustration}
                alt="admin_panel"
                width={500}
                sx={{ mb: 2 }}
              />
              <Typography level="h6" fontWeight={600}>
                {t("Welcome to the admin section")}
              </Typography>
              <Typography level="body2">
                {t(
                  "From here you can manage all the settings and resources of the application"
                )}
              </Typography>
            </Box>
          ) : (
            <Outlet />
          )}
        </Sheet>
      </Box>
    </Box>
  );
};

export default AdminLayout;
