import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Typography,
} from "@mui/joy";
import { lighten } from "@mui/material";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import axios from "axios";
import { formatISO } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { Artist, ArtistForm, UnavailableDates } from "types";
import { ValidationModel } from "types/Validators";
import { validateForm, validateInput } from "utils/validators";

interface EditArtistProps {}

const EditArtist: React.FC<EditArtistProps> = () => {
  const { id } = useParams();
  const [artist, setArtist] = useState<ArtistForm>({
    name: "",
    address: "",
    vat: "",
    color: "#000000",
  });
  const [unavailableDates, setUnavailableDates] = useState<string[]>([]);
  const [validationModel] = useState<ValidationModel>([
    {
      fieldName: "name",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "color",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
  ]);
  const [errors, setErrors] = useState<{ [fieldName: string]: string[] }>({});
  const navigate = useNavigate();
  const [t] = useTranslation();

  const fetchArtist = useCallback(async () => {
    try {
      const {
        data: {
          data: { address, color, name, vat },
        },
      } = await axios.get<{ data: Artist }>(`/api/artists/${id}`);
      setArtist({ address, color, name, vat });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch artist"));
      }
    }
  }, [id, navigate, t]);

  const fetchUnavailableDates = useCallback(async () => {
    try {
      const { data } = await axios.get<{ data: UnavailableDates[] }>(
        `/api/artists/${id}/unavailable-dates`
      );
      setUnavailableDates(data.data.map((d) => d.date));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch unavailable dates"));
      }
    }
  }, [id, navigate, t]);

  const updateArtist = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const formValidation = validateForm(artist, validationModel);
      if (formValidation.isValid) {
        try {
          const { status } = await axios.patch(`/api/artists/${id}`, artist);
          if (status === 200) {
            toast.success(t("Artist updated with success"));
            navigate("/admin/artists");
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            //Fore the user logout if the request is unauthorized
            if (error.response?.status === 401) {
              navigate("/login");
            }
            console.log(error.response?.data);
            toast.error(error.response?.data.error);
          } else {
            toast.error(t("Something went wrong! Cannot update artist"));
          }
        }
      } else {
        setErrors(formValidation.errors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [artist, validationModel, navigate]
  );

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let validation = validateInput(
      e.currentTarget.name,
      e.currentTarget.value,
      validationModel,
      artist
    );
    if (validation) {
      setErrors({ ...errors, [e.currentTarget.name]: validation.errors });
    }
  };

  const updateUnavailableDates = async (dates: string[]) => {
    try {
      await axios.post(`/api/artists/${id}/unavailable-dates`, {
        dates,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        toast.error(error.response?.data);
        console.log(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot update unavailable dates"));
      }
    }
  };

  const onDayClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const formattedDate = `${formatISO(
      parseInt(e.currentTarget.getAttribute("data-timestamp")!),
      { representation: "date" }
    )}T00:00:00Z`;
    let newDates = [...unavailableDates];
    if (unavailableDates.includes(formattedDate)) {
      newDates = newDates.filter((d) => d !== formattedDate);
    } else {
      newDates = [...newDates, formattedDate];
    }
    setUnavailableDates(newDates);
    updateUnavailableDates(newDates);
  };

  const UnavailableDay = (props: PickersDayProps<Date>) => {
    const { day, outsideCurrentMonth, ...otherProps } = props;

    const isUnavailable = unavailableDates.includes(
      `${formatISO(day, { representation: "date" })}T00:00:00Z`
    );

    return (
      <PickersDay
        {...otherProps}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        aria-readonly={isUnavailable}
        disableRipple
        disableTouchRipple
        focusRipple
        sx={{
          backgroundColor: isUnavailable
            ? (theme) => lighten(theme.palette.error.light, 0.5)
            : undefined,
          "&:hover": {
            backgroundColor: isUnavailable
              ? (theme) => lighten(theme.palette.error.dark, 0.5)
              : undefined,
          },
          "&.Mui-selected": {
            backgroundColor: isUnavailable
              ? (theme) => lighten(theme.palette.error.light, 0.5)
              : "transparent",
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            color: (theme) => theme.palette.common.black,
          },
          "&:hover.Mui-selected": {
            backgroundColor: isUnavailable
              ? (theme) => lighten(theme.palette.error.light, 0.5)
              : "transparent",
          },
          "&:focus.Mui-selected": {
            backgroundColor: isUnavailable
              ? (theme) => lighten(theme.palette.error.light, 0.5)
              : "transparent",
          },
        }}
      />
    );
  };

  useEffect(() => {
    fetchArtist();
    fetchUnavailableDates();
  }, [fetchArtist, fetchUnavailableDates]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display="flex"
      flexDirection="column"
      rowGap={2}
      sx={(theme) => ({
        boxSizing: "border-box",
        [theme.breakpoints.down("sm")]: {
          p: 2,
        },
      })}
    >
      <Typography level="h4" fontWeight={600}>
        {t("Edit Artist")}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        width={"100%"}
        flex={"1 1"}
        alignContent={"space-between"}
        component={"form"}
        onSubmit={updateArtist}
        noValidate
      >
        <Box display="flex" width={"100%"} flexWrap="wrap" gap={2}>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width={"100%"}
            columnGap={2}
            justifyContent={"flex-start"}
          >
            {/* Name */}
            <FormControl
              required
              sx={(theme) => ({
                flex: "0.4 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"name" in errors && errors["name"].length > 0}
            >
              <FormLabel>{t("Artist Name")}</FormLabel>
              <Input
                name="name"
                type="text"
                value={artist.name}
                onChange={(e) =>
                  setArtist({ ...artist, name: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["name"] && errors["name"].length > 0
                ? errors["name"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
            {/* Color */}
            <FormControl
              required
              sx={(theme) => ({
                flex: "0.1 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"color" in errors && errors["color"].length > 0}
            >
              <FormLabel>{t("Artist Color")}</FormLabel>
              <Input
                name="color"
                type="color"
                value={artist.color}
                onChange={(e) =>
                  setArtist({ ...artist, color: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["color"] && errors["color"].length > 0
                ? errors["color"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width={"100%"}
            columnGap={2}
            justifyContent={"flex-start"}
          >
            {/* Address */}
            <FormControl
              sx={(theme) => ({
                flex: "0.4 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"address" in errors && errors["address"].length > 0}
            >
              <FormLabel>{t("Address")}</FormLabel>
              <Input
                name="address"
                type="text"
                value={artist.address}
                onChange={(e) =>
                  setArtist({ ...artist, address: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["address"] && errors["address"].length > 0
                ? errors["address"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
            {/* Vat */}
            <FormControl
              sx={(theme) => ({
                flex: "0.4 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"vat" in errors && errors["vat"].length > 0}
            >
              <FormLabel>{t("Vat")}</FormLabel>
              <Input
                name="vat"
                type="text"
                value={artist.vat}
                onChange={(e) =>
                  setArtist({ ...artist, vat: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["vat"] && errors["vat"].length > 0
                ? errors["vat"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
          </Box>
          <Typography level="h6" fontWeight={600} color="neutral">
            {t("Unavailable Dates")}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width={"100%"}
            columnGap={2}
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                justifyContent: "center",
              },
            })}
          >
            <DateCalendar
              //defaultValue={intialValue}

              sx={{ margin: "-12px 0 0 0" }}
              slots={{
                day: UnavailableDay,
              }}
              disableHighlightToday
              slotProps={{
                day: {
                  onClick: onDayClick,
                },
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"flex-end"}
          alignSelf={"flex-end"}
          columnGap={2}
          width={"100%"}
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column-reverse",
              mb: 2,
            },
          })}
        >
          <Button
            variant="plain"
            onClick={() => navigate("/admin/artists")}
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                mt: 2,
                flex: "1 1",
              },
            })}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                mt: 2,
                flex: "1 1",
              },
            })}
          >
            {t("Confirm")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditArtist;
