import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Delete,
  Search,
  Check,
  ArrowBack,
  RestoreFromTrash,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemDecorator,
  ListItemContent,
  Avatar,
  ListItemButton,
  ListItem as MUIListItem,
  Input,
  CircularProgress,
  Typography,
  LinearProgress,
} from "@mui/joy";
import { useAuthorization } from "context/AuthorizationContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList, areEqual } from "react-window";
import { EventDocument, ListResponse } from "types";
import { GridSortModel } from "@mui/x-data-grid";
import axios from "axios";
import { toast } from "sonner";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { usePrevious } from "hooks";
import { useDialogManger } from "context/DialogManagerContext";
import JpgIcon from "components/icons/JpgIcon";
import PdfIcon from "components/icons/PdfIcon";
import PngIcon from "components/icons/PngIcon";
import { CustomNoRowsOverlay } from "components/styled_components/DataGridOverlays";

const RenderFileIcon = ({ ext }: { ext: string }) => {
  switch (ext) {
    case ".pdf":
      return <PdfIcon color="primary" />;
    case ".png":
      return <PngIcon color="primary" />;
    case ".jpg":
    case ".jpeg":
      return <JpgIcon color="primary" />;
    default:
      return null;
  }
};

const ListItem = memo(
  ({
    data,
    index,
    style,
  }: {
    data: {
      docs: EventDocument[];
      selectedRows: string[];
      isItemLoaded: (index: number) => boolean;
      setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
    };
    index: number;
    style: CSSProperties;
  }) => {
    const { docs, isItemLoaded, selectedRows, setSelectedRows } = data;
    if (!isItemLoaded(index)) {
      return (
        <MUIListItem style={style}>
          <CircularProgress size="sm" variant="plain" />
        </MUIListItem>
      );
    }
    return (
      <ListItemButton
        style={style}
        sx={{
          px: 2,
          gap: 2,
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (!selectedRows.includes(docs[index].id)) {
            setSelectedRows([...selectedRows, docs[index].id]);
          } else {
            setSelectedRows(selectedRows.filter((r) => r !== docs[index].id));
          }
        }}
      >
        <ListItemDecorator>
          <Avatar color="primary">
            {selectedRows.includes(docs[index].id) ? (
              <Check />
            ) : (
              <RenderFileIcon ext={docs[index].extension} />
            )}
          </Avatar>
        </ListItemDecorator>
        <ListItemContent>
          <Typography>{docs[index].sourceName}</Typography>
        </ListItemContent>
      </ListItemButton>
    );
  },
  areEqual
);

const TrashMobileList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState<EventDocument[]>([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(25);
  const [totalItems, setTotalItems] = useState(0);
  const [sort] = useState<GridSortModel>([
    { field: "deleted_at", sort: "asc" },
  ]);
  const [filter, setFilter] = useState("");
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { showDialog } = useDialogManger();
  const { isAllowed } = useAuthorization();
  let filterTimeout = useRef<NodeJS.Timeout>(null!);
  const prevFilter = usePrevious(filter);
  const itemCount = docs.length < totalItems ? docs.length + 1 : docs.length;

  const fetchDocs = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<ListResponse<EventDocument>>(
        "/api/trash",
        {
          params: {
            page: docs.length !== totalItems ? page + 1 : page,
            limit,
            sort: `${sort[0].field}:${sort[0].sort}`,
            search: filter,
          },
        }
      );
      setDocs(data.data);
      setPage(data.page);
      setTotalItems(data.total);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
          return;
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch documents"));
      }
    }
    setLoading(false);
  }, [docs, totalItems, page, limit, sort, filter, navigate, t]);

  const fetchMoreDocs = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<ListResponse<EventDocument>>(
        "/api/trash",
        {
          params: {
            page: page + 1,
            limit,
            sort: `${sort[0].field}:${sort[0].sort}`,
            search: filter,
          },
        }
      );
      setDocs([...docs, ...data.data]);
      setPage(data.page);
      setTotalItems(data.total);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
          return;
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch documents"));
      }
    }
    setLoading(false);
  };

  const isItemLoaded = (index: number) =>
    !(docs.length < totalItems) || index < docs.length;

  const loadMoreItems = loading ? () => {} : fetchMoreDocs;

  const onDeleteDocs = async () => {
    try {
      const { status } = await axios.post(`/api/trash/remove-documents`, {
        documentIds: selectedRows,
      });
      if (status === 200) {
        setSelectedRows([]);
        fetchDocs();
        toast.success(t("Documents successfully deleted"));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
          return;
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot delete documents"));
      }
    }
    setLoading(false);
  };

  const onRestoreDocs = async () => {
    try {
      const { status } = await axios.post(`/api/trash/restore-documents`, {
        documentIds: selectedRows,
      });
      if (status === 200) {
        setSelectedRows([]);
        fetchDocs();
        toast.success(t("Documents successfully restored"));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
          return;
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot restore documents"));
      }
    }
    setLoading(false);
  };

  const onDeleteClick = () => {
    showDialog({
      title: "Delete the selected documents?",
      body: "If you proceed with this action, the selected documents will be deleted permanently and they can't be recovered anymore.",
      actions: [
        {
          label: "Cancel",
          color: "neutral",
          variant: "plain",
          onClick: (e, closeModal) => {
            closeModal();
          },
        },
        {
          label: "Delete",
          color: "danger",
          variant: "solid",
          onClick: (e, closeModal) => {
            onDeleteDocs();
            closeModal();
          },
        },
      ],
    });
  };

  const onRestoreClick = () => {
    showDialog({
      title: "Restore the selected documents?",
      body: "If you proceed with this action, the selected documents will be restored and shows as attachments in their own event.",
      actions: [
        {
          label: "Cancel",
          color: "neutral",
          variant: "plain",
          onClick: (e, closeModal) => {
            closeModal();
          },
        },
        {
          label: "Restore",
          color: "primary",
          variant: "solid",
          onClick: (e, closeModal) => {
            onRestoreDocs();
            closeModal();
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (prevFilter !== filter && prevFilter !== undefined) {
      if (filterTimeout.current) {
        clearTimeout(filterTimeout.current);
      }
      filterTimeout.current = setTimeout(() => {
        fetchDocs();
      }, 500);

      return () => {
        clearTimeout(filterTimeout.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, prevFilter]);

  useEffect(() => {
    fetchDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box position={"absolute"} top={0} left={0} width={"100vw"}>
        {loading ? <LinearProgress /> : null}
      </Box>
      <Typography level="h4" fontWeight={600} pt={2} px={2}>
        {t("Deleted Documents")}
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"row"}
        width={"100%"}
        py={2}
        px={1}
        boxSizing={"border-box"}
        justifyContent={selectedRows.length > 0 ? "flex-end" : "flex-start"}
        alignItems={"center"}
      >
        {selectedRows.length > 0 &&
        (isAllowed("documents.write") || isAllowed("documents.delete")) ? (
          <Box
            display={"flex"}
            flexDirection={"row"}
            flex={"1 1"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              flex={"1 1"}
              alignItems={"center"}
              gap={2}
            >
              <IconButton
                variant="plain"
                color="neutral"
                onClick={() => setSelectedRows([])}
              >
                <ArrowBack />
              </IconButton>
              <Typography level="h6">{selectedRows.length}</Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              flex={"1 1"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              {isAllowed("documents.write") ? (
                <IconButton
                  variant="plain"
                  color="neutral"
                  onClick={onRestoreClick}
                >
                  <RestoreFromTrash />
                </IconButton>
              ) : null}
              {isAllowed("documents.delete") ? (
                <IconButton
                  variant="plain"
                  color="danger"
                  onClick={onDeleteClick}
                >
                  <Delete />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        ) : (
          <Input
            size="sm"
            startDecorator={<Search />}
            fullWidth
            value={filter}
            placeholder={`${t("Type to search")}...`}
            onChange={(e) => setFilter(e.currentTarget.value)}
          />
        )}
      </Box>
      <Box flex={"1 1 auto"} boxSizing={"border-box"} py={1}>
        <AutoSizer>
          {({ height, width }: Size) => (
            <InfiniteLoader
              threshold={5}
              itemCount={itemCount}
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  itemCount={itemCount}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  height={height}
                  width={width}
                  itemSize={60}
                  innerElementType={
                    docs.length === 0
                      ? () => <CustomNoRowsOverlay label="No documents found" />
                      : "div"
                  }
                >
                  {ListItem}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </Box>
    </>
  );
};

export default TrashMobileList;
