import React from "react";
import { Outlet } from "react-router-dom";

interface ArtistsProps {}

const Artists: React.FC<ArtistsProps> = () => {
  return <Outlet />;
};

export { default as CreateArtist } from "./Create";
export { default as EditArtist } from "./Edit";
export { default as ArtistsList } from "./List";
export default Artists;
