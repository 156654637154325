import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Button, Input, Typography } from "@mui/joy";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ListResponse, UserIdentity } from "types";
import { LinearProgress } from "@mui/material";
import { Search, Add, Delete, Key } from "@mui/icons-material";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { parseListDate } from "utils/dateParser";
import DGContextMenu from "components/DGContextMenu";
import UserDetailsModal from "./DetailsModal";
import ChangeUserPasswordModal from "views/Admin/Users/ChangeUserPasswordModal";
import { StyledCheckbox } from "components/styled_components";
import { useDialogManger } from "context/DialogManagerContext";
import { usePrevious } from "hooks";
import { toast } from "sonner";
import { CustomNoRowsOverlay } from "components/styled_components/DataGridOverlays";
import { useAuthorization } from "context/AuthorizationContext";

interface UsersListProps {}

const UsersList: React.FC<UsersListProps> = () => {
  const [loading, setLoading] = useState(true);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [changePwdOpen, setChangePwdOpen] = useState(false);
  const [users, setUsers] = useState<UserIdentity[]>([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [totalItems, setTotalItems] = useState(0);
  const [sort, setSort] = useState<GridSortModel>([
    { field: "created_at", sort: "asc" },
  ]);
  const [filter, setFilter] = useState("");
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [selectedRow, setSelectedRow] = useState("");
  const navigate = useNavigate();
  const { isAllowed } = useAuthorization();
  const [t] = useTranslation();
  const { showDialog } = useDialogManger();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  let filterTimeout = useRef<NodeJS.Timeout>(null!);
  const prevFilter = usePrevious(filter);

  const parseLanguage = useCallback(
    (lang: string) => {
      switch (lang) {
        case "it_IT":
          return t("Italian");
        case "en_EN":
          return t("English");
        default:
          return "";
      }
    },
    [t]
  );

  const columns = useMemo<GridColDef<UserIdentity>[]>(
    () => [
      {
        field: "first_name",
        headerName: `${t("First name")}`,
        sortable: true,
        filterable: false,
        flex: 1,
        hideable: false,
        renderCell: ({ row }) => (
          <div className="MuiDataGrid-cellContent" title={row.firstName}>
            {row.firstName}
          </div>
        ),
      },
      {
        field: "last_name",
        headerName: `${t("Last name")}`,
        sortable: true,
        filterable: false,
        flex: 1,
        hideable: false,
        renderCell: ({ row }) => (
          <div className="MuiDataGrid-cellContent" title={row.lastName}>
            {row.lastName}
          </div>
        ),
      },
      {
        field: "email",
        headerName: `${t("Email")}`,
        sortable: true,
        filterable: false,
        flex: 1,
        hideable: false,
      },
      {
        field: "lang",
        headerName: `${t("Language")}`,
        sortable: true,
        filterable: false,
        hideable: false,
        renderCell: ({ row }) => <>{parseLanguage(row.lang)}</>,
      },
      {
        field: "created_at",
        headerName: `${t("Created at")}`,
        sortable: true,
        filterable: false,
        hideable: false,
        flex: 1,
        renderCell: ({ row }) => <>{parseListDate(row.createdAt)}</>,
      },
      {
        field: "updated_at",
        headerName: `${t("Updated at")}`,
        sortable: true,
        filterable: false,
        hideable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <>{row.updatedAt ? parseListDate(row.updatedAt) : ""}</>
        ),
      },
    ],
    [t, parseLanguage]
  );

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedRow(event.currentTarget.getAttribute("data-id")!);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<ListResponse<UserIdentity>>(
        "/api/users",
        {
          params: {
            page: page + 1,
            limit,
            sort: `${sort[0].field}:${sort[0].sort}`,
            search: filter,
          },
        }
      );
      setUsers(data.data);
      setTotalItems(data.total);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch users"));
      }
    }
    setLoading(false);
  }, [page, sort, limit, filter, navigate, t]);

  const onDeleteUsers = async () => {
    try {
      const { status } = await axios.post(`/api/users/delete`, {
        userIds: selectedRows,
      });
      if (status === 200) {
        setSelectedRows([]);
        fetchUsers();
        toast.success(t("Users successfully deleted"));
        //Close the context menu when item is deleted
        handleClose();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot delete users"));
      }
    }
    setLoading(false);
  };

  const onDeleteUsersClick = () => {
    showDialog({
      title: "Delete the selected users?",
      body: "If you proceed with this action, the selected users will be deleted and they can't login or use the platform anymore.",
      actions: [
        {
          label: "Cancel",
          color: "neutral",
          variant: "plain",
          onClick: (e, closeModal) => {
            closeModal();
          },
        },
        {
          label: "Delete",
          color: "danger",
          variant: "solid",
          onClick: (e, closeModal) => {
            onDeleteUsers();
            closeModal();
          },
        },
      ],
    });
  };

  const onDeleteUser = async () => {
    try {
      const { status } = await axios.delete(`/api/users/${selectedRow}`);
      if (status === 200) {
        setSelectedRow("");
        fetchUsers();
        toast.success(t("User successfully deleted"));
        //Close the context menu when item is deleted
        handleClose();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot delete user"));
      }
    }
    setLoading(false);
  };

  const onDeleteUserClick = () => {
    showDialog({
      title: "Delete the selected user?",
      body: "If you proceed with this action, the selected user will be deleted and it can't login or use the platform anymore.",
      actions: [
        {
          label: "Cancel",
          color: "neutral",
          variant: "plain",
          onClick: (e, closeModal) => {
            closeModal();
          },
        },
        {
          label: "Delete",
          color: "danger",
          variant: "solid",
          onClick: (e, closeModal) => {
            onDeleteUser();
            closeModal();
          },
        },
      ],
    });
  };

  const onSortChange = (
    model: GridSortModel,
    details: GridCallbackDetails<any>
  ) => {
    if (model.length === 0) {
      setSort([{ field: "created_at", sort: "asc" }]);
    } else {
      setSort(model);
    }
  };

  useEffect(() => {
    if (prevFilter !== filter && prevFilter !== undefined) {
      if (filterTimeout.current) {
        clearTimeout(filterTimeout.current);
      }
      filterTimeout.current = setTimeout(() => {
        fetchUsers();
      }, 500);

      return () => {
        clearTimeout(filterTimeout.current);
      };
    } else {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, limit, filter, fetchUsers]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display="flex"
      flexDirection="column"
      rowGap={2}
    >
      <Typography level="h4" fontWeight={600}>
        {t("Users")}
      </Typography>
      <Box
        display="flex"
        alignSelf="flex-end"
        flexDirection="row"
        width={"100%"}
      >
        <Box
          display="flex"
          alignSelf="flex-end"
          flexDirection="row"
          justifyContent="flex-start"
          flex={1}
          columnGap={2}
        >
          {isAllowed("users.write") ? (
            <Button
              variant="solid"
              startDecorator={<Add />}
              color="primary"
              size="sm"
              onClick={() => navigate("new")}
            >
              {t("New")}
            </Button>
          ) : null}
          {isAllowed("users.delete") ? (
            <Button
              variant="soft"
              startDecorator={<Delete />}
              color="danger"
              size="sm"
              disabled={selectedRows.length === 0}
              onClick={onDeleteUsersClick}
            >
              {t("Delete")}
            </Button>
          ) : null}
        </Box>
        <Box
          display="flex"
          alignSelf="flex-end"
          flexDirection="row"
          justifyContent="flex-end"
          flex={1}
        >
          <Input
            size="sm"
            placeholder={`${t("Search")}...`}
            startDecorator={<Search />}
            fullWidth
            value={filter}
            onChange={(e) => setFilter(e.currentTarget.value)}
            sx={{ flex: "0.60 1" }}
          ></Input>
        </Box>
      </Box>

      <Box
        alignSelf="stretch"
        flex={"1 1"}
        maxHeight={"calc( 100% - 100px )"}
        boxSizing={"border-box"}
      >
        <DataGrid
          columns={columns}
          rows={users}
          pagination
          paginationModel={{ page: page, pageSize: limit }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setLimit(model.pageSize);
          }}
          rowCount={totalItems}
          paginationMode="server"
          sortingMode="server"
          sortModel={sort}
          onSortModelChange={onSortChange}
          onRowSelectionModelChange={(selRows) => {
            setSelectedRows(selRows);
          }}
          checkboxSelection
          disableColumnMenu
          density="compact"
          localeText={{
            columnHeaderSortIconLabel: `${t("Sort")}`,
            noRowsLabel: `${t("No users found")}`,
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                t("{{from}}-{{to}} of {{count}}", {
                  from,
                  to,
                  count,
                }),
              labelRowsPerPage: `${t("Rows per page")}:`,
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
          slots={{
            loadingOverlay: LinearProgress,
            baseCheckbox: StyledCheckbox,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              color: "primary",
            },
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" },
            },
          }}
          loading={loading}
        />
        <DGContextMenu
          rowId={selectedRow}
          resource="users"
          open={contextMenu !== null}
          onClose={handleClose}
          onDetail={() => {
            setDetailsOpen(true);
            handleClose();
          }}
          onEdit={() => {
            navigate(selectedRow);
          }}
          onDelete={onDeleteUserClick}
          extraActions={[
            {
              label: "Change password",
              icon: <Key />,
              isAllowed: isAllowed("users.write"),
              onClick: () => {
                setChangePwdOpen(true);
                handleClose();
              },
            },
          ]}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          slotProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              },
            },
          }}
        />
        <UserDetailsModal
          open={detailsOpen}
          userId={selectedRow}
          onClose={() => setDetailsOpen(false)}
        />
        <ChangeUserPasswordModal
          open={changePwdOpen}
          onClose={() => setChangePwdOpen(false)}
          userId={selectedRow}
        />
      </Box>
    </Box>
  );
};

export default UsersList;
