import React, { useContext, useState } from "react";

interface AuthorizationContexProps {
  permissions: string[];
  isAllowed: (actions: string | string[]) => boolean;
  isSomeAllowed: (actions: string[]) => boolean;
  setPermissions: (actions: string[]) => void;
}

const AuthorizationContex = React.createContext<AuthorizationContexProps>({
  permissions: [],
  isAllowed: (actions: string | string[]) => false,
  isSomeAllowed: (actions: string[]) => false,
  setPermissions: (actions: string[]) => {},
});

function AuthorizationProvider({ children }: { children: React.ReactNode }) {
  const [permissions, setPermissions] = useState<string[]>([]);

  const isAllowed = (actions: string | string[]): boolean => {
    if (Array.isArray(actions)) {
      return actions.every((p) => permissions.includes(p));
    }

    return permissions.includes(actions);
  };

  const isSomeAllowed = (actions: string[]) => {
    return actions.some((a) => permissions.includes(a));
  };

  const value: AuthorizationContexProps = {
    permissions,
    isAllowed,
    isSomeAllowed,
    setPermissions,
  };

  return (
    <AuthorizationContex.Provider value={value}>
      {children}
    </AuthorizationContex.Provider>
  );
}

export default AuthorizationProvider;

export const useAuthorization = () => {
  const authorization = useContext(AuthorizationContex);
  return authorization;
};
