import React, { useCallback, useContext, useEffect, useState } from "react";
import { Artist } from "types";
import { fetchUserArtists } from "utils";

interface ArtistsContextProps {
  artists: Artist[];
  getArtists: () => void;
}

const ArtistsContext = React.createContext<ArtistsContextProps>({
  artists: [],
  getArtists: () => {},
});

const ArtistsProvider = ({ children }: { children: React.ReactNode }) => {
  const [artists, setArtists] = useState<Artist[]>([]);

  const getArtists = useCallback(async () => {
    const data = await fetchUserArtists();
    if (data) {
      setArtists(data.data);
    }
  }, []);

  useEffect(() => {
    getArtists();
  }, [getArtists]);

  let value: ArtistsContextProps = {
    artists,
    getArtists,
  };
  return (
    <ArtistsContext.Provider value={value}>{children}</ArtistsContext.Provider>
  );
};

export default ArtistsProvider;

export const useArtists = () => {
  const artists = useContext(ArtistsContext);
  return artists;
};
