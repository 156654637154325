import { styled } from "@mui/joy";

export const CalendarWrapper = styled("div")(({ theme }) => ({
  maxHeight: "100%",
  height: "100%",
  fontFamily: theme.fontFamily.body,
  border: "none",
  "& .ep-dayheader": {
    padding: "12px 0",
  },
  // [theme.breakpoints.up("sm")]: {
  //   "& .ep-dayheader": {
  //     backgroundColor: theme.palette.primary[50],
  //   },
  // },
  "& .fc-theme-standard .fc-scrollgrid": {
    border: "none",
  },
  "& thead tr th div": {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  "& .fc-theme-standard th": {
    borderRight: "none",
  },
  "& .fc-scrollgrid": {
    border: "none !important",
  },
  "& .fc-scrollgrid td:last-child": {
    borderRight: "none !important",
  },
  "& .fc .fc-multimonth": {
    border: "none",
    borderRadius: "10px",
  },
  "& .fc-scroller::-webkit-scrollbar-track": {
    boxShadow: "none",
    borderRadius: 10,
  },
  ".fc .fc-col-header-cell-cushion": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
  },
}));
