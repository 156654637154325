import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalDialog,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Typography,
  ModalClose,
  Button,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { validateForm, validateInput } from "utils/validators";
import { ValidationModel } from "types/Validators";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  open,
  onClose,
}) => {
  const [state, setState] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState<{ [formFiel: string]: string[] }>({});
  const validationModel = useMemo<ValidationModel>(
    () => [
      {
        fieldName: "currentPassword",
        validators: {
          required: {
            value: true,
            message: "This field is required",
          },
        },
      },
      {
        fieldName: "password",
        validators: {
          required: {
            value: true,
            message: "This field is required",
          },
          minLength: {
            value: 8,
            message: "The password must have min 8 chars",
          },
        },
      },
      {
        fieldName: "confirmPassword",
        validators: {
          required: {
            value: true,
            message: "This field is required",
          },
          equalTo: {
            value: "password",
            message: "Passwords did not match",
          },
        },
      },
    ],
    []
  );
  const [t] = useTranslation();
  const navigate = useNavigate();

  const mutatePassword = useCallback(async () => {
    try {
      const { data, status } = await axios.patch<{ success: boolean }>(
        `/auth/change-password`,
        state
      );
      if (status === 200 && data.success) {
        toast.success(t("Password changed with success"));
        onClose();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //For the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot change user's password"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, onClose, navigate]);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let validation = validateInput(
      e.currentTarget.name,
      e.currentTarget.value,
      validationModel,
      state
    );
    if (validation) {
      setErrors({ ...errors, [e.currentTarget.name]: validation.errors });
    }
  };

  const onSubmit = () => {
    const formValidation = validateForm(state, validationModel);
    if (formValidation.isValid) {
      mutatePassword();
    } else {
      setErrors(formValidation.errors);
    }
  };

  useEffect(() => {
    //Reset state whenever userid change
    if (!open)
      setState({
        currentPassword: "",
        password: "",
        confirmPassword: "",
      });
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        sx={{
          minWidth: 400,
        }}
        size="lg"
        aria-labelledby="change-password-modal-title"
        aria-describedby="change-password-modal-description"
      >
        <Box mb={3} display="flex" alignItems={"center"}>
          <Typography level="h6" fontWeight="bold" lineHeight={1}>
            {t("Change Password")}
          </Typography>
          <ModalClose />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width={"100%"}
          mb={3}
          rowGap={2}
        >
          <FormControl
            required
            sx={{ flex: "0.4 1" }}
            error={
              "currentPassword" in errors &&
              errors["currentPassword"].length > 0
            }
          >
            <FormLabel>{t("Current Password")}</FormLabel>
            <Input
              name="currentPassword"
              autoComplete="new-password"
              type="password"
              value={state.currentPassword}
              onChange={(e) =>
                setState({ ...state, currentPassword: e.currentTarget.value })
              }
              onBlur={onBlur}
            />
            {"currentPassword" in errors && errors["currentPassword"].length > 0
              ? errors["currentPassword"].map((m, i) => (
                  <FormHelperText key={i}>{m}</FormHelperText>
                ))
              : null}
          </FormControl>
          {/* Password         Confirm password */}
          <FormControl
            required
            sx={{ flex: "0.4 1" }}
            error={"password" in errors && errors["password"].length > 0}
          >
            <FormLabel>{t("Password")}</FormLabel>
            <Input
              name="password"
              autoComplete="new-password"
              type="password"
              value={state.password}
              onChange={(e) =>
                setState({ ...state, password: e.currentTarget.value })
              }
              onBlur={onBlur}
            />
            {"password" in errors && errors["password"].length > 0
              ? errors["password"].map((m, i) => (
                  <FormHelperText key={i}>{m}</FormHelperText>
                ))
              : null}
          </FormControl>
          <FormControl
            required
            sx={{ flex: "0.4 1" }}
            error={
              "confirmPassword" in errors &&
              errors["confirmPassword"].length > 0
            }
          >
            <FormLabel>{t("Confirm password")}</FormLabel>
            <Input
              name="confirmPassword"
              type="password"
              value={state.confirmPassword}
              onChange={(e) =>
                setState({ ...state, confirmPassword: e.currentTarget.value })
              }
              onBlur={onBlur}
            />
            {"confirmPassword" in errors && errors["confirmPassword"].length > 0
              ? errors["confirmPassword"].map((m, i) => (
                  <FormHelperText key={i}>{m}</FormHelperText>
                ))
              : null}
          </FormControl>
        </Box>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"flex-end"}
          columnGap={2}
        >
          <Button variant="plain" color="neutral" onClick={onClose}>
            {t("Close")}
          </Button>
          <Button variant="soft" color="primary" onClick={onSubmit}>
            {t("Confirm")}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default ChangePasswordModal;
