import React, { useRef, useState } from "react";
import { FileUploadOutlined } from "@mui/icons-material";
import { Box, Card, FormControl, Link, Typography } from "@mui/joy";
import { alpha } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileElement from "./styled_components/FileElement";

interface FileUploaderProps {
  onChange?: (files: File[]) => void;
}

const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [t] = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = [...files, ...Array.from(e.target.files)];
      setFiles(newFiles);
      if (props.onChange) {
        props.onChange(newFiles);
      }
    }
  };

  const onFileRemove = (file: File, index: number) => {
    const newFiles = files.filter(
      (f, i) => i !== index && file.name !== f.name
    );
    setFiles(newFiles);
  };

  return (
    <FormControl sx={{ flex: "1 1", maxWidth: "520px" }}>
      <Card
        variant="plain"
        color="primary"
        sx={{
          boxShadow: "none",
          padding: 0.5,
          alignItems: "center",
          backgroundColor: (t) => alpha(t.palette.primary[200], 0.2),
        }}
      >
        {files.length === 0 ? (
          <>
            <Box
              borderRadius={40}
              padding={0.8}
              bgcolor={(t) => alpha(t.palette.primary[200], 0.5)}
              width={25}
              height={25}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                borderRadius={"50%"}
                padding={1}
                bgcolor={(t) => alpha(t.palette.primary[300], 0.2)}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <FileUploadOutlined sx={{ fontSize: 16 }} />
              </Box>
            </Box>

            <Typography level="body2" textAlign={"center"} fontSize={12}>
              <Link underline="hover" onClick={openFileDialog}>
                {t("Click to upload")}
              </Link>
              <br />
              {t("JPG, JPEG, PNG, PDF (Max size 4MB)")}
            </Typography>
          </>
        ) : (
          <Box width={"100%"} textAlign={"center"}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              flexWrap={"wrap"}
              overflow={"hidden auto"}
              width={"100%"}
              gap={0.5}
            >
              {files.map((file, i) => (
                <FileElement
                  key={i}
                  index={i}
                  file={file}
                  onRemove={onFileRemove}
                />
              ))}
            </Box>
            <Link underline="hover" onClick={openFileDialog} p={0.2}>
              {t("Upload more...")}
            </Link>
          </Box>
        )}
        <input
          ref={fileInputRef}
          className="ep-files-uploader"
          name="files[]"
          multiple
          accept="image/png, image/jpeg,.pdf,.jpg"
          type="file"
          onChange={onInputChange}
          style={{ display: "none" }}
        />
      </Card>
    </FormControl>
  );
};

export default FileUploader;
