import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import axios from "axios";
import { useAuthentication } from "context/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Artist, EditUserForm, ListResponse, Role, UserType } from "types";
import { ValidationModel } from "types/Validators";
import { validateForm, validateInput } from "utils/validators";
import { toast } from "sonner";

interface EditUserProps {}

const EditUser: React.FC<EditUserProps> = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [artists, setArtists] = useState<Artist[]>([]);
  const [artistsFilter, setArtistsFilter] = useState<string>("");
  const [user, setUser] = useState<EditUserForm>({
    email: "",
    firstName: "",
    lastName: "",
    lang: "en_EN",
    isSystem: false,
    roleId: "",
    artists: [],
  });
  const [validationModel] = useState<ValidationModel>([
    {
      fieldName: "email",
      validators: {
        email: {
          value: true,
          message: "Insert a valid email address",
        },
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "roleId",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "artists",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
  ]);
  const [errors, setErrors] = useState<{ [fieldName: string]: string[] }>({});
  const { id } = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { user: authUser } = useAuthentication();

  const fetchRoles = useCallback(async () => {
    try {
      const { data } = await axios.get<{ data: Role[] }>("/api/roles");
      setRoles(data.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch roles"));
      }
    }
  }, [navigate, t]);

  const fetchArtists = useCallback(async () => {
    try {
      const { data } = await axios.get<ListResponse<Artist>>("/api/artists", {
        params: {
          search: artistsFilter,
        },
      });
      setArtists(data.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch artists"));
      }
    }
  }, [navigate, t, artistsFilter]);

  const fetchUser = useCallback(async () => {
    try {
      const {
        data: {
          data: { email, firstName, lastName, roleId, artists, isSystem, lang },
        },
      } = await axios.get<{ data: UserType }>(`/api/users/${id}`);
      setUser({
        email,
        firstName,
        lastName,
        roleId,
        isSystem,
        lang,
        artists: artists!,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch user"));
      }
    }
  }, [id, navigate, t]);

  const updateUser = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formValidation = validateForm(user, validationModel);
      if (formValidation.isValid) {
        try {
          const { status } = await axios.patch(`/api/users/${id}`, {
            ...user,
            artistIds: user.artists.map((a) => a.id),
          });
          if (status === 200) {
            toast.success(t("User updated with success"));
            navigate("/admin/users");
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            //Fore the user logout if the request is unauthorized
            if (error.response?.status === 401) {
              navigate("/login");
            }
            console.log(error.response?.data);
            toast.error(error.response?.data.error);
          } else {
            console.log(error);
            toast.error(t("Something went wrong! Cannot update user"));
          }
        }
      } else {
        setErrors(formValidation.errors);
      }
    },
    [user, validationModel, id, t, navigate]
  );

  const onArtistsChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Artist[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Artist> | undefined
  ) => {
    setUser({ ...user, artists: value });
  };

  const onArtistBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let validation = validateInput(
      e.currentTarget.name,
      user.artists,
      validationModel,
      user
    );

    if (validation) {
      setErrors({
        ...errors,
        [e.currentTarget.name]: validation.errors,
      });
    }
  };

  const onRoleBlur = (e: React.FocusEvent<HTMLButtonElement>) => {
    let validation = validateInput(
      e.currentTarget.name,
      user.roleId,
      validationModel,
      user
    );

    if (validation) {
      setErrors({
        ...errors,
        [e.currentTarget.name]: validation.errors,
      });
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let validation = validateInput(
      e.currentTarget.name,
      e.currentTarget.value,
      validationModel,
      user
    );
    if (validation) {
      setErrors({ ...errors, [e.currentTarget.name]: validation.errors });
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchArtists();
    fetchUser();
  }, [fetchRoles, fetchArtists, fetchUser]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display="flex"
      flexDirection="column"
      rowGap={2}
      sx={(theme) => ({
        boxSizing: "border-box",
        [theme.breakpoints.down("sm")]: {
          p: 2,
        },
      })}
    >
      <Typography level="h4" fontWeight={600}>
        {t("Edit User")}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        width={"100%"}
        flex={"1 1"}
        alignContent={"space-between"}
        component={"form"}
        onSubmit={updateUser}
        noValidate
      >
        <Box display="flex" width={"100%"} flexWrap="wrap" rowGap={2}>
          {/* Email */}
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width={"100%"}
            alignItems={"flex-start"}
            gap={2}
          >
            <FormControl
              required
              sx={(theme) => ({
                flex: "0.6 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"email" in errors && errors["email"].length > 0}
            >
              <FormLabel>{t("Email")}</FormLabel>
              <Input
                name="email"
                type="email"
                placeholder={t("User email").toString()}
                value={user.email}
                onChange={(e) =>
                  setUser({ ...user, email: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["email"] && errors["email"].length > 0
                ? errors["email"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
            {authUser && authUser.isSystem ? (
              <FormControl sx={{ marginTop: "35px" }}>
                <Checkbox
                  label={t("Is System")}
                  size="md"
                  name="isSystem"
                  checked={user.isSystem}
                  onChange={(e) =>
                    setUser({ ...user, isSystem: e.currentTarget.checked })
                  }
                />
                <FormHelperText>
                  <Typography level="body3">
                    {t("Sets the user's authority level at the system level")}
                  </Typography>
                </FormHelperText>
              </FormControl>
            ) : null}
          </Box>
          {/* First name       Last name */}
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width={"100%"}
            columnGap={2}
            justifyContent={"flex-start"}
          >
            <FormControl
              sx={(theme) => ({
                flex: "0.4 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"firstName" in errors && errors["firstName"].length > 0}
            >
              <FormLabel>{t("First name")}</FormLabel>
              <Input
                name="firstName"
                type="text"
                value={user.firstName}
                onChange={(e) =>
                  setUser({ ...user, firstName: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["firstName"] && errors["firstName"].length > 0
                ? errors["firstName"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
            <FormControl
              sx={(theme) => ({
                flex: "0.4 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"lastName" in errors && errors["lastName"].length > 0}
            >
              <FormLabel>{t("Last name")}</FormLabel>
              <Input
                name="lastName"
                type="text"
                value={user.lastName}
                onChange={(e) =>
                  setUser({ ...user, lastName: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["lastName"] && errors["lastName"].length > 0
                ? errors["lastName"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
          </Box>
          {/* Role     Languange      Artists */}
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width={"100%"}
            columnGap={2}
          >
            <FormControl
              sx={(theme) => ({
                flex: "0.4 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"lang" in errors && errors["lang"].length > 0}
            >
              <FormLabel>{t("Language")}</FormLabel>
              <Select
                name="lang"
                placeholder={t("Select a language")}
                value={user.lang}
                onChange={(_, v) => setUser({ ...user, lang: v! })}
                slotProps={{
                  button: {
                    onBlur: onRoleBlur,
                  },
                }}
              >
                <Option value={"en_EN"}>{t("English")}</Option>
                <Option value={"it_IT"}>{t("Italian")}</Option>
              </Select>
              {"lang" in errors && errors["lang"].length > 0
                ? errors["lang"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
            <FormControl
              required
              sx={(theme) => ({
                flex: "0.4 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"roleId" in errors && errors["roleId"].length > 0}
            >
              <FormLabel>{t("Role")}</FormLabel>
              <Select
                name="roleId"
                placeholder={t("Select a role...")}
                value={user.roleId}
                onChange={(_, v) => setUser({ ...user, roleId: v! })}
                slotProps={{
                  button: {
                    onBlur: onRoleBlur,
                  },
                }}
              >
                {roles.map((r) => (
                  <Option key={r.id} value={r.id}>
                    {t(r.name)}
                  </Option>
                ))}
              </Select>
              {"roleId" in errors && errors["roleId"].length > 0
                ? errors["roleId"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
            <FormControl
              required
              sx={(theme) => ({
                flex: "0.4 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"artists" in errors && errors["artists"].length > 0}
            >
              <FormLabel>{t("Artists")}</FormLabel>
              <Autocomplete
                error={"artists" in errors && errors["artists"].length > 0}
                name="artists"
                multiple
                options={artists}
                getOptionLabel={(a) => a.name}
                onInputChange={(e, v, r) => {
                  setArtistsFilter(v);
                }}
                value={user.artists}
                onChange={onArtistsChange}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                slotProps={{
                  input: {
                    onBlur: onArtistBlur,
                  },
                }}
              />
              {"artists" in errors && errors["artists"].length > 0
                ? errors["artists"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"flex-end"}
          alignSelf={"flex-end"}
          columnGap={2}
          width={"100%"}
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column-reverse",
            },
          })}
        >
          <Button
            variant="plain"
            onClick={() => navigate("/admin/users")}
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                flex: "1 1",
                mt: 2,
              },
            })}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                flex: "1 1",
                mt: 2,
              },
            })}
          >
            {t("Confirm")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditUser;
