import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Radio,
  RadioGroup,
  Textarea,
  Typography,
} from "@mui/joy";
import { Collapse } from "@mui/material";
import { Form } from "./FormComponent";
import { useTranslation } from "react-i18next";
import { useArtists } from "context/ArtistsContext";
import { useEventLocations } from "context/LocationsContext";
import { Artist, ArtistEvent, EventForm, EventLocation } from "types";
import { ValidationModel } from "types/Validators";
import { FormRow } from "./styled_components/FormRow";
import { Close, Euro, ExpandLess, ExpandMore } from "@mui/icons-material";
import { validateForm, validateInput } from "utils/validators";
import axios from "axios";
import { toast } from "sonner";
import { useAuthorization } from "context/AuthorizationContext";
import { compareAsc, format } from "date-fns";
import FileUploader from "./FileUploader";
import DocumentElement from "./styled_components/DocumentElement";
import { useNavigate } from "react-router-dom";

interface EventModalProps {
  open: boolean;
  onClose: (
    e: {},
    reason: "backdropClick" | "escapeKeyDown" | "closeClick"
  ) => void;
  onConfirm: () => void;
  event?: ArtistEvent;
  eventDate?: string;
  layout?: "center" | "fullscreen";
}

const initialEventState: EventForm = {
  date: "",
  eventType: "OUT",
  artist: null,
  promoterName: "",
  promoterFee: 0,
  serviceName: "",
  serviceFee: 0,
  note: "",
  location: null,
  price: 0,
  temporary: false,
  implant: false,
  hotel: false,
  people: 0,
};

const EventModal: React.FC<EventModalProps> = ({
  open,
  event,
  eventDate,
  layout,
  onClose,
  onConfirm,
}) => {
  const [eventForm, setEventForm] = useState<EventForm>(initialEventState);
  const [uploadDocuments, setUploadDocuments] = useState<File[]>([]);
  const [validationModel] = useState<ValidationModel>([
    {
      fieldName: "date",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "eventType",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "artist",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "location",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "price",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
  ]);
  const [errors, setErrors] = useState<{ [fieldName: string]: string[] }>({});
  const [showDetails, setShowDetails] = useState(false);
  const [t] = useTranslation();
  const { artists } = useArtists();
  const { locations } = useEventLocations();
  const { isAllowed } = useAuthorization();
  const navigate = useNavigate();

  const isArtistDisabled = useCallback(
    (artist: Artist) => {
      const eventDate = new Date(eventForm.date);
      return artist.unavailableDates &&
        artist.unavailableDates.find(
          (d) => compareAsc(new Date(d.date), eventDate) === 0
        )
        ? true
        : false;
    },
    [eventForm.date]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.type === "checkbox") {
      setEventForm({
        ...eventForm,
        [e.currentTarget.name]: e.currentTarget.checked,
      });
    } else {
      setEventForm({
        ...eventForm,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const onPricesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEventForm({
      ...eventForm,
      [e.currentTarget.name]: parseFloat(e.currentTarget.value),
    });
  };

  const onHotelInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEventForm({ ...eventForm, hotel: e.currentTarget.checked, people: 0 });
  };

  const onHotelPeopleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEventForm({
      ...eventForm,
      [e.currentTarget.name]: parseInt(e.currentTarget.value),
    });
  };

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEventForm({ ...eventForm, note: e.currentTarget.value });
  };

  const onArtistChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: Artist | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Artist>
  ) => {
    setEventForm({ ...eventForm, artist: value });
  };

  const onLocationChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: EventLocation | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<EventLocation>
  ) => {
    setEventForm({ ...eventForm, location: value });
  };

  const uploadDocs = async (eventId: string) => {
    try {
      const res = await axios.postForm(
        `/api/events/${eventId}/documents`,
        { "files[]": uploadDocuments },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status) {
        toast.success(t("Documents uploaded with success"));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(
          t("Something went wrong! Cannot upload one or more documents")
        );
      }
    }
  };

  const downloadDocument = async (docId: string) => {
    try {
      const { data, headers } = await axios.get(
        `/api/events/${event?.id}/documents/${docId}`,
        {
          responseType: "blob",
        }
      );
      const fileUrl = URL.createObjectURL(new window.Blob([data]));
      let linkBtn = document.createElement("a");
      linkBtn.href = fileUrl;
      document.body.appendChild(linkBtn);
      linkBtn.setAttribute(
        "download",
        headers["content-disposition"].split("filename=")[1].replaceAll('"', "")
      );
      //Force download
      linkBtn.click();
      //Clean up and remove the link
      linkBtn.parentNode?.removeChild(linkBtn);
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(
          t("Something went wrong! Error while trying to download document")
        );
      }
    }
  };

  const onDeleteDocument = async (eventId: string, docId: string) => {
    try {
      const res = await axios.delete(
        `/api/events/${eventId}/documents/${docId}`
      );
      if (res.status) {
        toast.success(t("Document deleted with success"));
        onConfirm();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(
          t("Something went wrong! Cannot delete the selected document")
        );
      }
    }
  };

  const createEvent = async () => {
    let eventId: string = "";
    try {
      const { artist, location, date, ...evetData } = eventForm;
      const parsedDate = new Date(date).toISOString();
      const { data, status } = await axios.post<{ data: ArtistEvent }>(
        "/api/events",
        {
          ...evetData,
          date: parsedDate,
          artistId: artist?.id,
          locationId: location?.id,
        }
      );
      if (status === 200) {
        toast.success(t("Event created with success"));
      }
      eventId = data.data.id;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot create the event"));
      }
    } finally {
      if (uploadDocuments.length > 0 && isAllowed("documents.write")) {
        uploadDocs(eventId);
      }
      onConfirm();
      onClose({}, "closeClick");
    }
  };

  const updateEvent = async () => {
    try {
      const { artist, location, date, ...evetData } = eventForm;
      const parsedDate = new Date(date).toISOString();
      const { status } = await axios.patch<{ data: ArtistEvent }>(
        `/api/events/${event!.id}`,
        {
          ...evetData,
          date: parsedDate,
          artistId: artist?.id,
          locationId: location?.id,
        }
      );
      if (status === 200) {
        toast.success(t("Event updated with success"));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot update the event"));
      }
    } finally {
      if (uploadDocuments.length > 0 && isAllowed("documents.write")) {
        uploadDocs(event!.id);
      }
      onConfirm();
      onClose({}, "closeClick");
    }
  };

  const onSubmitEvent = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const formValidation = validateForm(eventForm, validationModel);

    if (formValidation.isValid) {
      if (event) {
        await updateEvent();
      } else {
        await createEvent();
      }
    } else {
      setErrors(formValidation.errors);
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let validation = validateInput(
      e.currentTarget.name,
      e.currentTarget.value,
      validationModel,
      eventForm
    );
    if (validation) {
      setErrors({ ...errors, [e.currentTarget.name]: validation.errors });
    }
  };

  useEffect(() => {
    if (!open) {
      setEventForm(initialEventState);
      setShowDetails(false);
      setErrors({});
    }
  }, [open]);

  useEffect(() => {
    if (open && event) {
      const {
        id,
        createdAt,
        deletedAt,
        updatedAt,
        whoUpdate,
        whoCreate,
        artistId,
        locationId,
        documents,
        ...eventData
      } = event;
      const evArtist = artists.find((a) => a.id === artistId);
      const evLocation = locations.find((l) => l.id === locationId);
      setEventForm({
        ...eventData,
        date: format(new Date(eventData.date), "yyyy-MM-dd"),
        artist: evArtist!,
        location: evLocation!,
      });
    }
  }, [open, event, artists, locations]);

  useEffect(() => {
    if (open && eventDate) {
      setEventForm({ ...eventForm, date: eventDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, eventDate]);

  return (
    <Modal
      open={open}
      aria-labelledby="event-modal-title"
      aria-describedby="event-modal-description"
      onClose={(e, r) => {
        setEventForm(initialEventState);
        onClose(e, r);
      }}
    >
      <ModalDialog size="sm" layout={layout ? layout : "center"}>
        <Box
          mb={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
        >
          <Typography
            id="event-modal-title"
            level={layout && layout === "fullscreen" ? "h5" : "h6"}
            fontWeight={600}
          >
            {t(event ? "Edit Event" : "New Event")}
          </Typography>
          <Button
            variant="plain"
            size="sm"
            color="neutral"
            sx={{ width: 30, height: 20 }}
            onClick={() => {
              setEventForm(initialEventState);
              onClose({}, "closeClick");
            }}
          >
            <Close />
          </Button>
        </Box>
        <Form
          id="event-modal-description"
          sx={{
            width: "100%",
            height: layout && layout === "fullscreen" ? "100%" : undefined,
            overflow:
              layout && layout === "fullscreen" ? "hidden auto" : undefined,
          }}
        >
          {/* Form Fields */}
          <Box
            flex={"1 1 auto"}
            display={"flex"}
            flexDirection={"column"}
            rowGap={1}
            width={"100%"}
            maxHeight={layout && layout === "fullscreen" ? "100%" : 530}
            overflow={"hidden auto"}
          >
            <FormRow>
              <FormControl
                required
                sx={{
                  flex: "0.45 1",
                }}
                error={"date" in errors && errors["date"].length > 0}
              >
                <FormLabel>{t("Date")}</FormLabel>
                <Input
                  type="date"
                  name="date"
                  size="sm"
                  value={eventForm.date}
                  onChange={onChange}
                  onBlur={onBlur}
                />
                {"date" in errors && errors["date"].length > 0
                  ? errors["date"].map((m, i) => (
                      <FormHelperText key={i}>{t(m)}</FormHelperText>
                    ))
                  : null}
              </FormControl>
            </FormRow>
            <FormRow gap={1}>
              <FormControl
                required
                sx={{ flex: "0.7 1" }}
                error={"artist" in errors && errors["artist"].length > 0}
              >
                <FormLabel>{t("Artist")}</FormLabel>
                <Autocomplete
                  error={"artist" in errors && errors["artist"].length > 0}
                  id="artist"
                  size="sm"
                  options={artists}
                  disabled={eventForm.date === ""}
                  getOptionDisabled={isArtistDisabled}
                  getOptionLabel={(a) => a.name}
                  value={eventForm.artist}
                  onChange={onArtistChange}
                  name="artist"
                  slotProps={{
                    listbox: {
                      sx: (theme) => ({
                        zIndex: theme.vars.zIndex.modal,
                      }),
                    },
                    input: {
                      onBlur: onBlur,
                    },
                  }}
                />
                {"artist" in errors && errors["artist"].length > 0
                  ? errors["artist"].map((m, i) => (
                      <FormHelperText key={i}>{t(m)}</FormHelperText>
                    ))
                  : null}
              </FormControl>
              <FormControl
                required
                error={"eventType" in errors && errors["eventType"].length > 0}
              >
                <FormLabel>{t("Event Type")}</FormLabel>
                <RadioGroup
                  size="sm"
                  defaultValue={"OUT"}
                  orientation="horizontal"
                  name="eventType"
                  onChange={onChange}
                  onBlur={onBlur}
                >
                  <Radio value={"IN"} label={t("Inside")}></Radio>
                  <Radio value={"OUT"} label={t("Outside")}></Radio>
                </RadioGroup>
                {"eventType" in errors && errors["eventType"].length > 0
                  ? errors["eventType"].map((m, i) => (
                      <FormHelperText key={i}>{t(m)}</FormHelperText>
                    ))
                  : null}
              </FormControl>
            </FormRow>
            <FormRow gap={1}>
              <FormControl
                required
                sx={{ flex: "0.9 1" }}
                error={"location" in errors && errors["location"].length > 0}
              >
                <FormLabel>{t("Location")}</FormLabel>
                <Autocomplete
                  size="sm"
                  required
                  options={locations}
                  getOptionLabel={(a) => a.description}
                  name="location"
                  value={eventForm.location}
                  error={"location" in errors && errors["location"].length > 0}
                  onChange={onLocationChange}
                  slotProps={{
                    listbox: {
                      sx: (theme) => ({
                        zIndex: theme.vars.zIndex.modal,
                      }),
                    },
                    input: {
                      onBlur: onBlur,
                    },
                  }}
                />
                {"location" in errors && errors["location"].length > 0
                  ? errors["location"].map((m, i) => (
                      <FormHelperText key={i}>{t(m)}</FormHelperText>
                    ))
                  : null}
              </FormControl>
              <FormControl
                required
                error={"price" in errors && errors["price"].length > 0}
              >
                <FormLabel>{t("Price")}</FormLabel>
                <Input
                  type="number"
                  name="price"
                  endDecorator={<Euro fontSize="small" />}
                  size="sm"
                  slotProps={{
                    input: {
                      step: "0.50",
                    },
                  }}
                  value={eventForm.price}
                  onChange={onPricesChange}
                  onBlur={onBlur}
                />
                {"price" in errors && errors["price"].length > 0
                  ? errors["price"].map((m, i) => (
                      <FormHelperText key={i}>{t(m)}</FormHelperText>
                    ))
                  : null}
              </FormControl>
            </FormRow>
            <FormRow>
              <FormControl sx={{ flex: "1 1" }}>
                <FormLabel>{t("Note")}</FormLabel>
                <Textarea
                  size="sm"
                  minRows={2}
                  name="note"
                  maxRows={3}
                  value={eventForm.note}
                  onChange={onTextAreaChange}
                />
              </FormControl>
            </FormRow>
            {isAllowed("documents.write") || isAllowed("documents.read") ? (
              <FormRow sx={{ flexDirection: "column" }}>
                <FormLabel>{t("Attachments")}</FormLabel>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  flexWrap={"wrap"}
                  overflow={"hidden auto"}
                  width={"100%"}
                  gap={0.5}
                  mb={1}
                >
                  {event &&
                    event.documents.map((doc) => (
                      <DocumentElement
                        key={doc.id}
                        file={doc}
                        onClick={(docId) => downloadDocument(docId)}
                        onRemove={(docId) => onDeleteDocument(event.id, docId)}
                      />
                    ))}
                </Box>
                {isAllowed("documents.write") ? (
                  <FileUploader onChange={setUploadDocuments} />
                ) : null}
              </FormRow>
            ) : null}
            <Typography
              level="h6"
              sx={{ cursor: "pointer" }}
              color="neutral"
              display={"flex"}
              alignItems={"center"}
              onClick={() => {
                setShowDetails(!showDetails);
              }}
            >
              {t("Others")}
              {showDetails ? <ExpandLess /> : <ExpandMore />}
            </Typography>
            <Collapse in={showDetails} sx={{ width: "100%" }}>
              <Box width={"100%"}>
                <FormRow gap={1} sx={{ mb: 1 }}>
                  <FormControl>
                    <FormLabel>{t("Promoter Name")}</FormLabel>
                    <Input
                      name="promoterName"
                      size="sm"
                      fullWidth
                      value={eventForm.promoterName}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t("Promoter Fee")}</FormLabel>
                    <Input
                      type="number"
                      name="promoterFee"
                      size="sm"
                      fullWidth
                      slotProps={{
                        input: {
                          step: "0.50",
                        },
                      }}
                      value={eventForm.promoterFee}
                      endDecorator={<Euro fontSize="small" />}
                      onChange={onPricesChange}
                      onBlur={onBlur}
                    />
                  </FormControl>
                </FormRow>
                <FormRow gap={1} sx={{ mb: 2 }}>
                  <FormControl>
                    <FormLabel>{t("Service Name")}</FormLabel>
                    <Input
                      name="serviceName"
                      size="sm"
                      fullWidth
                      value={eventForm.serviceName}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>{t("Service Fee")}</FormLabel>
                    <Input
                      type="number"
                      name="serviceFee"
                      size="sm"
                      slotProps={{
                        input: {
                          step: "0.50",
                        },
                      }}
                      fullWidth
                      value={eventForm.serviceFee}
                      endDecorator={<Euro fontSize="small" />}
                      onChange={onPricesChange}
                      onBlur={onBlur}
                    />
                  </FormControl>
                </FormRow>
                <FormRow gap={1} sx={{ alignItems: "center" }}>
                  <Checkbox
                    label={t("Temporary")}
                    size="sm"
                    name="temporary"
                    checked={eventForm.temporary}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  <Checkbox
                    label={t("Implant")}
                    size="sm"
                    name="implant"
                    checked={eventForm.implant}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                  <Checkbox
                    label={t("Hotel")}
                    size="sm"
                    name="hotel"
                    checked={eventForm.hotel}
                    onChange={onHotelInputChange}
                    onBlur={onBlur}
                  />
                  <FormControl
                    required={eventForm.hotel}
                    error={"people" in errors && errors["people"].length > 0}
                  >
                    <Input
                      type="number"
                      name="people"
                      size="sm"
                      placeholder={t("People").toString()}
                      disabled={!eventForm.hotel}
                      value={eventForm.people}
                      onChange={onHotelPeopleChange}
                      onBlur={onBlur}
                    />
                    {errors["people"] && errors["people"].length > 0
                      ? errors["people"].map((m, i) => (
                          <FormHelperText key={i}>{t(m)}</FormHelperText>
                        ))
                      : null}
                  </FormControl>
                </FormRow>
              </Box>
            </Collapse>
          </Box>
          {/* Form Footer */}
          <Box
            display={"flex"}
            flexDirection={"row"}
            columnGap={1}
            width={"100%"}
            flex={"0 1"}
            justifyContent={"flex-end"}
          >
            <Button
              variant="plain"
              color="neutral"
              onClick={() => onClose({}, "closeClick")}
            >
              {t("Close")}
            </Button>
            {isAllowed("events.write") ? (
              <Button
                type="button"
                variant="soft"
                color="primary"
                onClick={onSubmitEvent}
              >
                {t(event ? "Update" : "Create")}
              </Button>
            ) : null}
          </Box>
        </Form>
      </ModalDialog>
    </Modal>
  );
};

export default EventModal;
