import React from "react";
import { Box, Button, ListItemDecorator } from "@mui/joy";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import EventIcon from "@mui/icons-material/Event";
import { useTranslation } from "react-i18next";

interface MobileLayoutProps {}

const MobileLayout: React.FC<MobileLayoutProps> = () => {
  const matchDashboard = useMatch("dashboard");
  const matchAnalytics = useMatch("analytics");
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Box
        flex={"1 1"}
        width={"100vw"}
        height={"calc(100% - 50px)"}
        overflow={"auto"}
      >
        <Outlet />
      </Box>
      <Box
        display={"flex"}
        flex={"0 1 70px"}
        width={"calc(100% - 16px)"}
        alignItems={"center"}
        justifyContent={"space-evenly"}
        gap={1}
        px={1}
        sx={{ bgcolor: (theme) => theme.palette.primary[50] }}
      >
        <Button
          size="lg"
          variant="plain"
          color={matchDashboard ? "primary" : "neutral"}
          fullWidth
          sx={{
            flexDirection: "column",
            "&:focus": {
              bgcolor: "transparent",
            },
            "&:active": {
              bgcolor: "transparent",
            },
            "& .MuiButton-startDecorator": {
              margin: 0,
            },
          }}
          onClick={() => navigate("/dashboard")}
        >
          <ListItemDecorator>
            <EventIcon />
          </ListItemDecorator>
          {t("Calendar")}
        </Button>
        <Button
          size="lg"
          fullWidth
          variant="plain"
          color={matchAnalytics ? "primary" : "neutral"}
          sx={{
            flexDirection: "column",
            "&:focus": {
              bgcolor: "transparent",
            },
            "&:active": {
              bgcolor: "transparent",
            },
            "& .MuiButton-startDecorator": {
              margin: 0,
            },
          }}
          onClick={() => navigate("/analytics")}
        >
          <ListItemDecorator>
            <InsightsRoundedIcon />
          </ListItemDecorator>
          {t("Analytics")}
        </Button>
      </Box>
    </Box>
  );
};

export default MobileLayout;
