import {
  AttachFile,
  Delete,
  Edit,
  EuroRounded,
  TodayOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/joy";
import { Popover, PopoverProps } from "@mui/material";
import { useArtists } from "context/ArtistsContext";
import { useAuthorization } from "context/AuthorizationContext";
import { useEventLocations } from "context/LocationsContext";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ArtistEvent } from "types";
import DocumentElement from "./styled_components/DocumentElement";
import axios from "axios";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useDialogManger } from "context/DialogManagerContext";

interface EventDetailsProps extends PopoverProps {
  event: ArtistEvent | undefined;
  onEditClick: (event: ArtistEvent) => void;
  onDeleteClick: (event: ArtistEvent) => void;
  forceUpdate?: () => void;
}

const EventDetails: React.FC<EventDetailsProps> = ({
  event,
  onEditClick,
  onDeleteClick,
  forceUpdate,
  ...props
}) => {
  const [t] = useTranslation();
  const { isAllowed } = useAuthorization();
  const { artists } = useArtists();
  const { locations } = useEventLocations();
  const navigate = useNavigate();
  const { showDialog } = useDialogManger();

  const eventArtist = useMemo(() => {
    if (event) {
      return artists.find((a) => a.id === event.artistId);
    }
  }, [artists, event]);

  const eventLocation = useMemo(() => {
    if (event) {
      return locations.find((l) => l.id === event.locationId);
    }
  }, [event, locations]);

  const downloadDocument = async (docId: string) => {
    try {
      const { data, headers } = await axios.get(
        `/api/events/${event?.id}/documents/${docId}`,
        {
          responseType: "blob",
        }
      );
      const fileUrl = URL.createObjectURL(new window.Blob([data]));
      let linkBtn = document.createElement("a");
      linkBtn.href = fileUrl;
      document.body.appendChild(linkBtn);
      linkBtn.setAttribute(
        "download",
        headers["content-disposition"].split("filename=")[1].replaceAll('"', "")
      );
      //Force download
      linkBtn.click();
      //Clean up and remove the link
      linkBtn.parentNode?.removeChild(linkBtn);
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(
          t("Something went wrong! Error while trying to download document")
        );
      }
    }
  };

  const onDeleteDocument = async (eventId: string, docId: string) => {
    try {
      const res = await axios.delete(
        `/api/events/${eventId}/documents/${docId}`
      );
      if (res.status) {
        toast.success(t("Document deleted with success"));
        if (forceUpdate) forceUpdate();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(
          t("Something went wrong! Cannot delete the selected document")
        );
      }
    }
  };

  const onDeleteDocClick = (eventId: string, docId: string) => {
    if (isAllowed("documents.delete")) {
      showDialog({
        title: "Delete the selected document?",
        body: "If you proceed with this action the selected document will be deleted.",
        actions: [
          {
            label: "Cancel",
            color: "neutral",
            variant: "plain",
            onClick: (e, closeModal) => {
              closeModal();
            },
          },
          {
            label: "Delete",
            color: "danger",
            variant: "solid",
            onClick: (e, closeModal) => {
              onDeleteDocument(eventId, docId);
              closeModal();
            },
          },
        ],
      });
    }
  };

  return (
    <Popover
      {...props}
      PaperProps={{ sx: { p: 1.5, minWidth: 250 } }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box display="flex" flexDirection={"column"} width={"100%"} rowGap={1}>
        <Box
          display="flex"
          flexDirection={"row"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            width={25}
            height={25}
            borderRadius={50}
            mr={1}
            bgcolor={eventArtist?.color}
          />
          <Typography level="h6" fontWeight={"bold"} sx={{ mr: 4 }}>
            {eventArtist?.name} {" - "} {eventLocation?.description}
          </Typography>
          <Box display={"flex"}>
            {isAllowed("events.write") ? (
              <IconButton
                size="sm"
                variant="plain"
                color="neutral"
                onClick={() => onEditClick(event!)}
              >
                <Edit fontSize="small" />
              </IconButton>
            ) : null}
            {isAllowed("events.delete") ? (
              <IconButton
                size="sm"
                color="danger"
                variant="plain"
                onClick={() => onDeleteClick(event!)}
              >
                <Delete fontSize="small" />
              </IconButton>
            ) : null}
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <TodayOutlined fontSize="small" sx={{ mr: 1 }} />
          {event ? (
            <Typography level="body2">
              {format(new Date(event.date), "dd/MM/yyyy")}
            </Typography>
          ) : null}
        </Box>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <EuroRounded fontSize="small" sx={{ mr: 1 }} />
          {event ? <Typography level="body2">{event.price}</Typography> : null}
        </Box>
        {event && event.documents.length > 0 ? (
          <Box
            display={"flex"}
            flexDirection={"row"}
            flexWrap={"wrap"}
            overflow={"hidden auto"}
            alignItems={"center"}
            width={"100%"}
            maxWidth={550}
            gap={0.5}
            mb={1}
          >
            <AttachFile fontSize="small" sx={{ mr: 1 }} />
            {event.documents.map((doc) => (
              <DocumentElement
                key={doc.id}
                file={doc}
                dense
                onRemove={(d) => {
                  onDeleteDocClick(event.id, d);
                }}
                onClick={downloadDocument}
              />
            ))}
          </Box>
        ) : null}
        {event && event.note ? (
          <Box>
            <Typography level="body2">{t("Note")}</Typography>
            <Typography level="body3">{event.note}</Typography>
          </Box>
        ) : null}
      </Box>
    </Popover>
  );
};

export default EventDetails;
