import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import axios from "axios";
import { useRegions } from "hooks/useRegions";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { LocationForm } from "types";
import { ValidationModel } from "types/Validators";
import { validateForm, validateInput } from "utils/validators";

interface CreateLocationProps {}

const CreateLocation: React.FC<CreateLocationProps> = () => {
  const [location, setLocation] = useState<LocationForm>({
    description: "",
    address: "",
    city: "",
    country: "",
    province: "",
    region: "",
    zip: "",
  });
  const [validationModel] = useState<ValidationModel>([
    {
      fieldName: "description",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "address",
      validators: {},
    },
    {
      fieldName: "city",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "zip",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "province",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "region",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
    {
      fieldName: "country",
      validators: {
        required: {
          value: true,
          message: "This field is required",
        },
      },
    },
  ]);
  const [errors, setErrors] = useState<{ [fieldName: string]: string[] }>({});
  const navigate = useNavigate();
  const [t] = useTranslation();
  const regions = useRegions();

  const saveLocation = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const formValidation = validateForm(location, validationModel);
      if (formValidation.isValid) {
        try {
          const { status } = await axios.post("/api/locations", location);
          if (status === 200) {
            toast.success(t("Location created with success"));
            navigate("/admin/locations");
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            //Fore the user logout if the request is unauthorized
            if (error.response?.status === 401) {
              navigate("/login");
            }
            console.log(error.response?.data);
            toast.error(error.response?.data.error);
          } else {
            console.log(error);
            toast.error(t("Something went wrong! Cannot create location"));
          }
        }
      } else {
        setErrors(formValidation.errors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, validationModel, navigate]
  );

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    let validation = validateInput(
      e.currentTarget.name,
      e.currentTarget.value,
      validationModel,
      location
    );
    if (validation) {
      setErrors({ ...errors, [e.currentTarget.name]: validation.errors });
    }
  };

  const onRegionBlur = (e: React.FocusEvent<HTMLButtonElement, Element>) => {
    let validation = validateInput(
      e.currentTarget.name,
      location.region,
      validationModel,
      location
    );
    if (validation) {
      setErrors({ ...errors, [e.currentTarget.name]: validation.errors });
    }
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display="flex"
      flexDirection="column"
      rowGap={2}
      sx={(theme) => ({
        boxSizing: "border-box",
        [theme.breakpoints.down("sm")]: {
          p: 2,
        },
      })}
    >
      <Typography level="h4" fontWeight={600}>
        {t("New Location")}
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        width={"100%"}
        flex={"1 1"}
        alignContent={"space-between"}
        component={"form"}
        onSubmit={saveLocation}
        noValidate
      >
        <Box
          display="flex"
          width={"100%"}
          flexWrap="wrap"
          gap={{ xs: 1, md: 2 }}
        >
          {/* Description */}
          <FormControl
            required
            sx={(theme) => ({
              flex: "0.5 1",
              [theme.breakpoints.down("sm")]: {
                flex: "1 1",
              },
            })}
            error={"description" in errors && errors["description"].length > 0}
          >
            <FormLabel>{t("Location Name")}</FormLabel>
            <Input
              name="description"
              type="text"
              value={location.description}
              onChange={(e) =>
                setLocation({ ...location, description: e.currentTarget.value })
              }
              onBlur={onBlur}
            />
            {errors["description"] && errors["description"].length > 0
              ? errors["description"].map((m, i) => (
                  <FormHelperText key={i}>{t(m)}</FormHelperText>
                ))
              : null}
          </FormControl>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width={"100%"}
            gap={{ xs: 1, md: 2 }}
          >
            {/* Address */}
            <FormControl
              sx={(theme) => ({
                flex: "0.6 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"address" in errors && errors["address"].length > 0}
            >
              <FormLabel>{t("Address")}</FormLabel>
              <Input
                name="address"
                type="text"
                value={location.address}
                onChange={(e) =>
                  setLocation({ ...location, address: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["address"] && errors["address"].length > 0
                ? errors["address"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
            {/* Zip */}
            <FormControl
              required
              sx={(theme) => ({
                flex: "0.2 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"zip" in errors && errors["zip"].length > 0}
            >
              <FormLabel>{t("Zip")}</FormLabel>
              <Input
                name="zip"
                type="text"
                value={location.zip}
                onChange={(e) =>
                  setLocation({
                    ...location,
                    zip: e.currentTarget.value,
                  })
                }
                onBlur={onBlur}
              />
              {errors["zip"] && errors["zip"].length > 0
                ? errors["zip"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
            {/* City */}
            <FormControl
              required
              sx={(theme) => ({
                flex: "0.3 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"city" in errors && errors["city"].length > 0}
            >
              <FormLabel>{t("City")}</FormLabel>
              <Input
                name="city"
                type="text"
                value={location.city}
                onChange={(e) =>
                  setLocation({ ...location, city: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["city"] && errors["city"].length > 0
                ? errors["city"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            width={"100%"}
            gap={{ xs: 1, md: 2 }}
          >
            {/* Province */}
            <FormControl
              required
              sx={(theme) => ({
                flex: "0.3 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"province" in errors && errors["province"].length > 0}
            >
              <FormLabel>{t("Province")}</FormLabel>
              <Input
                name="province"
                type="text"
                value={location.province}
                onChange={(e) =>
                  setLocation({ ...location, province: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["province"] && errors["province"].length > 0
                ? errors["province"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
            {/* Region */}
            <FormControl
              required
              sx={(theme) => ({
                flex: "0.3 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"region" in errors && errors["region"].length > 0}
            >
              <FormLabel>{t("Region")}</FormLabel>
              <Select
                name="region"
                value={location.region}
                placeholder={t("Select a region")}
                onChange={(e, value) =>
                  setLocation({
                    ...location,
                    region: value ? value : "",
                  })
                }
                slotProps={{
                  button: {
                    onBlur: onRegionBlur,
                  },
                }}
              >
                {regions.map((r, i) => (
                  <Option key={i} value={r}>
                    {r}
                  </Option>
                ))}
              </Select>
              {errors["region"] && errors["region"].length > 0
                ? errors["region"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
            {/* country */}
            <FormControl
              required
              sx={(theme) => ({
                flex: "0.3 1",
                [theme.breakpoints.down("sm")]: {
                  flex: "1 1",
                },
              })}
              error={"country" in errors && errors["country"].length > 0}
            >
              <FormLabel>{t("Country")}</FormLabel>
              <Input
                name="country"
                type="text"
                value={location.country}
                onChange={(e) =>
                  setLocation({ ...location, country: e.currentTarget.value })
                }
                onBlur={onBlur}
              />
              {errors["country"] && errors["country"].length > 0
                ? errors["country"].map((m, i) => (
                    <FormHelperText key={i}>{t(m)}</FormHelperText>
                  ))
                : null}
            </FormControl>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent={"flex-end"}
          alignSelf={"flex-end"}
          columnGap={2}
          width={"100%"}
          sx={(theme) => ({
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column-reverse",
              mb: 2,
            },
          })}
        >
          <Button
            variant="plain"
            onClick={() => navigate("/admin/locations")}
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                flex: "1 1",
                mt: 2,
              },
            })}
          >
            {t("Cancel")}
          </Button>
          <Button
            type="submit"
            variant="solid"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                flex: "1 1",
                mt: 2,
              },
            })}
          >
            {t("Confirm")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateLocation;
