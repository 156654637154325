import axios from "axios"
import { EventLocation, ListResponse } from "types"
import { QueryParams } from ".";
import { toast } from "sonner"


export const fetchLocations = async ({ page, limit, sort, search }: QueryParams, errorCallback?: (error: any) => void) => {
    try {
        const { data } = await axios.get<ListResponse<EventLocation>>("/api/locations", {
            params: {
                page, limit, sort, search
            },
        });
        return data
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            //Fore the user logout if the request is unauthorized
            if (error.response?.status === 401) {
                window.location.href = "/login"
            }
            if (errorCallback) {
                errorCallback(error)
            }
            toast.error(error.response?.data);
        } else {
            if (errorCallback) {
                errorCallback(error)
            }
            toast.error("Something went wrong! Cannot fetch locations");
        }
    }

};

export const fetchAllLocations = async ({ sort, search }: { sort: string, search: string }, errorCallback?: (error: any) => void) => {
    try {
        const { data } = await axios.get<ListResponse<EventLocation>>("/api/locations/list", {
            params: {
                sort, search
            },
        });
        return data
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            //Fore the user logout if the request is unauthorized
            if (error.response?.status === 401) {
                window.location.href = "/login"
            }
            if (errorCallback) {
                errorCallback(error)
            }
            toast.error(error.response?.data);
        } else {
            if (errorCallback) {
                errorCallback(error)
            }
            toast.error("Something went wrong! Cannot fetch locations");
        }
    }

};