import React, { useCallback, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { Artist } from "types";
import axios from "axios";
import { toast } from "sonner";
import { useMediaQuery } from "react-responsive";

interface ArtistsDistProfitChartProps {
  startDate: string;
  endDate: string;
  selectedArtists: Artist[] | undefined;
}

type ArtistsDistProfitData = {
  name: string;
  price: number;
  count: number;
};

const ArtistsDistProfitChart: React.FC<ArtistsDistProfitChartProps> = ({
  startDate,
  endDate,
  selectedArtists,
}) => {
  const [artistsProfits, setArtistsProfits] = useState<ArtistsDistProfitData[]>(
    []
  );
  const [legend, setLegend] = useState<string[]>([]);
  const [series, setSeries] = useState<
    {
      name: string;
      type: string;
      data: (number | null)[];
    }[]
  >([]);
  const [t] = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  const getArtistsProfits = useCallback(async () => {
    try {
      const { data } = await axios.get<{ data: ArtistsDistProfitData[] }>(
        "/api/analytics/distinct-profit",
        {
          params: {
            start: startDate,
            end: endDate,
            artistIds: selectedArtists?.map((a) => a.id).join(","),
          },
        }
      );

      setLegend(data.data.map((a) => a.name));
      setArtistsProfits(data.data);

      const resultObj: {
        [name: string]: {
          name: string;
          type: string;
          data: (number | null)[];
        };
      } = {};

      data.data.forEach((item) => {
        const name = item.name;
        const count = item.count;

        if (!resultObj[name]) {
          resultObj[name] = {
            name: name,
            type: "bar",
            data: Array(data.data.length).fill(null),
          };
        }

        resultObj[name].data[data.data.indexOf(item)] = count;
      });

      // Convert the object values to an array
      setSeries(Object.values(resultObj));
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          window.location.href = "/login";
        }
        toast.error(error.response?.data);
      } else {
        toast.error("Something went wrong! Cannot fetch locations");
      }
    }
  }, [startDate, endDate, selectedArtists]);

  useEffect(() => {
    getArtistsProfits();
  }, [startDate, endDate, selectedArtists, getArtistsProfits]);

  const options = {
    title: {
      text: t("Artists Distinct Profit"),
      left: "start",
      textStyle: {
        fontFamily: "Public Sans",
        fontSize: "1rem",
        fontWeight: 600,
        color: "#25252D",
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      position: function (
        point: any[],
        params: any,
        dom: HTMLElement,
        rect: object,
        size: any
      ) {
        const calc = size.viewSize[0] / 2 - (size.contentSize[0] * 50) / 100;
        return [`${calc}px`, "10%"];
      },
    },
    xAxis: {
      type: "category",
      data: artistsProfits.map((ap) => ap.price),
      axisLabel: {
        show: !isMobile,
        rotate: 30,
        interval: 0,
        padding: 5,
        formatter: "{value} €",
      },
    },
    yAxis: {
      type: "value",
      minInterval: 1,
    },
    legend: {
      data: legend,
      bottom: 0,
    },
    series: series,
  };
  return <ReactECharts option={options} style={{ height: "450px" }} />;
};

export default ArtistsDistProfitChart;
