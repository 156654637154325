import React, { useState } from "react";
import { useAuthentication } from "context/AuthenticationContext";
import { useAuthorization } from "context/AuthorizationContext";
import { useTranslation } from "react-i18next";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import {
  Delete,
  FmdGoodRounded,
  GroupRounded,
  Menu,
  TheaterComedyRounded,
} from "@mui/icons-material";
import Drawer from "components/styled_components/Drawer";
import { IllustrationImg } from "components/styled_components";
import adminIllustration from "resources/admin_illustration.svg";
import MobileUserMenu from "components/mobile/MobileUserMenu";

interface MobileAdminLayoutProps {}

const MobileAdminLayout: React.FC<MobileAdminLayoutProps> = () => {
  const [toggleDrawer, setToggleDrawer] = useState(false);

  const [t] = useTranslation();
  const rootAdminMatch = useMatch("admin");
  const usersMatch = useMatch("admin/users/*");
  const locationsMatch = useMatch("admin/locations/*");
  const artistsMatch = useMatch("admin/artists/*");
  const trashMatch = useMatch("admin/trash");
  const [userMenuAnchEl, setUserMenuAnchEl] = useState<HTMLDivElement | null>(
    null
  );
  const navigate = useNavigate();
  const { isAllowed } = useAuthorization();
  const { user } = useAuthentication();

  const renderAvatarText = () => {
    return user?.email[0].toUpperCase();
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchEl(null);
  };

  const handleUserMenuOpen = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setUserMenuAnchEl(e.currentTarget);
  };

  return (
    <>
      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        flexDirection={"column"}
        boxSizing={"border-box"}
      >
        <Box
          flex={"0 1 50px"}
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"space-between"}
          boxSizing={"border-box"}
          px={1}
          py={2}
          bgcolor={(t) => t.palette.primary[50]}
        >
          <IconButton
            variant="plain"
            color="neutral"
            onClick={() => setToggleDrawer(true)}
          >
            <Menu />
          </IconButton>
          <Avatar
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={handleUserMenuOpen}
          >
            {renderAvatarText()}
          </Avatar>
        </Box>
        {rootAdminMatch !== null ? (
          <Box
            display="flex"
            flex={"1 1"}
            height={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            boxSizing={"border-box"}
            p={2}
            textAlign={"center"}
          >
            <IllustrationImg
              src={adminIllustration}
              alt="admin_panel"
              width={300}
              sx={{ mb: 2 }}
            />
            <Typography level="h6" fontWeight={600}>
              {t("Welcome to the admin section")}
            </Typography>
            <Typography level="body2">
              {t(
                "From here you can manage all the settings and resources of the application"
              )}
            </Typography>
          </Box>
        ) : (
          <Box
            position={"relative"}
            flex={"1 1"}
            display={"flex"}
            flexDirection={"column"}
            boxSizing={"border-box"}
            overflow={"hidden auto"}
          >
            <Outlet />
          </Box>
        )}
      </Box>
      <Drawer
        title={t("Events Planer")}
        open={toggleDrawer}
        size={300}
        onClose={() => setToggleDrawer(false)}
      >
        <List sx={{ "--List-radius": "16px", "--List-padding": "16px" }}>
          {isAllowed("users.read") ? (
            <ListItemButton
              selected={usersMatch != null}
              variant={usersMatch != null ? "soft" : "plain"}
              onClick={() => {
                navigate("users");
                setToggleDrawer(false);
              }}
            >
              <ListItemDecorator>
                <GroupRounded />
              </ListItemDecorator>
              <ListItemContent>{t("Users")}</ListItemContent>
            </ListItemButton>
          ) : null}
          {isAllowed("locations.read") ? (
            <ListItemButton
              selected={locationsMatch != null}
              variant={locationsMatch != null ? "soft" : "plain"}
              onClick={() => {
                navigate("locations");
                setToggleDrawer(false);
              }}
            >
              <ListItemDecorator>
                <FmdGoodRounded />
              </ListItemDecorator>
              <ListItemContent>{t("Locations")}</ListItemContent>
            </ListItemButton>
          ) : null}
          {isAllowed("artists.read") ? (
            <ListItemButton
              selected={artistsMatch != null}
              variant={artistsMatch != null ? "soft" : "plain"}
              onClick={() => {
                navigate("artists");
                setToggleDrawer(false);
              }}
            >
              <ListItemDecorator>
                <TheaterComedyRounded />
              </ListItemDecorator>
              <ListItemContent>{t("Artists")}</ListItemContent>
            </ListItemButton>
          ) : null}
          {isAllowed("documents.read") && user?.isSystem ? (
            <ListItemButton
              selected={trashMatch != null}
              variant={trashMatch != null ? "soft" : "plain"}
              onClick={() => {
                navigate("trash");
                setToggleDrawer(false);
              }}
            >
              <ListItemDecorator>
                <Delete />
              </ListItemDecorator>
              <ListItemContent>{t("Trash")}</ListItemContent>
            </ListItemButton>
          ) : null}
        </List>
      </Drawer>
      <MobileUserMenu
        open={Boolean(userMenuAnchEl)}
        onClose={handleUserMenuClose}
      />
    </>
  );
};

export default MobileAdminLayout;
