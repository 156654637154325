export const useRegions = () => {
    return [
        "Valle d'Aosta",
        "Piemonte",
        "Liguria",
        "Lombardia",
        "Veneto",
        "Trentino-Alto Adige",
        "Friuli-Venezia Giulia",
        "Emilia-Romagna",
        "Toscana",
        "Lazio",
        "Umbria",
        "Marche",
        "Abruzzo",
        "Molise",
        "Campania",
        "Basilicata",
        "Puglia",
        "Calabria",
        "Sicilia",
        "Sardegna"
    ]
}