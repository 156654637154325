import React from "react";
import { useAuthentication } from "context/AuthenticationContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import LocationsProvider from "context/LocationsContext";
import ArtistsProvider from "context/ArtistsContext";
import { Box, CircularProgress, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

interface AuthMiddlewareProps {
  children?: React.ReactNode;
}

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({ children }) => {
  const { isAuthenticated, user, isLoading } = useAuthentication();
  const location = useLocation();
  const [t] = useTranslation();

  if (isLoading)
    return (
      <Box
        width={"100vw"}
        height={"100vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        gap={2}
      >
        <CircularProgress size="lg" color="primary" />
        <Typography level="body1" fontWeight={600}>
          {t("Loading core data")}
        </Typography>
      </Box>
    );

  if (location.pathname === "/") <Navigate to="/dashboard" replace />;

  if (isAuthenticated && user)
    return (
      <LocationsProvider>
        <ArtistsProvider>{children ? children : <Outlet />}</ArtistsProvider>
      </LocationsProvider>
    );

  return <Navigate to="/login" replace />;
};

export default AuthMiddleware;
