import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  ColorPaletteProp,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
  VariantProp,
} from "@mui/joy";
import { useTranslation } from "react-i18next";

interface DialogManagerContexProps {
  isOpen: boolean;
  showDialog: (options: DialogOptions) => void;
}

export interface DialogActions {
  label: string;
  color?: ColorPaletteProp;
  icon?: React.ReactNode;
  variant?: VariantProp;
  onClick: (
    event: React.MouseEvent<HTMLAnchorElement>,
    closeModal: () => void
  ) => void;
}

export type DialogOptions = {
  title: string;
  body: string;
  actions: DialogActions[];
  /**
   * Determines whether to show the close icon in the upper right corner
   * @default true
   */
  showCloseIcon?: boolean;
};

const DialogManagerContex = React.createContext<DialogManagerContexProps>({
  isOpen: false,
  showDialog: (options: DialogOptions) => {},
});

function DialogManagerProvider({ children }: { children: React.ReactNode }) {
  const [show, setShow] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(true);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalActions, setModalActions] = useState<DialogActions[]>([]);

  const [t] = useTranslation();

  const showDialog = (options: DialogOptions) => {
    if (options.showCloseIcon !== undefined) {
      setShowCloseIcon(options.showCloseIcon);
    }
    setModalTitle(options.title);
    setModalBody(options.body);
    setModalActions(options.actions);
    setShow(true);
  };

  const onCloseModal = () => {
    setShow(false);
  };

  const value = {
    isOpen: show,
    showDialog,
  };
  return (
    <DialogManagerContex.Provider value={value}>
      <Modal open={show} onClose={onCloseModal}>
        <ModalDialog
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
            },
          })}
        >
          <Box display="flex" flexDirection="column" width="100%" rowGap={2}>
            {/* Header */}
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <Typography level="h6" fontWeight={600}>
                {t(modalTitle)}
              </Typography>
              {showCloseIcon ? <ModalClose /> : null}
            </Box>
            {/* Body */}
            <Box width="100%" display="flex" flexDirection="row">
              <Typography level="body2" fontWeight={600}>
                {t(modalBody)}
              </Typography>
            </Box>
            {/* Footer */}
            <Box
              width="100%"
              display="flex"
              flexDirection={{ xs: "column-reverse", sm: "row" }}
              justifyContent="flex-end"
              alignItems="center"
              gap={1}
              sx={(theme) => ({
                [theme.breakpoints.only("xs")]: {
                  mt: 1,
                },
              })}
            >
              {modalActions.map((a, i) => (
                <Button
                  key={i}
                  color={a.color}
                  variant={a.variant}
                  startDecorator={a.icon}
                  sx={(theme) => ({
                    [theme.breakpoints.only("xs")]: {
                      width: "100%",
                    },
                  })}
                  onClick={(e) => a.onClick(e, onCloseModal)}
                >
                  {t(a.label)}
                </Button>
              ))}
            </Box>
          </Box>
        </ModalDialog>
      </Modal>
      {children}
    </DialogManagerContex.Provider>
  );
}

export default DialogManagerProvider;

export const useDialogManger = () => {
  const manager = useContext(DialogManagerContex);
  return manager;
};
