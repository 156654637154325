import React, { useCallback, useEffect, useState } from "react";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { Artist } from "types";
import axios from "axios";
import { toast } from "sonner";
import { useIsMobile } from "hooks/useIsMobile";

interface ArtistsCachetChartProps {
  startDate: string;
  endDate: string;
  selectedArtists: Artist[] | undefined;
}

type ArtistsCachetsData = {
  name: string;
  value: number;
};

const ArtistsCachetChart: React.FC<ArtistsCachetChartProps> = ({
  startDate,
  endDate,
  selectedArtists,
}) => {
  const [artistsCachets, setArtistsCachets] = useState<ArtistsCachetsData[]>(
    []
  );
  const [t] = useTranslation();
  const isMobile = useIsMobile();

  const getArtistsCachets = useCallback(async () => {
    try {
      const { data } = await axios.get<{ data: ArtistsCachetsData[] }>(
        "/api/analytics/artists-cachet",
        {
          params: {
            start: startDate,
            end: endDate,
            artistIds: selectedArtists?.map((a) => a.id).join(","),
          },
        }
      );
      setArtistsCachets(data.data);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          window.location.href = "/login";
        }
        toast.error(error.response?.data);
      } else {
        toast.error("Something went wrong! Cannot fetch locations");
      }
    }
  }, [startDate, endDate, selectedArtists]);

  useEffect(() => {
    getArtistsCachets();
  }, [startDate, endDate, selectedArtists, getArtistsCachets]);

  const options: EChartsOption = {
    title: {
      text: t("Artists Cachet"),
      left: "start",
      textStyle: {
        fontFamily: "Public Sans",
        fontSize: "1rem",
        fontWeight: 600,
        color: "#25252D",
      },
    },
    tooltip: {
      trigger: "item",
    },

    xAxis: {
      type: "category",
      data: artistsCachets.map((a) => a.name),
      axisLabel: {
        interval: 0,
        rotate: 40,
        width: 100,
        overflow: "truncate",
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        show: !isMobile,
      },
    },
    series: [
      {
        type: "bar",
        stack: "category",
        itemStyle: { color: "#096BDE" },
        z: 3,
        label: {
          position: "top",
          show: true,
        },
        data: artistsCachets.map((a) => a.value),
      },
    ],
  };
  return <ReactECharts option={options} />;
};

export default ArtistsCachetChart;
