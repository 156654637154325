import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Button, IconButton, Input, Typography } from "@mui/joy";
import axios from "axios";
import { useAuthentication } from "context/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EventDocument, ListResponse } from "types";
import { LinearProgress } from "@mui/material";
import {
  Search,
  Delete,
  DeleteForever,
  RestoreFromTrash,
} from "@mui/icons-material";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { parseListDate } from "utils/dateParser";
import { StyledCheckbox } from "components/styled_components";
import { useDialogManger } from "context/DialogManagerContext";
import { usePrevious } from "hooks";
import { toast } from "sonner";
import PdfIcon from "components/icons/PdfIcon";
import PngIcon from "components/icons/PngIcon";
import JpgIcon from "components/icons/JpgIcon";
import { useAuthorization } from "context/AuthorizationContext";
import { CustomNoRowsOverlay } from "components/styled_components/DataGridOverlays";

interface DeletedDocsListProps {}

const DeletedDocsList: React.FC<DeletedDocsListProps> = () => {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState<EventDocument[]>([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [totalItems, setTotalItems] = useState(0);
  const [sort, setSort] = useState<GridSortModel>([
    { field: "deleted_at", sort: "asc" },
  ]);
  const [filter, setFilter] = useState("");
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { user } = useAuthentication();
  const { isAllowed } = useAuthorization();
  const { showDialog } = useDialogManger();
  let filterTimeout = useRef<NodeJS.Timeout>(null!);
  const prevFilter = usePrevious(filter);
  const RenderFileIcon = ({ ext }: { ext: string }) => {
    switch (ext) {
      case ".pdf":
        return <PdfIcon color="primary" />;
      case ".png":
        return <PngIcon color="primary" />;
      case ".jpg":
      case ".jpeg":
        return <JpgIcon color="primary" />;
      default:
        return null;
    }
  };

  const fetchDocs = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<ListResponse<EventDocument>>(
        "/api/trash",
        {
          params: {
            page: page + 1,
            limit,
            sort: `${sort[0].field}:${sort[0].sort}`,
            search: filter,
          },
        }
      );
      setDocs(data.data);
      setTotalItems(data.total);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch documents"));
      }
    }
    setLoading(false);
  }, [page, sort, limit, filter, navigate, t]);

  const onDeleteDocs = async () => {
    try {
      const { status } = await axios.post(`/api/trash/remove-documents`, {
        documentIds: selectedRows,
      });
      if (status === 200) {
        setSelectedRows([]);
        fetchDocs();
        toast.success(t("Documents successfully removed"));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot remove documents"));
      }
    }
    setLoading(false);
  };

  const onDeleteDocsClick = () => {
    showDialog({
      title: "Remove permanently the selected documents?",
      body: "If you proceed with this action, the selected documents will be deleted permanently and they can't be recovered anymore.",
      actions: [
        {
          label: "Cancel",
          color: "neutral",
          variant: "plain",
          onClick: (e, closeModal) => {
            closeModal();
          },
        },
        {
          label: "Delete",
          color: "danger",
          variant: "solid",
          onClick: (e, closeModal) => {
            onDeleteDocs();
            closeModal();
          },
        },
      ],
    });
  };

  const onDeleteDoc = useCallback(
    async (docId: string) => {
      try {
        const { status } = await axios.delete(
          `/api/trash/remove-documents/${docId}`
        );
        if (status === 200) {
          fetchDocs();
          toast.success(t("Document successfully removed"));
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          //Fore the user logout if the request is unauthorized
          if (error.response?.status === 401) {
            navigate("/login");
          }
          console.log(error.response?.data);
          toast.error(error.response?.data.error);
        } else {
          console.log(error);
          toast.error(t("Something went wrong! Cannot delete document"));
        }
      }
      setLoading(false);
    },
    [fetchDocs, navigate, t]
  );

  const onDeleteDocClick = useCallback(
    (docId: string) => {
      showDialog({
        title: "Remove permanently the selected document?",
        body: "If you proceed with this action, the selected document will be deleted permanently and it can't be recovered anymore.",
        actions: [
          {
            label: "Cancel",
            color: "neutral",
            variant: "plain",
            onClick: (e, closeModal) => {
              closeModal();
            },
          },
          {
            label: "Delete",
            color: "danger",
            variant: "solid",
            onClick: (e, closeModal) => {
              onDeleteDoc(docId);
              closeModal();
            },
          },
        ],
      });
    },
    [onDeleteDoc, showDialog]
  );

  const onRestoreDocs = async () => {
    try {
      const { status } = await axios.post(`/api/trash/restore-documents`, {
        documentIds: selectedRows,
      });
      if (status === 200) {
        setSelectedRows([]);
        fetchDocs();
        toast.success(t("Documents successfully restored"));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot restore documents"));
      }
    }
    setLoading(false);
  };

  const onRestoreDocsClick = () => {
    showDialog({
      title: "Restore the selected documents?",
      body: "If you proceed with this action, the selected documents will be restored and shows as attachments in their own event.",
      actions: [
        {
          label: "Cancel",
          color: "neutral",
          variant: "plain",
          onClick: (e, closeModal) => {
            closeModal();
          },
        },
        {
          label: "Restore",
          color: "primary",
          variant: "solid",
          onClick: (e, closeModal) => {
            onRestoreDocs();
            closeModal();
          },
        },
      ],
    });
  };

  const onRestoreDoc = useCallback(
    async (docId: string) => {
      try {
        const { status } = await axios.patch(
          `/api/trash/restore-documents/${docId}`
        );
        if (status === 200) {
          fetchDocs();
          toast.success(t("Document successfully restored"));
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          //Fore the user logout if the request is unauthorized
          if (error.response?.status === 401) {
            navigate("/login");
          }
          console.log(error.response?.data);
          toast.error(error.response?.data.error);
        } else {
          console.log(error);
          toast.error(t("Something went wrong! Cannot restore document"));
        }
      }
      setLoading(false);
    },
    [fetchDocs, navigate, t]
  );

  const onRestoreDocClick = useCallback(
    (docId: string) => {
      showDialog({
        title: "Restore the selected document?",
        body: "If you proceed with this action, the selected document will be restored and shows as attachment in its event.",
        actions: [
          {
            label: "Cancel",
            color: "neutral",
            variant: "plain",
            onClick: (e, closeModal) => {
              closeModal();
            },
          },
          {
            label: "Restore",
            color: "primary",
            variant: "solid",
            onClick: (e, closeModal) => {
              onRestoreDoc(docId);
              closeModal();
            },
          },
        ],
      });
    },
    [onRestoreDoc, showDialog]
  );

  const onSortChange = (
    model: GridSortModel,
    details: GridCallbackDetails<any>
  ) => {
    if (model.length === 0) {
      setSort([{ field: "deleted_at", sort: "asc" }]);
    } else {
      setSort(model);
    }
  };

  const columns = useMemo<GridColDef<EventDocument>[]>(
    () => [
      {
        field: "icon",
        headerName: "",
        sortable: false,
        filterable: false,
        flex: 0,
        hideable: false,
        renderCell: ({ row }) => (
          <div className="MuiDataGrid-cellContent">
            <RenderFileIcon ext={row.extension} />
          </div>
        ),
      },
      {
        field: "source_name",
        headerName: `${t("Name")}`,
        sortable: true,
        filterable: false,
        flex: 1,
        hideable: false,
        renderCell: ({ row }) => (
          <div className="MuiDataGrid-cellContent" title={row.sourceName}>
            {row.sourceName}
          </div>
        ),
      },
      {
        field: "ext",
        headerName: `${t("Extension")}`,
        sortable: true,
        filterable: false,
        flex: 1,
        hideable: false,
        renderCell: ({ row }) => (
          <div className="MuiDataGrid-cellContent" title={row.extension}>
            {row.extension}
          </div>
        ),
      },
      {
        field: "deleted_at",
        headerName: `${t("Deleted at")}`,
        sortable: true,
        filterable: false,
        hideable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <>{row.deletedAt ? parseListDate(row.deletedAt) : ""}</>
        ),
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        filterable: false,
        hideable: false,
        flex: 0,
        renderCell: ({ row }) => (
          <Box display={"inline-flex"} columnGap={0.5} width={"auto"}>
            <IconButton
              size="sm"
              variant="plain"
              color="neutral"
              title={`${t("Restore Document")}`}
              onClick={() => onRestoreDocClick(row.id)}
            >
              <RestoreFromTrash />
            </IconButton>
            <IconButton
              size="sm"
              variant="plain"
              color="danger"
              title={`${t("Remove Document")}`}
              onClick={() => onDeleteDocClick(row.id)}
            >
              <DeleteForever />
            </IconButton>
          </Box>
        ),
      },
    ],
    [t, onDeleteDocClick, onRestoreDocClick]
  );

  useEffect(() => {
    if (prevFilter !== filter && prevFilter !== undefined) {
      if (filterTimeout.current) {
        clearTimeout(filterTimeout.current);
      }
      filterTimeout.current = setTimeout(() => {
        fetchDocs();
      }, 500);

      return () => {
        clearTimeout(filterTimeout.current);
      };
    } else {
      fetchDocs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, limit, filter, fetchDocs]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display="flex"
      flexDirection="column"
      rowGap={2}
    >
      <Typography level="h4" fontWeight={600}>
        {t("Deleted Documents")}
      </Typography>
      <Box
        display="flex"
        alignSelf="flex-end"
        flexDirection="row"
        width={"100%"}
      >
        <Box
          display="flex"
          alignSelf="flex-end"
          flexDirection="row"
          justifyContent="flex-start"
          flex={1}
          columnGap={2}
        >
          {isAllowed("documents.write") && user?.isSystem ? (
            <Button
              variant="soft"
              startDecorator={<RestoreFromTrash />}
              color="neutral"
              size="sm"
              disabled={selectedRows.length === 0}
              onClick={onRestoreDocsClick}
            >
              {t("Restore")}
            </Button>
          ) : null}
          {isAllowed("documents.delete") && user?.isSystem ? (
            <Button
              variant="soft"
              startDecorator={<Delete />}
              color="danger"
              size="sm"
              disabled={selectedRows.length === 0}
              onClick={onDeleteDocsClick}
            >
              {t("Delete")}
            </Button>
          ) : null}
        </Box>
        <Box
          display="flex"
          alignSelf="flex-end"
          flexDirection="row"
          justifyContent="flex-end"
          flex={1}
        >
          <Input
            size="sm"
            placeholder={`${t("Search")}...`}
            startDecorator={<Search />}
            fullWidth
            value={filter}
            onChange={(e) => setFilter(e.currentTarget.value)}
            sx={{ flex: "0.60 1" }}
          ></Input>
        </Box>
      </Box>

      <Box
        alignSelf="stretch"
        flex={"1 1"}
        maxHeight={"calc( 100% - 100px )"}
        boxSizing={"border-box"}
      >
        <DataGrid
          columns={columns}
          rows={docs}
          pagination
          paginationModel={{ page: page, pageSize: limit }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setLimit(model.pageSize);
          }}
          sortingMode="server"
          paginationMode="server"
          rowCount={totalItems}
          sortModel={sort}
          onSortModelChange={onSortChange}
          onRowSelectionModelChange={(selRows) => {
            setSelectedRows(selRows);
          }}
          checkboxSelection
          disableColumnMenu
          density="compact"
          localeText={{
            columnHeaderSortIconLabel: `${t("Sort")}`,
            noRowsLabel: `${t("No documents found")}`,
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                t("{{from}}-{{to}} of {{count}}", {
                  from,
                  to,
                  count,
                }),
              labelRowsPerPage: `${t("Rows per page")}:`,
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
          slots={{
            loadingOverlay: LinearProgress,
            baseCheckbox: StyledCheckbox,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              color: "primary",
            },
          }}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default DeletedDocsList;
