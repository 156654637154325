import React from "react";
import { Checkbox, CheckboxTypeMap } from "@mui/joy";

export const StyledCheckbox = React.forwardRef(
  (
    {
      inputProps,
      touchRippleRef,
      ...props
    }: CheckboxTypeMap & { inputProps: any; touchRippleRef: any },
    ref
  ) => <Checkbox {...props} component={"span"} />
);
