import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Delete,
  Search,
  Check,
  Add,
  ArrowBack,
  Edit,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemDecorator,
  ListItemContent,
  Avatar,
  ListItemButton,
  ListItem as MUIListItem,
  Input,
  CircularProgress,
  Typography,
  LinearProgress,
} from "@mui/joy";
import { useAuthorization } from "context/AuthorizationContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList, areEqual } from "react-window";
import { EventLocation, ListResponse } from "types";
import { GridSortModel } from "@mui/x-data-grid";
import axios from "axios";
import { toast } from "sonner";
import AutoSizer, { Size } from "react-virtualized-auto-sizer";
import { usePrevious } from "hooks";
import { useDialogManger } from "context/DialogManagerContext";
import { CustomNoRowsOverlay } from "components/styled_components/DataGridOverlays";
import LocationDetailsModal from "views/Admin/Locations/DetailsModal";

const ListItem = memo(
  ({
    data,
    index,
    style,
  }: {
    data: {
      locations: EventLocation[];
      selectedRows: string[];
      isItemLoaded: (index: number) => boolean;
      setDetailsOpen: React.Dispatch<
        React.SetStateAction<{
          open: boolean;
          locationId: string;
        }>
      >;
      setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
    };
    index: number;
    style: CSSProperties;
  }) => {
    if (!data.isItemLoaded(index)) {
      return (
        <MUIListItem style={style} sx={{ justifyContent: "center" }}>
          <CircularProgress size="sm" variant="plain" />
        </MUIListItem>
      );
    }

    return (
      <ListItemButton
        style={style}
        sx={{
          px: 2,
          gap: 2,
        }}
        onClick={() =>
          data.setDetailsOpen({
            open: true,
            locationId: data.locations[index].id,
          })
        }
      >
        <ListItemDecorator>
          <Avatar
            color={
              data.selectedRows.includes(data.locations[index].id)
                ? "primary"
                : "neutral"
            }
            onClick={(e) => {
              e.stopPropagation();
              if (!data.selectedRows.includes(data.locations[index].id)) {
                data.setSelectedRows([
                  ...data.selectedRows,
                  data.locations[index].id,
                ]);
              } else {
                data.setSelectedRows(
                  data.selectedRows.filter(
                    (r: string) => r !== data.locations[index].id
                  )
                );
              }
            }}
          >
            {data.selectedRows.includes(data.locations[index].id) ? (
              <Check />
            ) : (
              <>{data.locations[index].description[0].toUpperCase()}</>
            )}
          </Avatar>
        </ListItemDecorator>
        <ListItemContent>
          <Typography>{data.locations[index].description}</Typography>
          <Typography level="body2" noWrap>
            {`${data.locations[index].address}, ${data.locations[index].city} (${data.locations[index].province})`}
          </Typography>
        </ListItemContent>
      </ListItemButton>
    );
  },
  areEqual
);

const LocationsMobileList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState({
    open: false,
    locationId: "",
  });
  const [locations, setLocations] = useState<EventLocation[]>([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(25);
  const [totalItems, setTotalItems] = useState(0);
  const [sort] = useState<GridSortModel>([
    { field: "created_at", sort: "asc" },
  ]);
  const [filter, setFilter] = useState("");
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { showDialog } = useDialogManger();
  const { isAllowed } = useAuthorization();
  let filterTimeout = useRef<NodeJS.Timeout>(null!);
  const prevFilter = usePrevious(filter);
  const itemCount =
    locations.length < totalItems ? locations.length + 1 : locations.length;

  const fetchLocations = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<ListResponse<EventLocation>>(
        "/api/locations",
        {
          params: {
            page,
            limit,
            sort: `${sort[0].field}:${sort[0].sort}`,
            search: filter,
          },
        }
      );
      setLocations(data.data);
      setTotalItems(data.total);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
          return;
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch locations"));
      }
    }
    setLoading(false);
  }, [page, limit, sort, filter, navigate, t]);

  const fetchMoreLocations = async () => {
    setNextPageLoading(true);

    try {
      const { data } = await axios.get<ListResponse<EventLocation>>(
        "/api/locations",
        {
          params: {
            page: page + 1,
            limit,
            sort: `${sort[0].field}:${sort[0].sort}`,
            search: filter,
          },
        }
      );
      setLocations([...locations, ...data.data]);
      setPage(data.page);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
          return;
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch locations"));
      }
    }
    setNextPageLoading(false);
  };

  const isItemLoaded = (index: number) =>
    !(locations.length < totalItems) || index < locations.length;

  const loadMoreItems = loading ? () => {} : fetchMoreLocations;

  const onDeleteLocations = async () => {
    try {
      const { status } = await axios.post(`/api/locations/delete`, {
        locationIds: selectedRows,
      });
      if (status === 200) {
        setSelectedRows([]);
        fetchLocations();
        toast.success(t("Locations successfully deleted"));
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
          return;
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot delete locations"));
      }
    }
    setLoading(false);
  };

  const onDeleteClick = () => {
    showDialog({
      title: "Delete the selected locations?",
      body: "If you proceed with this action, the selected locations will be deleted.",
      actions: [
        {
          label: "Cancel",
          color: "neutral",
          variant: "plain",
          onClick: (e, closeModal) => {
            closeModal();
          },
        },
        {
          label: "Delete",
          color: "danger",
          variant: "solid",
          onClick: (e, closeModal) => {
            onDeleteLocations();
            closeModal();
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (prevFilter !== filter && prevFilter !== undefined) {
      if (filterTimeout.current) {
        clearTimeout(filterTimeout.current);
      }
      filterTimeout.current = setTimeout(() => {
        fetchLocations();
      }, 500);

      return () => {
        clearTimeout(filterTimeout.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, prevFilter]);

  useEffect(() => {
    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box position={"absolute"} top={0} left={0} width={"100vw"}>
        {loading || nextPageLoading ? <LinearProgress /> : null}
      </Box>
      <Typography level="h4" fontWeight={600} pt={2} px={2}>
        {t("Locations")}
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"row"}
        width={"100%"}
        py={2}
        px={1}
        boxSizing={"border-box"}
        justifyContent={selectedRows.length > 0 ? "flex-end" : "flex-start"}
        alignItems={"center"}
      >
        {selectedRows.length > 0 &&
        (isAllowed("locations.write") || isAllowed("locations.delete")) ? (
          <Box
            display={"flex"}
            flexDirection={"row"}
            flex={"1 1"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              flex={"1 1"}
              alignItems={"center"}
              gap={2}
            >
              <IconButton
                variant="plain"
                color="neutral"
                onClick={() => setSelectedRows([])}
              >
                <ArrowBack />
              </IconButton>
              <Typography level="h6">{selectedRows.length}</Typography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              flex={"1 1"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              {selectedRows.length === 1 && isAllowed("locations.write") ? (
                <IconButton
                  variant="plain"
                  color="neutral"
                  onClick={() => navigate(selectedRows[0])}
                >
                  <Edit />
                </IconButton>
              ) : null}
              {isAllowed("locations.delete") ? (
                <IconButton
                  variant="plain"
                  color="danger"
                  onClick={onDeleteClick}
                >
                  <Delete />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        ) : (
          <Input
            size="sm"
            startDecorator={<Search />}
            fullWidth
            value={filter}
            placeholder={`${t("Type to search")}...`}
            onChange={(e) => setFilter(e.currentTarget.value)}
          />
        )}
      </Box>
      {isAllowed("locations.write") ? (
        <IconButton
          sx={{
            position: "absolute",
            right: "1rem",
            bottom: "1rem",
            zIndex: 9,
            // boxShadow:
            //   "rgba(0, 0, 0, 0.05) 0px 3px 5px -1px, rgba(0, 0, 0, 0.10) -1px 1px 10px 0px, rgba(0, 0, 0, 0.10) 0px 1px 18px 0px",
          }}
          size="lg"
          onClick={() => navigate("new")}
        >
          <Add fontSize="large" />
        </IconButton>
      ) : null}
      <Box flex={"1 1 auto"} boxSizing={"border-box"} py={1}>
        <AutoSizer>
          {({ height, width }: Size) => (
            <InfiniteLoader
              itemCount={itemCount}
              threshold={16}
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  itemCount={itemCount}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  height={height - 20}
                  width={width}
                  itemSize={60}
                  itemData={{
                    locations,
                    selectedRows,
                    isItemLoaded,
                    setDetailsOpen,
                    setSelectedRows,
                  }}
                  innerElementType={
                    locations.length === 0
                      ? () => <CustomNoRowsOverlay label="No locations found" />
                      : "div"
                  }
                >
                  {ListItem}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </Box>
      <LocationDetailsModal
        layout="fullscreen"
        open={detailsOpen.open}
        locationId={detailsOpen.locationId}
        onClose={() => {
          setDetailsOpen({ open: false, locationId: "" });
        }}
      />
    </>
  );
};

export default LocationsMobileList;
