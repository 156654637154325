import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_lang from './i18n/en.json'
import it_lang from './i18n/it.json'

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: en_lang
            },
            it: {
                translation: it_lang
            }
        },
        lng: navigator.language, // if you're using a language detector, do not define the lng option
        fallbackLng: "en-EN",

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;   