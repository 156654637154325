import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "views/Login";
import Layout from "components/Layout";
import { Box } from "@mui/joy";
import AdminLayout from "views/Admin";
import Users, { CreateUser, EditUser, UsersList } from "views/Admin/Users";
import Artists, {
  ArtistsList,
  CreateArtist,
  EditArtist,
} from "views/Admin/Artists";
import Locations, {
  CreateLocation,
  EditLocation,
  LocationsList,
} from "views/Admin/Locations";
import AuthMiddleware from "components/AuthMiddleware";
import Calendar from "views/Calendar";
import AccountSettings from "views/AccountSettings";
import Trash from "views/Admin/Trash";
import { MobileCalendar, MobileLayout } from "views/mobile";
import MobileAdminLayout from "views/mobile/Admin";
import UsersMobileList from "views/mobile/Admin/Users/List";
import RecoverPassword from "views/RecoverPassword";
import ResetPassword from "views/ResetPassword";
import TrashMobileList from "views/mobile/Admin/Trash/List";
import LocationsMobileList from "views/mobile/Admin/Locations/List";
import ArtistsMobileList from "views/mobile/Admin/Artists/List";
import Analytics from "views/Analytics";
import MobileAnalytics from "views/mobile/MobileAnalytics";
import { useIsMobile } from "hooks/useIsMobile";

const App: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <Box
      className="App"
      sx={{ overflow: "hidden", height: isMobile ? "100%" : "auto" }}
    >
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="recover-password" element={<RecoverPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="/" element={<AuthMiddleware />}>
          <Route element={isMobile ? <MobileLayout /> : <Layout />}>
            <Route
              path="dashboard"
              element={isMobile ? <MobileCalendar /> : <Calendar />}
            />
            <Route
              path="analytics"
              element={isMobile ? <MobileAnalytics /> : <Analytics />}
            />
            <Route
              path="admin"
              element={isMobile ? <MobileAdminLayout /> : <AdminLayout />}
            >
              {/* Users routes */}
              <Route path="users" element={<Users />}>
                <Route
                  index
                  element={isMobile ? <UsersMobileList /> : <UsersList />}
                />
                <Route path="new" element={<CreateUser />} />
                <Route path=":id" element={<EditUser />} />
              </Route>
              {/* Locations routes */}
              <Route path="locations" element={<Locations />}>
                <Route
                  index
                  element={
                    isMobile ? <LocationsMobileList /> : <LocationsList />
                  }
                />
                <Route path="new" element={<CreateLocation />} />
                <Route path=":id" element={<EditLocation />} />
              </Route>
              {/* Artists routes */}
              <Route path="artists" element={<Artists />}>
                <Route
                  index
                  element={isMobile ? <ArtistsMobileList /> : <ArtistsList />}
                />
                <Route path="new" element={<CreateArtist />} />
                <Route path=":id" element={<EditArtist />} />
              </Route>
              <Route
                path="trash"
                element={isMobile ? <TrashMobileList /> : <Trash />}
              />
            </Route>
            <Route path="account-settings" element={<AccountSettings />} />
          </Route>
          <Route path="*" element={<>404</>} />
        </Route>
      </Routes>
    </Box>
  );
};

export default App;
