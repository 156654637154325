import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { Artist } from "types";
import ReactECharts from "echarts-for-react";
import * as eChart from "echarts/core";
import italyJson from "resources/geo/italy_regions.json";
import { useIsMobile } from "hooks/useIsMobile";

interface EventsByLocationsChartProps {
  startDate: string;
  endDate: string;
  selectedArtists: Artist[] | undefined;
}

type EventsByLocationData = {
  name: string;
  value: number;
  latitude: number;
  longitude: number;
};

const EventsByLocationsChart: React.FC<EventsByLocationsChartProps> = ({
  startDate,
  endDate,
  selectedArtists,
}) => {
  const [locationsEvents, setLocationsEvents] = useState<
    EventsByLocationData[]
  >([]);
  const [t] = useTranslation();
  const isMobile = useIsMobile();
  const getLocationEvents = useCallback(async () => {
    try {
      const { data } = await axios.get<{ data: EventsByLocationData[] }>(
        "/api/analytics/location-events",
        {
          params: {
            start: startDate,
            end: endDate,
            artistIds: selectedArtists
              ? selectedArtists.map((a) => a.id).join(",")
              : undefined,
          },
        }
      );
      setLocationsEvents(data.data);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          window.location.href = "/login";
        }
        toast.error(error.response?.data);
      } else {
        toast.error("Something went wrong! Cannot fetch locations");
      }
    }
  }, [startDate, endDate, selectedArtists]);

  useEffect(() => {
    getLocationEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, selectedArtists]);

  eChart.registerMap("IT", italyJson as any);

  const options = {
    title: {
      text: t("Events by Location"),
      left: "start",
      textStyle: {
        fontFamily: "Public Sans",
        fontSize: "1rem",
        fontWeight: 600,
        color: "#25252D",
      },
    },
    tooltip: {
      trigger: "item",
    },
    geo: {
      tooltip: {
        show: true,
        formatter: (p: any) => `${p.name}: <strong>${p.value[2]}</strong>`,
      },
      map: "IT",
      roam: !isMobile,
      silent: true,
    },
    series: [
      {
        name: t("Location"),
        type: "scatter",
        coordinateSystem: "geo",
        geoIndex: 0,

        itemStyle: {
          color: "#b02a02",
        },
        label: {
          show: false,
          formatter: "{b}",
          position: "top",
        },
        emphasis: {
          label: {
            show: true,
            formatter: "{b}",
          },
        },
        data: locationsEvents.map((l) => ({
          name: l.name,
          value: [l.longitude, l.latitude, l.value],
        })),
      },
    ],
  };
  return <ReactECharts option={options} style={{ height: "450px" }} />;
};

export default EventsByLocationsChart;
