import {
  EmailOutlined,
  KeyOutlined,
  LanguageOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Input,
  Select,
  Sheet,
  Typography,
  Option,
  Chip,
  Button,
} from "@mui/joy";
import axios from "axios";
import { useAuthentication } from "context/AuthenticationContext";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import ChangePasswordModal from "components/ChangePasswordModal";
import { useMediaQuery } from "react-responsive";

interface AccountSettingsProps {}

const AccountSettings: React.FC<AccountSettingsProps> = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  const { user, getIdentity } = useAuthentication();
  const [identity, setIdentity] = useState({
    email: user ? user.email : "",
    firstName: user ? user.firstName : "",
    lastName: user ? user.lastName : "",
    lang: user ? user.lang : "en_EN",
  });
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const navigate = useNavigate();
  const [t] = useTranslation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIdentity({ ...identity, [e.currentTarget.name]: e.currentTarget.value });
  };

  const onLangChange = (
    e: React.SyntheticEvent | null,
    value: string | null
  ) => {
    setIdentity({ ...identity, lang: value ? value : "" });
  };

  const onSubmit = async () => {
    try {
      const res = await axios.patch("/auth/identity", identity);
      if (res.status === 200) {
        toast.success(t("User data updated with success"));
        getIdentity();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot update user data"));
      }
    }
  };

  return (
    <Box
      flex="1 1"
      p={isMobile ? 0 : 2}
      height={!isMobile ? "calc(100% - 32px)" : "100%"}
    >
      <Sheet
        color="neutral"
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: isMobile ? 0 : 16,
          boxSizing: "border-box",
          p: 2,
        }}
      >
        <Box
          width={"100%"}
          height={isMobile ? "calc(100% - 80px)" : "calc(100% - 40px)"}
          display="flex"
          flexDirection="column"
          rowGap={2}
        >
          <Typography level="h4" fontWeight={600}>
            {t("Account Settings")}
          </Typography>
          {/* First Name       Last Name */}
          <Box
            width={"100%"}
            display="flex"
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Box display={"flex"} flex={"0 1 250px"}>
              <Typography level="body1">{t("Name")}</Typography>
            </Box>
            <Box
              display={"flex"}
              flex={"1 1 auto"}
              px={4}
              gap={2}
              flexWrap={"wrap"}
            >
              <Input
                name="firstName"
                placeholder={`${t("First Name")}`}
                value={identity.firstName}
                fullWidth
                onChange={onChange}
              />
              <Input
                name="lastName"
                placeholder={`${t("Last Name")}`}
                value={identity.lastName}
                fullWidth
                onChange={onChange}
              />
            </Box>
          </Box>
          <Divider />
          {/* Email                      */}
          <Box
            width={"100%"}
            display="flex"
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Box display={"flex"} flex={"0 1 250px"}>
              <Typography level="body1">{t("Email")}</Typography>
            </Box>
            <Box display={"flex"} flex={"1 1 auto"} px={4} columnGap={2}>
              <Input
                name="email"
                placeholder={`${t("Email")}`}
                value={identity.email}
                fullWidth
                startDecorator={<EmailOutlined />}
                onChange={onChange}
              />
            </Box>
          </Box>
          <Divider />
          {/* Role                   */}
          <Box
            width={"100%"}
            display="flex"
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Box display={"flex"} flex={"0 1 250px"}>
              <Typography level="body1">{t("Role")}</Typography>
            </Box>
            <Box display={"flex"} flex={"1 1 auto"} px={4} columnGap={2}>
              <Input name="role" value={user?.role.name} disabled fullWidth />
            </Box>
          </Box>
          <Divider />
          <Box
            width={"100%"}
            display="flex"
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Box display={"flex"} flex={"0 1 250px"}>
              <Typography level="body1">{t("Language")}</Typography>
            </Box>
            <Box display={"flex"} flex={"1 1 auto"} px={4} columnGap={2}>
              <Select
                name="lang"
                value={identity.lang}
                startDecorator={<LanguageOutlined />}
                onChange={onLangChange}
              >
                <Option value={"en_EN"}>{t("English")}</Option>
                <Option value={"it_IT"}>{t("Italian")}</Option>
              </Select>
            </Box>
          </Box>
          <Divider />
          {/* Artists                    */}
          <Box
            width={"100%"}
            display="flex"
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Box display={"flex"} flex={"0 1 250px"}>
              <Typography level="body1">{t("Artists")}</Typography>
            </Box>
            <Box
              display={"flex"}
              flex={"1 1 auto"}
              flexWrap={"wrap"}
              px={4}
              gap={2}
              maxHeight={"350px"}
              overflow={"hidden auto"}
            >
              {user?.artists?.map((a) => (
                <Chip key={a.id} variant="soft" color="primary">
                  {a.name}
                </Chip>
              ))}
            </Box>
          </Box>
          <Divider />
          {/* Change Password            */}
          <Box
            width={"100%"}
            display="flex"
            flexDirection={"row"}
            alignItems={"center"}
          >
            <Box display={"flex"} flex={"0 1 250px"}>
              <Typography level="body1">{t("Password")}</Typography>
            </Box>
            <Button
              startDecorator={<KeyOutlined />}
              variant="outlined"
              onClick={() => setPasswordModalOpen(true)}
            >
              {t("Change")}
            </Button>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={isMobile ? "column-reverse" : "row"}
          justifyContent={"flex-end"}
        >
          <Button
            variant="plain"
            color="neutral"
            onClick={() => navigate(-1)}
            fullWidth={isMobile}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="solid"
            color="primary"
            fullWidth={isMobile}
            onClick={onSubmit}
          >
            {t("Confirm")}
          </Button>
        </Box>
      </Sheet>
      <ChangePasswordModal
        open={passwordModalOpen}
        onClose={() => {
          setPasswordModalOpen(false);
        }}
      />
    </Box>
  );
};

export default AccountSettings;
