import React from "react";
import { Box, IconButton, Sheet, Typography } from "@mui/joy";
import PdfIcon from "components/icons/PdfIcon";
import JpgIcon from "components/icons/JpgIcon";
import PngIcon from "components/icons/PngIcon";
import { Close } from "@mui/icons-material";
import { EventDocument } from "types";
import { useAuthorization } from "context/AuthorizationContext";

interface DocumentElementProps {
  file: EventDocument;
  dense?: boolean;
  onRemove: (fileId: string) => void;
  onClick?: (fileId: string) => void;
}

const DocumentElement: React.FC<DocumentElementProps> = (props) => {
  const { isAllowed } = useAuthorization();
  const RenderFileIcon = () => {
    switch (props.file.extension) {
      case ".pdf":
        return <PdfIcon color="primary" />;
      case ".png":
        return <PngIcon color="primary" />;
      case ".jpg":
      case ".jpeg":
        return <JpgIcon color="primary" />;
      default:
        return null;
    }
  };

  const onDeleteDocument = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.stopPropagation();
    props.onRemove(props.file.id);
  };

  return (
    <Sheet
      color="primary"
      variant="outlined"
      title={props.file.sourceName}
      sx={{
        display: "flex",
        flexDirection: "row",
        p: props.dense ? 0.5 : 0.7,
        borderRadius: props.dense ? 5 : 8,
        position: "relative",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => (props.onClick ? props.onClick(props.file.id) : {})}
    >
      <RenderFileIcon />
      <Box
        display={"flex"}
        flexDirection={"row"}
        flex={"1 1 200px"}
        maxWidth={120}
        overflow={"hidden"}
        alignItems={"center"}
      >
        <Typography
          level={props.dense ? "body3" : "body2"}
          color="primary"
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {props.file.sourceName}
        </Typography>
        {isAllowed("documents.delete") ? (
          <IconButton
            variant="plain"
            color="neutral"
            sx={{
              "--IconButton-size": props.dense ? "19px" : "22px",
            }}
            onClick={onDeleteDocument}
          >
            <Close fontSize={props.dense ? "small" : "inherit"} />
          </IconButton>
        ) : null}
      </Box>
    </Sheet>
  );
};

export default DocumentElement;
