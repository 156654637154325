import { Box, Typography } from "@mui/joy";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { Artist } from "types";
import EuroIcon from "@mui/icons-material/Euro";

interface TotalProfitChartProps {
  startDate: string;
  endDate: string;
  selectedArtists: Artist[] | undefined;
}

const TotalProfitChart: React.FC<TotalProfitChartProps> = ({
  startDate,
  endDate,
  selectedArtists,
}) => {
  const [profit, setProfit] = useState(0);

  const [t] = useTranslation();

  const getProfit = useCallback(async () => {
    try {
      const { data } = await axios.get<{ data: number }>(
        "/api/analytics/profit",
        {
          params: {
            start: startDate,
            end: endDate,
            artistIds: selectedArtists?.map((a) => a.id).join(","),
          },
        }
      );

      setProfit(data.data);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          window.location.href = "/login";
        }
        toast.error(error.response?.data);
      } else {
        toast.error("Something went wrong! Cannot fetch locations");
      }
    }
  }, [startDate, endDate, selectedArtists]);

  useEffect(() => {
    getProfit();
  }, [startDate, endDate, selectedArtists, getProfit]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      width={"100%"}
      height={"100%"}
      gap={2}
    >
      <Typography
        level="body1"
        fontWeight={600}
        fontSize={"1rem"}
        sx={{ color: "#25252D" }}
      >
        {t("Total Profit")}
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
      >
        <EuroIcon />
        <Typography level="h4" lineHeight={1}>
          {profit}
        </Typography>
      </Box>
    </Box>
  );
};

export default TotalProfitChart;
