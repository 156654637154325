import axios from "axios"
import { Artist, ArtistEvent, ListResponse } from "types"
import { QueryParams } from ".";
import { toast } from "sonner"


export const fetchArtists = async ({ page, limit, sort, search }: QueryParams, errorCallback?: (error: string) => void) => {
    try {
        const { data } = await axios.get<ListResponse<Artist>>("/api/artists", {
            params: {
                page, limit, sort, search
            },
        });
        return data
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            //Fore the user logout if the request is unauthorized
            if (error.response?.status === 401) {
                window.location.href = "/login"
            }
            if (errorCallback) {
                errorCallback(error.response?.data.error)
            }
            toast.error(error.response?.data.error);
        } else {
            if (errorCallback) {
                errorCallback("Something went wrong! Cannot fetch artist")
            }
            console.log(error);

            toast.error("Something went wrong! Cannot fetch artist");
        }
    }

};

export const fetchArtistEvents = async (artistIds?: string[], startDate?: string, endDate?: string, errorCallback?: (error: string) => void) => {
    try {
        const { data } = await axios.get<{ data: ArtistEvent[] }>("/api/artists/events", {
            params: {
                artistIds: artistIds?.join(","),
                start: startDate,
                end: endDate
            },
        });
        return data
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            //Fore the user logout if the request is unauthorized
            if (error.response?.status === 401) {
                window.location.href = "/login"
            }
            if (errorCallback) {
                errorCallback(error.response?.data.error)
            }
            toast.error(error.response?.data.error);
        } else {
            if (errorCallback) {
                errorCallback("Error trying to fetch artists events")
            }
            console.log(error);
            toast.error("Error trying to fetch artists events");
        }
    }
}

export const fetchUserArtists = async (errorCallback?: (error: any) => void) => {
    try {
        const { data } = await axios.get<{ data: Artist[] }>("/api/users/artists");
        return data
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            //Fore the user logout if the request is unauthorized
            if (error.response?.status === 401) {
                window.location.href = "/login"
            } else {
                if (errorCallback) {
                    errorCallback(error)
                }
                toast.error(error.response?.data);
            }
        } else {
            if (errorCallback) {
                errorCallback(error)
            }
            toast.error("Something went wrong! Cannot fetch artist");
        }
    }
}