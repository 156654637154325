import {
  DateSpanApi,
  EventClickArg,
  EventSourceInput,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import {
  Add,
  ArrowDropDown,
  ArrowDropUp,
  CalendarViewMonthOutlined,
  CalendarViewWeek,
  MenuRounded,
  ViewDayOutlined,
  ViewWeekOutlined,
  CalendarMonthRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemDecorator,
  ListItemContent,
  Sheet,
  Checkbox,
  Typography,
  Button,
} from "@mui/joy";
import MobileUserMenu from "components/mobile/MobileUserMenu";
import Drawer from "components/styled_components/Drawer";
import { useArtists } from "context/ArtistsContext";
import { useAuthentication } from "context/AuthenticationContext";
import { useAuthorization } from "context/AuthorizationContext";
import { useEventLocations } from "context/LocationsContext";
import {
  add,
  endOfMonth,
  endOfYear,
  format,
  formatISO,
  isSameMonth,
  startOfMonth,
  startOfYear,
} from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ArtistEvent, EventsDateRange, ViewModeTypes } from "types";
import { Collapse, darken } from "@mui/material";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import { CalendarWrapper } from "components/styled_components";
import { fetchArtistEvents } from "utils";
import { useSwipe } from "hooks/useSwipe";
import { enUS, it } from "date-fns/locale";
import { DateCalendar } from "@mui/x-date-pickers";
import { PickerSelectionState } from "@mui/x-date-pickers/internals";
import CalendarDateHeader from "components/styled_components/CalendarDateHeader";
import MobileEventDetails from "components/mobile/MobileEventDetails";
import EventModal from "components/EventModal";
import axios from "axios";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useDialogManger } from "context/DialogManagerContext";

interface MobileCalendarProps {}

const MobileCalendar: React.FC<MobileCalendarProps> = () => {
  const [ignoredArtists, setIgnoredArtists] = useState<string[]>([]);
  const [currentDay, setCurrentDay] = useState(new Date());
  const initialDateRange = useMemo<EventsDateRange>(() => {
    const offset = 6;
    let startDate = startOfMonth(new Date());
    startDate = add(startDate, { days: -offset });
    let endDate = endOfMonth(startDate);
    endDate = add(endDate, { days: offset, months: 1 });
    return {
      start: formatISO(startDate, { representation: "date" }),
      end: formatISO(endDate, { representation: "date" }),
    };
  }, []);
  const [userMenuAnchEl, setUserMenuAnchEl] = useState<HTMLDivElement | null>(
    null
  );
  const { user } = useAuthentication();
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [expandCalendar, setExpandCalendar] = useState(false);
  const [eventDetail, setEventDetail] = useState<{
    open: boolean;
    event: ArtistEvent | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [eventModal, setEventModal] = useState<{
    open: boolean;
    event: ArtistEvent | undefined;
    eventDate: string | undefined;
  }>({
    open: false,
    event: undefined,
    eventDate: undefined,
  });
  const [view, setView] = useState<ViewModeTypes>("dayGridDay");
  const [dateRange, setDateRange] = useState<EventsDateRange>(initialDateRange);
  const [events, setEvents] = useState<ArtistEvent[]>([]);
  const [forceEventUpdate, setForceEventUpdate] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState<EventSourceInput>([]);
  const [title, setTitle] = useState("");
  const [isSwipeActive, setIsSwipeActive] = useState(false);
  const [t] = useTranslation();
  const { isAllowed } = useAuthorization();
  const { locations } = useEventLocations();
  const { artists } = useArtists();
  const navigate = useNavigate();
  const { showDialog } = useDialogManger();
  const initialDate = useMemo(() => new Date(), []);
  const calendarWrapperRef = React.createRef<HTMLDivElement>();
  const calendarRef = useRef<FullCalendar | null>(null);
  const filteredArtists = useMemo<string[]>(() => {
    return artists
      .filter((a) => !ignoredArtists.includes(a.id))
      .map((fa) => fa.id);
  }, [ignoredArtists, artists]);

  const onPrevClick = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      setCurrentDay(calendarApi.getDate());
      setTitle(
        format(calendarApi.getDate(), "MMMM", {
          locale: user?.lang === "en_EN" ? enUS : it,
        })
      );
      if (!isSameMonth(currentDay, calendarApi.getDate())) {
        evalDatesRange(calendarApi.getDate());
      }
    }
  };

  const onNextClick = (e?: React.MouseEvent<HTMLAnchorElement>) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      setCurrentDay(calendarApi.getDate());
      setTitle(
        format(calendarApi.getDate(), "MMMM", {
          locale: user?.lang === "en_EN" ? enUS : it,
        })
      );
      if (!isSameMonth(currentDay, calendarApi.getDate())) {
        evalDatesRange(calendarApi.getDate());
      }
    }
  };

  const onSwipeLeft = (e: TouchEvent) => {
    if (!toggleDrawer) {
      onPrevClick();
    }
    setIsSwipeActive(false);
  };

  const onSwipeRight = (e: TouchEvent) => {
    if (!toggleDrawer) {
      onNextClick();
    }
    setIsSwipeActive(false);
  };

  //Swipe handler
  useSwipe(
    {
      left: onSwipeLeft,
      right: onSwipeRight,
    },
    (swipe) => setIsSwipeActive(swipe)
  );

  const renderAvatarText = () => {
    return user?.email[0].toUpperCase();
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchEl(null);
  };

  const handleUserMenuOpen = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setUserMenuAnchEl(e.currentTarget);
  };

  const selectionAllowed = (args: DateSpanApi): boolean => {
    let startDate = args.start;
    let endDate = args.end;
    endDate.setSeconds(endDate.getSeconds() - 1); // allow full day selection
    if (
      startDate.getDate() === endDate.getDate() &&
      isAllowed("events.write")
    ) {
      return true;
    }

    return false;
  };

  const onDateChange = (
    value: Date | null,
    selectionState?: PickerSelectionState
  ) => {
    if (calendarRef.current && value) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(value);
      setTitle(
        format(calendarApi.getDate(), "MMMM", {
          locale: user?.lang === "en_EN" ? enUS : it,
        })
      );
      setCurrentDay(calendarApi.getDate());
      if (!isSameMonth(currentDay, value)) {
        evalDatesRange(value);
      }
    }
  };

  const onEventClick = ({ el, event }: EventClickArg) => {
    const selEvent = events.find((e) => e.id === event.id);
    if (selEvent) {
      setEventDetail({
        open: true,
        event: selEvent,
      });
    }
  };

  const evalDatesRange = useCallback(
    (value: Date, changedView?: ViewModeTypes) => {
      const offset = 6;

      if (
        changedView
          ? changedView === "multiMonthYear"
          : view === "multiMonthYear"
      ) {
        setDateRange({
          start: formatISO(startOfYear(value), { representation: "date" }),
          end: formatISO(endOfYear(value), { representation: "date" }),
        });
        return;
      }

      let startDate = startOfMonth(value);
      startDate = add(startDate, { days: -offset });
      let endDate = endOfMonth(startDate);
      endDate = add(endDate, { days: offset, months: 1 });
      setDateRange({
        start: formatISO(startDate, { representation: "date" }),
        end: formatISO(endDate, { representation: "date" }),
      });
    },
    [view]
  );

  const getEvents = useCallback(async () => {
    const data = await fetchArtistEvents(
      filteredArtists.length === artists.length ? undefined : filteredArtists,
      dateRange.start,
      dateRange.end
    );
    if (data) {
      setEvents(data.data);
      const mappedEvents: EventSourceInput = data.data.map((e) => {
        const artist = artists.find((a) => a.id === e.artistId);
        const location = locations.find((l) => l.id === e.locationId);
        return {
          id: e.id,
          allDay: true,
          date: e.date,
          backgroundColor: artist?.color,
          borderColor: artist?.color,
          editable: false,
          groupId: e.artistId,
          title: location?.description,
        };
      });
      setCalendarEvents(mappedEvents);
      setForceEventUpdate(false);
      //Update event on details or edit modals
      if (eventModal.open) {
        setEventModal({
          ...eventModal,
          event: data.data.find((e) => e.id === eventModal.event?.id),
        });
      }
      if (eventDetail.open) {
        setEventDetail({
          ...eventDetail,
          event: data.data.find((e) => e.id === eventDetail.event?.id),
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forceEventUpdate,
    filteredArtists,
    artists,
    dateRange.start,
    dateRange.end,
    locations,
  ]);

  const onViewChange = (value: ViewModeTypes) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(value);
      setView(value);
      setTitle(
        format(currentDay, "MMMM", {
          locale: user?.lang === "en_EN" ? enUS : it,
        })
      );
      if (value === "multiMonthYear") {
        evalDatesRange(calendarApi.getDate(), value);
      }
    }
    if (value === "dayGridMonth" || value === "multiMonthYear") {
      setExpandCalendar(false);
    }
  };

  const onEditEvent = (e: ArtistEvent) => {
    if (isAllowed("events.write")) {
      setEventDetail({
        open: false,
        event: undefined,
      });
      setEventModal({
        open: true,
        event: e,
        eventDate: undefined,
      });
    }
  };

  const deleteEvent = async (e: ArtistEvent) => {
    try {
      const { status } = await axios.delete(`/api/events/${e.id}`, {
        params: { artistId: e.artistId },
      });
      if (status === 200) {
        setEventDetail({ open: false, event: undefined });
        getEvents();
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        toast.error(error.response?.data);
      } else {
        toast.error("Unable to delete the selected event");
      }
    }
  };

  const onDeleteEventClick = (artist: ArtistEvent) => {
    if (isAllowed("events.delete")) {
      showDialog({
        title: "Delete this event?",
        body: "If you proceed with this action the selected event will be deleted.",
        actions: [
          {
            label: "Cancel",
            color: "neutral",
            variant: "plain",
            onClick: (e, closeModal) => {
              closeModal();
            },
          },
          {
            label: "Delete",
            color: "danger",
            variant: "solid",
            onClick: (e, closeModal) => {
              deleteEvent(artist);
              closeModal();
            },
          },
        ],
      });
    }
  };

  useEffect(() => {
    getEvents();
  }, [ignoredArtists, getEvents]);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setTitle(
        format(calendarApi.getDate(), "MMMM", {
          locale: user?.lang === "en_EN" ? enUS : it,
        })
      );
    }
    evalDatesRange(currentDay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      position={"relative"}
    >
      {/* Menu */}
      <Sheet
        variant="soft"
        sx={{
          py: 2,
          px: 1,
          backgroundColor: (t) => t.palette.primary[50],
        }}
      >
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <IconButton
            color="neutral"
            variant="plain"
            sx={{ "--IconButton-size": "45px" }}
            onClick={() => setToggleDrawer(true)}
          >
            <MenuRounded />
          </IconButton>
          {view === "dayGridMonth" || view === "multiMonthYear" ? (
            <Typography level="h6" textTransform={"capitalize"} ml={2}>
              {title}
            </Typography>
          ) : (
            <Button
              variant="plain"
              endDecorator={
                expandCalendar ? <ArrowDropUp /> : <ArrowDropDown />
              }
              onClick={() => {
                setExpandCalendar(!expandCalendar);
              }}
              sx={{
                "&:active": {
                  backgroundColor: "transparent",
                },
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&:focus": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Typography level="h6" textTransform={"capitalize"}>
                {title}
              </Typography>
            </Button>
          )}
          <Box
            sx={{
              display: "flex",
              columnGap: 2,
              alignItems: "center",
              justifyContent: "flex-end",
              flex: "1 1",
            }}
          >
            <Avatar
              variant="soft"
              color="primary"
              onClick={handleUserMenuOpen}
              sx={{
                cursor: "pointer",
              }}
            >
              {renderAvatarText()}
            </Avatar>
          </Box>
        </Box>
        <Collapse
          in={expandCalendar}
          timeout={450}
          onExited={() => {
            if (calendarRef.current) {
              const calendar = calendarRef.current.getApi();
              calendar.updateSize();
            }
          }}
        >
          <DateCalendar
            defaultValue={initialDate}
            onChange={onDateChange}
            value={currentDay}
            sx={{
              width: "100%",
              margin: 0,
              maxHeight: 220,
              "& .MuiPickersCalendarHeader-root": {
                display: "none",
              },
              "& .MuiDayCalendar-header": {
                justifyContent: "space-between",
              },
              "& .MuiDayCalendar-weekContainer": {
                justifyContent: "space-between",
                margin: 0,
              },
              "& .MuiDayCalendar-weekDayLabel": {
                width: 30,
                height: 34,
              },
            }}
            slotProps={{
              day: {
                sx: {
                  width: 30,
                  height: 30,
                },
              },
            }}
          />
        </Collapse>
      </Sheet>
      {/* Calendar */}
      <CalendarWrapper ref={calendarWrapperRef}>
        <FullCalendar
          ref={(ref) => (calendarRef.current = ref)}
          plugins={[dayGridPlugin, multiMonthPlugin, interactionPlugin]}
          initialView={view}
          headerToolbar={false}
          stickyHeaderDates
          showNonCurrentDates={false}
          fixedWeekCount={false}
          dayHeaderContent={(args) => <CalendarDateHeader {...args} />}
          dayHeaderClassNames={"ep-dayheader"}
          titleFormat={{
            month: "long",
            day: "2-digit",
            year: "numeric",
          }}
          dayMaxEventRows
          multiMonthMaxColumns={1}
          firstDay={1}
          locale={user?.lang === "en_EN" ? "en" : "it"}
          height={"100%"}
          selectable
          selectAllow={selectionAllowed}
          events={calendarEvents}
          dateClick={(arg) => {
            if (isAllowed("events.write") && !isSwipeActive)
              setEventModal({
                ...eventModal,
                open: true,
                eventDate: arg.dateStr,
              });
          }}
          eventClick={onEventClick}
          views={{
            dayGridThreeDays: {
              buttonText: "3 Days",
              type: "dayGrid",
              duration: { days: 3 },
              dayMaxEventRows: 30,
            },
            dayGridMonth: {
              dayMaxEventRows: 5,
            },
          }}
        />
      </CalendarWrapper>
      {isAllowed("events.write") ? (
        <IconButton
          variant="soft"
          title={`${t("Add event")}`}
          color="primary"
          size="lg"
          sx={{
            position: "absolute",
            bottom: "1rem",
            right: "1rem",
            zIndex: 99,
          }}
          onClick={() => {
            setEventModal({
              ...eventModal,
              open: true,
            });
          }}
        >
          <Add />
        </IconButton>
      ) : null}
      <MobileUserMenu
        open={Boolean(userMenuAnchEl)}
        onClose={handleUserMenuClose}
      />
      <MobileEventDetails
        open={eventDetail.open}
        event={eventDetail.event}
        onClose={() => setEventDetail({ open: false, event: undefined })}
        onEditClick={onEditEvent}
        onDeleteClick={onDeleteEventClick}
        forceUpdate={() => {
          setForceEventUpdate(true);
          setEventDetail({
            ...eventDetail,
            event: events.find((e) => e.id === eventDetail.event?.id),
          });
        }}
      />
      <EventModal
        open={eventModal.open}
        event={eventModal.event}
        eventDate={eventModal.eventDate}
        onClose={() =>
          setEventModal({ open: false, event: undefined, eventDate: undefined })
        }
        onConfirm={() => {}}
        layout="fullscreen"
      />
      <Drawer
        title="Events Planner"
        open={toggleDrawer}
        size={300}
        onClose={() => setToggleDrawer(false)}
      >
        <List sx={{ "--List-radius": "16px", "--List-padding": "16px" }}>
          <ListItemButton
            selected={view === "dayGridDay"}
            variant={view === "dayGridDay" ? "soft" : "plain"}
            color={view === "dayGridDay" ? "primary" : "neutral"}
            onClick={() => {
              onViewChange("dayGridDay");
            }}
          >
            <ListItemDecorator>
              <ViewDayOutlined />
            </ListItemDecorator>
            <ListItemContent>{t("Day")}</ListItemContent>
          </ListItemButton>
          <ListItemButton
            selected={view === "dayGridThreeDays"}
            variant={view === "dayGridThreeDays" ? "soft" : "plain"}
            color={view === "dayGridThreeDays" ? "primary" : "neutral"}
            onClick={() => {
              onViewChange("dayGridThreeDays");
            }}
          >
            <ListItemDecorator>
              <ViewWeekOutlined />
            </ListItemDecorator>
            <ListItemContent>{t("3 Days")}</ListItemContent>
          </ListItemButton>
          <ListItemButton
            selected={view === "dayGridWeek"}
            variant={view === "dayGridWeek" ? "soft" : "plain"}
            color={view === "dayGridWeek" ? "primary" : "neutral"}
            onClick={() => {
              onViewChange("dayGridWeek");
            }}
          >
            <ListItemDecorator>
              <CalendarViewWeek />
            </ListItemDecorator>
            <ListItemContent>{t("Week")}</ListItemContent>
          </ListItemButton>
          <ListItemButton
            selected={view === "dayGridMonth"}
            variant={view === "dayGridMonth" ? "soft" : "plain"}
            color={view === "dayGridMonth" ? "primary" : "neutral"}
            onClick={() => {
              onViewChange("dayGridMonth");
            }}
          >
            <ListItemDecorator>
              <CalendarViewMonthOutlined />
            </ListItemDecorator>
            <ListItemContent>{t("Month")}</ListItemContent>
          </ListItemButton>
          <ListItemButton
            selected={view === "multiMonthYear"}
            variant={view === "multiMonthYear" ? "soft" : "plain"}
            color={view === "multiMonthYear" ? "primary" : "neutral"}
            onClick={() => {
              onViewChange("multiMonthYear");
            }}
          >
            <ListItemDecorator>
              <CalendarMonthRounded />
            </ListItemDecorator>
            <ListItemContent>{t("Multi Month")}</ListItemContent>
          </ListItemButton>
        </List>
        <Divider />
        <Box display="flex" flexDirection="column" rowGap={1} px={2} py={2}>
          <Typography level="body1" fontWeight={600}>
            {t("Artists")}
          </Typography>
          {/* List of artists */}
          <List
            size="sm"
            color="primary"
            sx={{
              "--ListItem-minHeight": "2rem",
              "--ListItem-radius": "8px",
            }}
          >
            {artists.map((a) => (
              <ListItemButton
                key={a.id}
                onClick={() => {
                  if (!ignoredArtists.includes(a.id)) {
                    setIgnoredArtists([...ignoredArtists, a.id]);
                  } else {
                    setIgnoredArtists(
                      ignoredArtists.filter((sa) => sa !== a.id)
                    );
                  }
                }}
              >
                <ListItemDecorator>
                  <Checkbox
                    checked={!ignoredArtists.includes(a.id)}
                    sx={{
                      "& .MuiCheckbox-checkbox.Joy-checked": {
                        backgroundColor: a.color,
                      },
                      "&:hover .MuiCheckbox-checkbox.Joy-checked": {
                        backgroundColor: darken(a.color, 0.15),
                      },
                    }}
                  />
                </ListItemDecorator>
                <ListItemContent>{a.name}</ListItemContent>
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default MobileCalendar;
