import React from "react";
import { Box, IconButton, Sheet, Typography } from "@mui/joy";
import PdfIcon from "components/icons/PdfIcon";
import JpgIcon from "components/icons/JpgIcon";
import PngIcon from "components/icons/PngIcon";
import { Close } from "@mui/icons-material";
interface FileElementProps {
  index: number;
  file: File;
  onRemove: (file: File, index: number) => void;
}

const FileElement: React.FC<FileElementProps> = (props) => {
  const RenderFileIcon = () => {
    switch (props.file.type) {
      case "application/pdf":
        return <PdfIcon color="primary" />;
      case "image/png":
        return <PngIcon color="primary" />;
      case "image/jpg":
        return <JpgIcon color="primary" />;
      default:
        return null;
    }
  };

  return (
    <Sheet
      color="primary"
      variant="outlined"
      title={props.file.name}
      sx={{
        display: "flex",
        flexDirection: "row",
        p: 0.7,
        borderRadius: 8,
        position: "relative",
        alignItems: "center",
      }}
    >
      <RenderFileIcon />
      <Box
        display={"flex"}
        flexDirection={"row"}
        flex={"1 1 200px"}
        maxWidth={120}
        overflow={"hidden"}
        alignItems={"center"}
      >
        <Typography
          level="body2"
          color="primary"
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {props.file.name}
        </Typography>

        <IconButton
          variant="plain"
          color="neutral"
          sx={{
            "--IconButton-size": "22px",
          }}
          onClick={() => props.onRemove(props.file, props.index)}
        >
          <Close />
        </IconButton>
      </Box>
    </Sheet>
  );
};

export default FileElement;
