import { useEffect, useRef } from "react";

type SwipeDirections = { left?: (e: TouchEvent) => void; right?: (e: TouchEvent) => void; up?: (e: TouchEvent) => void; down?: (e: TouchEvent) => void; }

export const useSwipe = ({ left, right, up, down }: SwipeDirections, isSwiping?: (swiping: boolean) => void) => {
    const touchCoordsRef = useRef({ touchStart: { x: 0, y: 0, time: Date.now() } });
    const fnsRef = useRef({ up, down, left, right });
    fnsRef.current = {
        up,
        left,
        down,
        right,
    };
    useEffect(() => {
        const handleTouchStart = (e: TouchEvent) => {
            touchCoordsRef.current.touchStart.x = e.targetTouches[0].clientX;
            touchCoordsRef.current.touchStart.y = e.targetTouches[0].clientY;
            touchCoordsRef.current.touchStart.time = Date.now();
        };
        const handleTouchEnd = (e: TouchEvent) => {
            const threshold = 90;
            const swipeSpeed = 0.8; // sec;
            const touchEndX = e.changedTouches[0].clientX;
            const touchEndY = e.changedTouches[0].clientY;
            const touchStartX = touchCoordsRef.current.touchStart.x;
            const touchStartY = touchCoordsRef.current.touchStart.y;
            const elapsedTime = (Date.now() - touchCoordsRef.current.touchStart.time) / 1000;
            if (elapsedTime > swipeSpeed) {
                if (isSwiping) {
                    isSwiping(false)
                }
                return;
            }
            const xDistance = touchStartX - touchEndX;
            const yDistance = touchStartY - touchEndY;

            if (Math.abs(xDistance) < threshold && Math.abs(yDistance) < threshold) {
                if (isSwiping) {
                    isSwiping(false)
                }
                return;
            }

            if (Math.abs(xDistance) >= Math.abs(yDistance)) {
                xDistance > 0 ? fnsRef.current.right?.(e) : fnsRef.current.left?.(e);
                if (isSwiping) {
                    isSwiping(true)
                }
            } else {
                yDistance > 0 ? fnsRef.current.down?.(e) : fnsRef.current.up?.(e);
                if (isSwiping) {
                    isSwiping(true)
                }
            }
        };

        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);

        return () => {

            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);

        };
    });
};