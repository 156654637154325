import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Sheet,
} from "@mui/joy";
import { useAuthentication } from "context/AuthenticationContext";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import MobileUserMenu from "components/mobile/MobileUserMenu";
import { addMonths, formatISO, subMonths } from "date-fns";
import { useArtists } from "context/ArtistsContext";
import { Artist } from "types";
import { Close, FilterAlt, FilterList } from "@mui/icons-material";
import EventsByRegionsChart from "components/charts/EventsByRegionsChart";
import EventsByLocationsChart from "components/charts/EventsByLocationsChart";
import ArtistsDistProfitChart from "components/charts/ArtistsDiffProfitChart";
import ArtistsCachetChart from "components/charts/ArtistsCachetChart";
import PromotersCostChart from "components/charts/PromotersCostChart";
import TotalProfitChart from "components/charts/TotalProfitChart";
import { Collapse } from "@mui/material";

const MobileAnalytics: React.FC = () => {
  const [userMenuAnchEl, setUserMenuAnchEl] = useState<HTMLDivElement | null>(
    null
  );
  const { user } = useAuthentication();
  // const navigate = useNavigate();
  const [t] = useTranslation();

  const { artists } = useArtists();
  const now = new Date();
  const [from, setFrom] = useState(
    formatISO(subMonths(now, 6), { representation: "date" })
  );
  const [to, setTo] = useState(() =>
    formatISO(addMonths(now, 1), { representation: "date" })
  );
  const [selArtists, setSelArtists] = useState<Artist[]>([]);

  //State used to update filter view,
  const [startDate, setStartDate] = useState<string>(
    formatISO(subMonths(now, 6), { representation: "date" })
  );
  const [endDate, setEndDate] = useState(() =>
    formatISO(addMonths(now, 1), { representation: "date" })
  );
  const [filteredArtists, setFilteredArtists] = useState<Artist[]>([]);

  const [toggleFilters, setToggleFilters] = useState(false);

  const renderAvatarText = () => {
    return user?.email[0].toUpperCase();
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchEl(null);
  };

  const handleUserMenuOpen = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setUserMenuAnchEl(e.currentTarget);
  };

  useEffect(() => {
    setFilteredArtists(artists);
    setSelArtists(artists);
  }, [artists]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      boxSizing={"border-box"}
      position={"relative"}
    >
      {/* Menu */}
      <Sheet
        variant="soft"
        sx={{
          py: 2,
          px: 1,
          backgroundColor: (t) => t.palette.primary[50],
        }}
      >
        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <IconButton
              color="neutral"
              variant="plain"
              sx={{ "--IconButton-size": "45px" }}
              onClick={() => setToggleFilters(!toggleFilters)}
            >
              <FilterList />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              columnGap: 2,
              alignItems: "center",
              justifyContent: "flex-end",
              flex: "1 1",
            }}
          >
            <Avatar
              variant="soft"
              color="primary"
              onClick={handleUserMenuOpen}
              sx={{
                cursor: "pointer",
              }}
            >
              {renderAvatarText()}
            </Avatar>
          </Box>
        </Box>
        <Collapse in={toggleFilters}>
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            px={2}
            boxSizing={"border-box"}
            flexWrap={"wrap"}
            position={"static"}
          >
            <FormControl
              sx={{
                flex: "1 1",
              }}
            >
              <FormLabel>{t("Artists")}</FormLabel>
              <Autocomplete
                size="sm"
                options={artists}
                limitTags={2}
                multiple
                name="artists"
                autoComplete={false}
                onInputChange={() => {}}
                getOptionLabel={(a) => a.name}
                value={filteredArtists}
                onChange={(_, v) => setFilteredArtists(v)}
                renderTags={(tags, getTagProps) =>
                  tags.map((item, index) => (
                    <Chip
                      variant="soft"
                      size="sm"
                      endDecorator={<Close fontSize="small" />}
                      {...getTagProps({ index })}
                    >
                      {item.name}
                    </Chip>
                  ))
                }
              ></Autocomplete>
            </FormControl>
            <FormControl
              sx={{
                flex: "1 1 250px",
              }}
            >
              <FormLabel>{t("From")}</FormLabel>
              <Input
                type="date"
                name="from_date"
                size="sm"
                fullWidth
                value={startDate}
                onChange={(e) => setStartDate(e.currentTarget.value)}
              />
            </FormControl>
            <FormControl
              sx={{
                flex: "1 1 250px",
              }}
            >
              <FormLabel>{t("To")}</FormLabel>
              <Input
                type="date"
                name="to_date"
                size="sm"
                fullWidth
                value={endDate}
                onChange={(e) => setEndDate(e.currentTarget.value)}
              />
            </FormControl>
            <Box display={"flex"} alignItems={"center"} flex={"1 1 200px"}>
              <Button
                color="primary"
                size="sm"
                variant="solid"
                fullWidth
                aria-label="filter"
                title={`${t("Filter")}`}
                onClick={() => {
                  setFrom(startDate);
                  setTo(endDate);
                  setSelArtists(filteredArtists);
                }}
              >
                <FilterAlt fontSize="small" />
              </Button>
            </Box>
          </Box>
        </Collapse>
      </Sheet>

      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        p={2}
        flexDirection={"column"}
        boxSizing={"border-box"}
        position={"relative"}
        gap={2}
        overflow={"hidden auto"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          width={"100%"}
          gap={2}
        >
          <Sheet
            sx={{ p: 2, borderRadius: 16, flex: "1 1" }}
            variant="outlined"
          >
            <TotalProfitChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          width={"100%"}
          gap={2}
        >
          <Sheet
            sx={{ p: 2, borderRadius: 16, flex: "1 1 310px" }}
            variant="outlined"
          >
            <PromotersCostChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
          <Sheet
            sx={{ p: 2, borderRadius: 16, flex: "1 1 310px" }}
            variant="outlined"
          >
            <ArtistsCachetChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          width={"100%"}
          gap={2}
        >
          <Sheet
            sx={{ p: 2, borderRadius: 16, flex: "1 1" }}
            variant="outlined"
          >
            <ArtistsDistProfitChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          width={"100%"}
          gap={2}
        >
          <Sheet
            sx={{ p: 2, borderRadius: 16, flex: "1 1 310px" }}
            variant="outlined"
          >
            <EventsByRegionsChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
          <Sheet
            sx={{ p: 2, borderRadius: 16, flex: "1 1 310px" }}
            variant="outlined"
          >
            <EventsByLocationsChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
        </Box>
      </Box>
      <MobileUserMenu
        open={Boolean(userMenuAnchEl)}
        onClose={handleUserMenuClose}
      />
    </Box>
  );
};

export default MobileAnalytics;
