import React from "react";
import { Outlet } from "react-router-dom";

interface LocationsProps {}

const Locations: React.FC<LocationsProps> = () => {
  return <Outlet />;
};

export { default as CreateLocation } from "./Create";
export { default as EditLocation } from "./Edit";
export { default as LocationsList } from "./List";
export default Locations;
