import React from "react";
import { Outlet } from "react-router-dom";

interface UsersProps {}

const Users: React.FC<UsersProps> = () => {
  return <Outlet />;
};

export { default as CreateUser } from "./Create";
export { default as EditUser } from "./Edit";
export { default as UsersList } from "./List";
export default Users;
