import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { ArtistEvent, EventsDateRange, ViewModeTypes } from "types";
import { fetchArtistEvents } from "utils";
import { useAuthorization } from "context/AuthorizationContext";
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Option,
  Select,
  Sheet,
  Typography,
} from "@mui/joy";
import { DateCalendar } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { darken } from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import { CalendarWrapper } from "components/styled_components";
import {
  DateSpanApi,
  EventClickArg,
  EventSourceInput,
} from "@fullcalendar/core";
import { useEventLocations } from "context/LocationsContext";
import { useArtists } from "context/ArtistsContext";
import { PickerSelectionState } from "@mui/x-date-pickers/internals";
import { Add, ChevronLeft, ChevronRight } from "@mui/icons-material";
import EventModal from "components/EventModal";
import EventDetails from "components/EventDetails";
import axios from "axios";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import {
  add,
  endOfMonth,
  endOfYear,
  formatISO,
  isSameMonth,
  isSameYear,
  startOfMonth,
  startOfYear,
} from "date-fns";
import { useAuthentication } from "context/AuthenticationContext";
import { useDialogManger } from "context/DialogManagerContext";

interface CalendarProps {}

type EventModalState = {
  open: boolean;
  eventDate: string | undefined;
  event: ArtistEvent | undefined;
};

type EventDetailModalState = {
  open: boolean;
  event: ArtistEvent | undefined;
  anchorEl: Element | null;
};

const Calendar: React.FC<CalendarProps> = () => {
  const [ignoredArtists, setIgnoredArtists] = useState<string[]>([]);
  const [currentDay, setCurrentDay] = useState(new Date());
  const initialDateRange = useMemo<EventsDateRange>(() => {
    const offset = 6;
    let startDate = startOfMonth(new Date());
    startDate = add(startDate, { days: -offset });
    let endDate = endOfMonth(startDate);
    endDate = add(endDate, { days: offset, months: 1 });
    return {
      start: formatISO(startDate, { representation: "date" }),
      end: formatISO(endDate, { representation: "date" }),
    };
  }, []);
  const [dateRange, setDateRange] = useState<EventsDateRange>(initialDateRange);
  const [events, setEvents] = useState<ArtistEvent[]>([]);
  const [forceEventUpdate, setForceEventUpdate] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState<EventSourceInput>([]);
  const [view, setView] = useState<ViewModeTypes>("dayGridMonth");
  const [title, setTitle] = useState<string>("");
  const [modalEvent, setModalEvent] = useState<EventModalState>({
    open: false,
    eventDate: undefined,
    event: undefined,
  });
  const [modalDetailEvent, setModalDetailEvent] =
    useState<EventDetailModalState>({
      open: false,
      event: undefined,
      anchorEl: null,
    });
  const { isAllowed } = useAuthorization();
  const { locations, getLocations } = useEventLocations();
  const { artists, getArtists } = useArtists();
  const { user } = useAuthentication();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { showDialog } = useDialogManger();
  const initialDate = useMemo(() => new Date(), []);
  const calendarRef = useRef<FullCalendar | null>(null);
  const filteredArtists = useMemo<string[]>(() => {
    return artists
      .filter((a) => !ignoredArtists.includes(a.id))
      .map((fa) => fa.id);
  }, [ignoredArtists, artists]);

  const evalDatesRange = useCallback(
    (value: Date, changedView?: ViewModeTypes) => {
      const offset = 6;

      if (
        changedView
          ? changedView === "multiMonthYear"
          : view === "multiMonthYear"
      ) {
        setDateRange({
          start: formatISO(startOfYear(value), { representation: "date" }),
          end: formatISO(endOfYear(value), { representation: "date" }),
        });
        return;
      }

      let startDate = startOfMonth(value);
      startDate = add(startDate, { days: -offset });
      let endDate = endOfMonth(startDate);
      endDate = add(endDate, { days: offset, months: 1 });
      setDateRange({
        start: formatISO(startDate, { representation: "date" }),
        end: formatISO(endDate, { representation: "date" }),
      });
    },
    [view]
  );

  const getEvents = useCallback(async () => {
    const data = await fetchArtistEvents(
      filteredArtists.length === artists.length ? undefined : filteredArtists,
      dateRange.start,
      dateRange.end
    );
    if (data) {
      setEvents(data.data);
      const mappedEvents: EventSourceInput = data.data.map((e) => {
        const artist = artists.find((a) => a.id === e.artistId);
        const location = locations.find((l) => l.id === e.locationId);
        return {
          id: e.id,
          allDay: true,
          date: e.date,
          backgroundColor: artist?.color,
          borderColor: artist?.color,
          editable: false,
          groupId: e.artistId,
          title: location?.description,
        };
      });
      setCalendarEvents(mappedEvents);
      setForceEventUpdate(false);
      //Update event on details or edit modals
      if (modalDetailEvent.open) {
        setModalDetailEvent({
          ...modalDetailEvent,
          event: data.data.find((e) => e.id === modalDetailEvent.event?.id),
        });
      }
      if (modalEvent.open) {
        setModalEvent({
          ...modalEvent,
          event: data.data.find((e) => e.id === modalEvent.event?.id),
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    forceEventUpdate,
    filteredArtists,
    artists,
    dateRange.start,
    dateRange.end,
    locations,
  ]);

  const deleteEvent = async (e: ArtistEvent) => {
    try {
      const { status } = await axios.delete(`/api/events/${e.id}`, {
        params: { artistId: e.artistId },
      });
      if (status === 200) {
        setModalDetailEvent({ anchorEl: null, open: false, event: undefined });
        getEvents();
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        toast.error(error.response?.data);
      } else {
        toast.error("Unable to delete the selected event");
      }
    }
  };

  const onDeleteClick = (artist: ArtistEvent) => {
    setModalDetailEvent({
      open: false,
      event: undefined,
      anchorEl: null,
    });
    if (isAllowed("events.delete")) {
      showDialog({
        title: "Delete this event?",
        body: "If you proceed with this action the selected event will be deleted.",
        actions: [
          {
            label: "Cancel",
            color: "neutral",
            variant: "plain",
            onClick: (e, closeModal) => {
              closeModal();
            },
          },
          {
            label: "Delete",
            color: "danger",
            variant: "solid",
            onClick: (e, closeModal) => {
              deleteEvent(artist);
              closeModal();
            },
          },
        ],
      });
    }
  };

  const selectionAllowed = (args: DateSpanApi): boolean => {
    let startDate = args.start;
    let endDate = args.end;
    endDate.setSeconds(endDate.getSeconds() - 1); // allow full day selection
    if (
      startDate.getDate() === endDate.getDate() &&
      isAllowed("events.write")
    ) {
      return true;
    }

    return false;
  };

  const onDateChange = (
    value: Date | null,
    selectionState?: PickerSelectionState
  ) => {
    if (calendarRef.current && value) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(value);
      setTitle(calendarApi.view.title);
      setCurrentDay(calendarApi.getDate());
      if (!isSameMonth(currentDay, value)) {
        evalDatesRange(value);
      }
    }
  };

  const onMonthChange = (date: Date) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
      setTitle(calendarApi.view.title);
      setCurrentDay(date);
      if (!isSameMonth(currentDay, date)) {
        evalDatesRange(date);
      }
    }
  };

  const onYearChange = (date: Date) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
      setTitle(calendarApi.view.title);
      setCurrentDay(date);
      if (!isSameYear(currentDay, date)) {
        evalDatesRange(date);
      }
    }
  };

  const onViewChange = (
    e:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | React.FocusEvent<Element, Element>
      | null,
    value: ViewModeTypes | null
  ) => {
    if (value && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(value);
      setView(value);
      setTitle(calendarApi.view.title);
      if (value === "multiMonthYear") {
        evalDatesRange(calendarApi.getDate(), value);
      }
    }
  };

  const onPrevClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.prev();
      setCurrentDay(calendarApi.getDate());
      setTitle(calendarApi.view.title);

      if (!isSameMonth(currentDay, calendarApi.getDate())) {
        evalDatesRange(calendarApi.getDate());
      }
    }
  };

  const onNextClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.next();
      setCurrentDay(calendarApi.getDate());
      setTitle(calendarApi.view.title);
      if (!isSameMonth(currentDay, calendarApi.getDate())) {
        evalDatesRange(calendarApi.getDate());
      }
    }
  };

  const onEventClick = ({ el, event }: EventClickArg) => {
    const selEvent = events.find((e) => e.id === event.id);
    if (selEvent) {
      setModalDetailEvent({
        open: true,
        event: selEvent,
        anchorEl: el,
      });
    }
  };

  const onEditEvent = (e: ArtistEvent) => {
    setModalDetailEvent({
      open: false,
      event: undefined,
      anchorEl: null,
    });
    setModalEvent({
      open: true,
      event: e,
      eventDate: undefined,
    });
  };

  const onDetailsModalClose = () => {
    setModalDetailEvent({
      open: false,
      event: undefined,
      anchorEl: null,
    });
  };

  useEffect(() => {
    getEvents();
  }, [ignoredArtists, getEvents]);

  useEffect(() => {
    if (forceEventUpdate) getEvents();
  }, [forceEventUpdate, getEvents]);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      setTitle(calendarApi.view.title);
    }
    evalDatesRange(currentDay);
    getLocations();
    getArtists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="row" width="100%" height="100%">
      <Box
        display="flex"
        flexDirection="column"
        flex={"1 1 350px"}
        p={"16px 0px 16px 16px"}
        rowGap={2}
        overflow={"hidden auto"}
        boxSizing={"border-box"}
      >
        <Sheet
          color="neutral"
          sx={{
            width: "100%",
            borderRadius: 16,
            boxSizing: "border-box",
            p: 1,
            flex: "0 1 300px",
          }}
        >
          <DateCalendar
            sx={{
              width: "100%",
            }}
            defaultValue={initialDate}
            onChange={onDateChange}
            value={currentDay}
            onMonthChange={onMonthChange}
            onYearChange={onYearChange}
          />
        </Sheet>
        <Sheet
          color="neutral"
          sx={{
            width: "100%",
            borderRadius: 16,
            boxSizing: "border-box",
            p: 2,
            flex: "1 1 200px",
          }}
        >
          <Box display="flex" flexDirection="column" rowGap={1}>
            <Typography level="body1" fontWeight={600}>
              {t("Artists")}
            </Typography>
            {/* List of artists */}
            <Box overflow={"auto"} flex={"1 1 30vh"}>
              <List
                size="sm"
                color="primary"
                sx={{
                  "--ListItem-minHeight": "2rem",
                  "--ListItem-radius": "8px",
                }}
              >
                {artists.map((a) => (
                  <ListItemButton
                    key={a.id}
                    onClick={() => {
                      if (!ignoredArtists.includes(a.id)) {
                        setIgnoredArtists([...ignoredArtists, a.id]);
                      } else {
                        setIgnoredArtists(
                          ignoredArtists.filter((sa) => sa !== a.id)
                        );
                      }
                    }}
                  >
                    <ListItemDecorator>
                      <Checkbox
                        checked={!ignoredArtists.includes(a.id)}
                        sx={{
                          "& .MuiCheckbox-checkbox.Joy-checked": {
                            backgroundColor: a.color,
                          },
                          "&:hover .MuiCheckbox-checkbox.Joy-checked": {
                            backgroundColor: darken(a.color, 0.15),
                          },
                        }}
                      />
                    </ListItemDecorator>
                    <ListItemContent>{a.name}</ListItemContent>
                  </ListItemButton>
                ))}
              </List>
            </Box>
          </Box>
        </Sheet>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex={"1 1 calc( 100% - 350px )"}
      >
        <Box
          display="flex"
          flexDirection="column"
          p={2}
          overflow={"hidden auto"}
          flex={"1 1"}
          rowGap={2}
        >
          {/* View menu selector */}
          {/* Current day + next/prev action button */}
          {/* Today button + new event button*/}
          <Box
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} flexDirection={"row"} columnGap={2}>
              <Typography level="h3">
                {/* {calendarRef.current?.getApi().view.title} */}
                {title}
              </Typography>
              <Box display={"flex"} alignItems={"center"}>
                <Button
                  variant="plain"
                  color="neutral"
                  size="sm"
                  onClick={onPrevClick}
                >
                  <ChevronLeft />
                </Button>
                <Button
                  variant="plain"
                  color="neutral"
                  size="sm"
                  onClick={onNextClick}
                >
                  <ChevronRight />
                </Button>
              </Box>
            </Box>
            <Box display={"flex"} flexDirection={"row"} columnGap={2}>
              <Select variant="plain" value={view} onChange={onViewChange}>
                <Option value={"dayGridDay"}>{t("Day")}</Option>
                <Option value={"dayGridThreeDays"}>{t("3 Days")}</Option>
                <Option value={"dayGridWeek"}>{t("Week")}</Option>
                <Option value={"dayGridMonth"}>{t("Month")}</Option>
                <Option value={"multiMonthYear"}>{t("Multi Month")}</Option>
              </Select>
              {isAllowed("events.write") ? (
                <Button
                  variant="solid"
                  color="primary"
                  startDecorator={<Add />}
                  onClick={() => {
                    setModalEvent({ ...modalEvent, open: true });
                  }}
                >
                  {t("Event")}
                </Button>
              ) : null}
            </Box>
          </Box>
          <Sheet
            color="neutral"
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              borderRadius: 16,
              rowGap: 2,
              boxSizing: "border-box",
              p: 2,
            }}
          >
            <CalendarWrapper>
              <FullCalendar
                ref={(ref) => (calendarRef.current = ref)}
                plugins={[dayGridPlugin, multiMonthPlugin, interactionPlugin]}
                initialView={view}
                headerToolbar={false}
                stickyHeaderDates
                showNonCurrentDates={false}
                fixedWeekCount={false}
                dayHeaderFormat={{
                  day:
                    view !== "dayGridMonth" && view !== "multiMonthYear"
                      ? "2-digit"
                      : undefined,
                  weekday: "long",
                }}
                dayHeaderClassNames={"ep-dayheader"}
                titleFormat={{
                  month: "long",
                  day: "2-digit",
                  year: "numeric",
                }}
                dayMaxEventRows
                multiMonthMaxColumns={1}
                firstDay={1}
                locale={user?.lang === "en_EN" ? "en" : "it"}
                height={"100%"}
                selectable
                selectAllow={selectionAllowed}
                events={calendarEvents}
                dateClick={(arg) => {
                  if (isAllowed("events.write"))
                    setModalEvent({
                      ...modalEvent,
                      open: true,
                      eventDate: arg.dateStr,
                    });
                }}
                eventClick={onEventClick}
                views={{
                  dayGridThreeDays: {
                    buttonText: "3 Days",
                    type: "dayGrid",
                    duration: { days: 3 },
                    dayMaxEventRows: 30,
                  },
                  dayGridMonth: {
                    dayMaxEventRows: 5,
                  },
                }}
              />
            </CalendarWrapper>
          </Sheet>
        </Box>
      </Box>
      <EventDetails
        open={modalDetailEvent.open}
        event={modalDetailEvent.event}
        anchorEl={modalDetailEvent.anchorEl}
        onClose={onDetailsModalClose}
        onEditClick={onEditEvent}
        onDeleteClick={onDeleteClick}
        forceUpdate={() => setForceEventUpdate(true)}
      />
      <EventModal
        open={modalEvent.open}
        event={modalEvent.event}
        eventDate={modalEvent.eventDate}
        onConfirm={() => setForceEventUpdate(true)}
        onClose={() => {
          setModalEvent({
            open: false,
            eventDate: undefined,
            event: undefined,
          });
        }}
      />
    </Box>
  );
};

export default Calendar;
