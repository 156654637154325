import React from "react";
import { ListDivider } from "@mui/joy";
import { Menu, MenuProps, MenuItem, alpha, lighten } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DeleteForever, Edit } from "@mui/icons-material";
import { useAuthorization } from "context/AuthorizationContext";

interface DataGridContextMenuProps extends MenuProps {
  rowId: string;
  resource: "users" | "locations" | "artists" | "documents";
  extraActions?: DataGridContextMenuActions[];
  onDetail: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onEdit: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  onDelete: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

type DataGridContextMenuActions = {
  label: string;
  isAllowed: boolean;
  icon?: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

const DataGridContextMenu: React.FC<DataGridContextMenuProps> = ({
  rowId,
  resource,
  extraActions,
  onDetail,
  onEdit,
  onDelete,
  ...menuProps
}) => {
  const [t] = useTranslation();
  const { isAllowed } = useAuthorization();

  return (
    <Menu
      {...menuProps}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 2,
          marginTop: (theme) => theme.spacing(1),
          minWidth: 180,
          color: (theme) =>
            theme.palette.mode === "light"
              ? "rgb(55, 65, 81)"
              : theme.palette.grey[300],
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          "& .MuiMenu-list": {
            padding: "4px 0",
          },
          "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
              fontSize: 18,
              color: (theme) => theme.palette.text.secondary,
              marginRight: (theme) => theme.spacing(1.5),
            },
            "&:active": {
              backgroundColor: (theme) =>
                alpha(
                  theme.palette.primary.main,
                  theme.palette.action.selectedOpacity
                ),
            },
          },
        },
      }}
    >
      {isAllowed(`${resource}.read`) ? (
        <MenuItem onClick={onDetail}>{t("Details")}</MenuItem>
      ) : null}
      {isAllowed(`${resource}.write`) ? (
        <MenuItem onClick={onEdit}>
          <Edit /> {t("Edit")}
        </MenuItem>
      ) : null}

      {extraActions && extraActions.some((a) => a.isAllowed) ? (
        <ListDivider />
      ) : null}
      {extraActions
        ? extraActions.map((action, i) =>
            action.isAllowed ? (
              <MenuItem key={i} onClick={action.onClick}>
                {action.icon} {t(action.label)}
              </MenuItem>
            ) : null
          )
        : null}
      {isAllowed(`${resource}.delete`) ? <ListDivider /> : null}
      {isAllowed(`${resource}.delete`) ? (
        <MenuItem
          onClick={onDelete}
          sx={{
            color: (theme) => theme.palette.error.main,
            "&:hover": {
              backgroundColor: (theme) =>
                lighten(theme.palette.error.light, 0.9),
            },
          }}
        >
          <DeleteForever
            sx={{
              color: (theme) => `${theme.palette.error.main} !important`,
            }}
          />{" "}
          {t("Delete")}
        </MenuItem>
      ) : null}
    </Menu>
  );
};

export default DataGridContextMenu;
