import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Sheet,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { Close, FilterAlt } from "@mui/icons-material";
import { addMonths, formatISO, subMonths } from "date-fns";
import { useArtists } from "context/ArtistsContext";
import { Artist } from "types";
import PromotersCostChart from "components/charts/PromotersCostChart";
import ArtistsCachetChart from "components/charts/ArtistsCachetChart";
import ArtistsDistProfitChart from "components/charts/ArtistsDiffProfitChart";
import TotalProfitChart from "components/charts/TotalProfitChart";
import EventsByRegionsChart from "components/charts/EventsByRegionsChart";
import EventsByLocationsChart from "components/charts/EventsByLocationsChart";

interface AnalyticsProps {}

const Analytics: React.FC<AnalyticsProps> = () => {
  const { artists } = useArtists();
  const now = new Date();
  const [from, setFrom] = useState(
    formatISO(subMonths(now, 6), { representation: "date" })
  );
  const [to, setTo] = useState(() =>
    formatISO(addMonths(now, 1), { representation: "date" })
  );
  const [selArtists, setSelArtists] = useState<Artist[]>([]);

  //State used to update filter view,
  const [startDate, setStartDate] = useState<string>(
    formatISO(subMonths(now, 6), { representation: "date" })
  );
  const [endDate, setEndDate] = useState(() =>
    formatISO(addMonths(now, 1), { representation: "date" })
  );
  const [filteredArtists, setFilteredArtists] = useState<Artist[]>([]);

  const [isSelOpen, setIsSelOpen] = useState(false);

  const [t] = useTranslation();

  useEffect(() => {
    setFilteredArtists(artists);
    setSelArtists(artists);
  }, [artists]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      overflow={"hidden"}
    >
      <Box
        width={"100%"}
        display={"flex"}
        flexDirection={"row"}
        gap={1}
        p={2}
        position={"static"}
        flexWrap={"wrap"}
        alignItems={"flex-end"}
        boxSizing={"border-box"}
      >
        <FormControl
          sx={{
            flex: "1 1 350px",
          }}
        >
          <FormLabel>{t("Artists")}</FormLabel>
          <Autocomplete
            size="sm"
            options={artists}
            limitTags={2}
            multiple
            name="artists"
            autoComplete={false}
            onInputChange={() => {}}
            getOptionLabel={(a) => a.name}
            value={filteredArtists}
            onChange={(_, v) => setFilteredArtists(v)}
            onOpen={(e) => setIsSelOpen(true)}
            onClose={(e) => setIsSelOpen(false)}
            renderTags={(tags, getTagProps) =>
              tags.map((item, index) => (
                <Chip
                  variant="soft"
                  size="sm"
                  endDecorator={<Close fontSize="small" />}
                  {...getTagProps({ index })}
                >
                  {item.name}
                </Chip>
              ))
            }
          ></Autocomplete>
        </FormControl>
        <FormControl
          sx={{
            flex: "1 1 250px",
          }}
        >
          <FormLabel>{t("From")}</FormLabel>
          <Input
            type="date"
            name="from_date"
            size="sm"
            value={startDate}
            onChange={(e) => setStartDate(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl
          sx={{
            flex: "1 1 250px",
          }}
        >
          <FormLabel>{t("To")}</FormLabel>
          <Input
            type="date"
            name="to_date"
            size="sm"
            value={endDate}
            onChange={(e) => setEndDate(e.currentTarget.value)}
          />
        </FormControl>
        <Box
          display={"flex"}
          alignItems={isSelOpen ? "center" : "end"}
          flex={"1 1 50px"}
        >
          <IconButton
            color="primary"
            size="sm"
            variant="solid"
            aria-label="filter"
            title={`${t("Filter")}`}
            onClick={() => {
              setFrom(startDate);
              setTo(endDate);
              setSelArtists(filteredArtists);
            }}
          >
            <FilterAlt fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      <Box
        width={"100%"}
        height={"100%"}
        display={"flex"}
        p={2}
        flexDirection={"column"}
        boxSizing={"border-box"}
        position={"relative"}
        gap={2}
        overflow={"hidden auto"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          width={"100%"}
          gap={2}
        >
          <Sheet sx={{ p: 2, borderRadius: 16, flex: "0.2 1" }}>
            <TotalProfitChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          width={"100%"}
          gap={2}
        >
          <Sheet sx={{ p: 2, borderRadius: 16, flex: "0.5 1 310px" }}>
            <PromotersCostChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
          <Sheet sx={{ p: 2, borderRadius: 16, flex: "0.5 1 310px" }}>
            <ArtistsCachetChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          width={"100%"}
          gap={2}
        >
          <Sheet sx={{ p: 2, borderRadius: 16, flex: "1 1" }}>
            <ArtistsDistProfitChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          width={"100%"}
          gap={2}
        >
          <Sheet sx={{ p: 2, borderRadius: 16, flex: "0.5 1 310px" }}>
            <EventsByRegionsChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
          <Sheet sx={{ p: 2, borderRadius: 16, flex: "0.5 1 310px" }}>
            <EventsByLocationsChart
              startDate={from}
              endDate={to}
              selectedArtists={
                selArtists.length === artists.length ? undefined : selArtists
              }
            />
          </Sheet>
        </Box>
      </Box>
    </Box>
  );
};

export default Analytics;
