import {
  AttachFile,
  Close,
  Delete,
  Edit,
  EuroRounded,
  EventNoteOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Modal, ModalDialog, Typography } from "@mui/joy";
import axios from "axios";
import DocumentElement from "components/styled_components/DocumentElement";
import { useArtists } from "context/ArtistsContext";
import { useAuthorization } from "context/AuthorizationContext";
import { useEventLocations } from "context/LocationsContext";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Artist, ArtistEvent, EventLocation } from "types";

interface MobileEventDetailsProps {
  open: boolean;
  event: ArtistEvent | undefined;
  onClose: (
    event: {},
    reason: "backdropClick" | "escapeKeyDown" | "closeClick"
  ) => void;
  onEditClick: (event: ArtistEvent) => void;
  onDeleteClick: (event: ArtistEvent) => void;
  forceUpdate?: () => void;
}

const MobileEventDetails: React.FC<MobileEventDetailsProps> = ({
  open,
  event,
  onClose,
  onEditClick,
  onDeleteClick,
  forceUpdate,
}) => {
  const { artists } = useArtists();
  const { locations } = useEventLocations();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { isAllowed } = useAuthorization();

  const artistEvent = useMemo<Artist | undefined>(
    () => artists.find((a) => a.id === event?.artistId),
    [artists, event]
  );

  const locationEvent = useMemo<EventLocation | undefined>(
    () => locations.find((l) => l.id === event?.locationId),
    [locations, event]
  );

  const downloadDocument = async (docId: string) => {
    try {
      const { data, headers } = await axios.get(
        `/api/events/${event?.id}/documents/${docId}`,
        {
          responseType: "blob",
        }
      );
      const fileUrl = URL.createObjectURL(new window.Blob([data]));
      let linkBtn = document.createElement("a");
      linkBtn.href = fileUrl;
      document.body.appendChild(linkBtn);
      linkBtn.setAttribute(
        "download",
        headers["content-disposition"].split("filename=")[1].replaceAll('"', "")
      );
      //Force download
      linkBtn.click();
      //Clean up and remove the link
      linkBtn.parentNode?.removeChild(linkBtn);
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(
          t("Something went wrong! Error while trying to download document")
        );
      }
    }
  };

  const onDeleteDocument = async (eventId: string, docId: string) => {
    try {
      const res = await axios.delete(
        `/api/events/${eventId}/documents/${docId}`
      );
      if (res.status) {
        toast.success(t("Document deleted with success"));
        if (forceUpdate) forceUpdate();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(
          t("Something went wrong! Cannot delete the selected document")
        );
      }
    }
  };

  return event ? (
    <Modal open={open} onClose={onClose}>
      <ModalDialog layout="fullscreen" sx={{ p: 0 }}>
        <Box
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          position={"relative"}
        >
          <Box
            width={"calc(100% - 32px)"}
            display={"flex"}
            px={2}
            pt={1}
            justifyContent={"space-between"}
          >
            <IconButton
              variant="plain"
              color="neutral"
              onClick={() => onClose({}, "closeClick")}
            >
              <Close />
            </IconButton>
            <Box display={"flex"} alignItems={"center"} columnGap={1}>
              {isAllowed("events.write") ? (
                <IconButton
                  variant="plain"
                  color="neutral"
                  onClick={() => onEditClick(event)}
                >
                  <Edit />
                </IconButton>
              ) : null}
              {isAllowed("events.delete") ? (
                <IconButton
                  variant="plain"
                  color="danger"
                  onClick={() => onDeleteClick(event)}
                >
                  <Delete />
                </IconButton>
              ) : null}
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            height={"calc(100% - 100px)"}
            gap={1}
            p={2}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              columnGap={1}
            >
              <Box
                width={20}
                height={20}
                bgcolor={artistEvent?.color}
                borderRadius={50}
              ></Box>
              <Typography level="h6" fontWeight={600}>
                {artistEvent?.name}
              </Typography>
            </Box>

            <Typography level="body1" ml={"28px"}>
              {format(new Date(event?.date), "dd/MM/yyyy")}
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"row"}
              columnGap={1}
              alignItems={"center"}
              mt={2}
              mb={1}
            >
              <LocationOnOutlined />
              <Box>
                <Typography level="body1">
                  {locationEvent?.description}
                </Typography>
                <Typography level="body2">{`${
                  locationEvent?.address !== ""
                    ? locationEvent?.address + ","
                    : ""
                } ${locationEvent?.zip} ${locationEvent?.city} ${
                  locationEvent?.province
                }`}</Typography>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              columnGap={1}
              alignItems={"center"}
              my={1}
            >
              <EuroRounded />
              <Typography level="body1">{event?.price}</Typography>
            </Box>
            {event.note ? (
              <Box
                display={"flex"}
                flexDirection={"row"}
                columnGap={1}
                alignItems={"center"}
                my={1}
              >
                <EventNoteOutlined />
                <Typography level="body1">{event?.note}</Typography>
              </Box>
            ) : null}

            {event.documents.length > 0 ? (
              <Box
                display={"flex"}
                flexDirection={"row"}
                columnGap={1}
                alignItems={"flex-start"}
                my={1}
              >
                <AttachFile />
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={1}
                  flexWrap={"wrap"}
                  flex={"1 1 auto"}
                  alignItems={"center"}
                >
                  {event.documents.map((doc) => (
                    <DocumentElement
                      key={doc.id}
                      file={doc}
                      dense
                      onRemove={(d) => {
                        onDeleteDocument(event.id, d);
                      }}
                      onClick={downloadDocument}
                    />
                  ))}
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  ) : null;
};

export default MobileEventDetails;
