import React from "react";
import DeletedDocsList from "./List";

interface TrashProps {}

const Trash: React.FC<TrashProps> = () => {
  return <DeletedDocsList />;
};

export default Trash;
