import {
  Avatar,
  Box,
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { lighten } from "@mui/material";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Artist } from "types";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { useAuthorization } from "context/AuthorizationContext";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { formatISO } from "date-fns";
import { TheaterComedy } from "@mui/icons-material";

interface DetailsModalProps {
  open: boolean;
  artistId: string;
  layout?: "center" | "fullscreen";
  onClose: () => void;
}

const ArtistDetailsModal: React.FC<DetailsModalProps> = ({
  open,
  artistId,
  layout,
  onClose,
}) => {
  const [artist, setArtist] = useState<Artist>({
    id: "",
    name: "",
    address: "",
    color: "#ffffff",
    vat: "",
    unavailableDates: [],
    createdAt: "",
    whoCreate: "",
    updatedAt: null,
    whoUpdate: null,
    deletedAt: null,
  });
  const [unavailableDates, setUnavailableDates] = useState<string[]>([]);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { isAllowed } = useAuthorization();

  const fetchArtist = useCallback(async () => {
    try {
      const { data } = await axios.get<{ data: Artist }>(
        `/api/artists/${artistId}`
      );
      setArtist(data.data);
      if (data.data.unavailableDates)
        setUnavailableDates(data.data.unavailableDates.map((d) => d.date));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/logout", { replace: true });
          return;
        }
        console.log(error.response?.data);
      } else {
        console.log(error);
      }
    }
  }, [artistId, navigate]);

  const UnavailableDay = (props: PickersDayProps<Date>) => {
    const { day, outsideCurrentMonth, ...otherProps } = props;

    const isUnavailable = unavailableDates.includes(
      `${formatISO(day, { representation: "date" })}T00:00:00Z`
    );

    return (
      <PickersDay
        {...otherProps}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        aria-readonly={isUnavailable}
        disableRipple
        disableTouchRipple
        focusRipple
        sx={{
          backgroundColor: isUnavailable
            ? (theme) => lighten(theme.palette.error.light, 0.5)
            : undefined,
          "&:hover": {
            backgroundColor: isUnavailable
              ? (theme) => lighten(theme.palette.error.dark, 0.5)
              : undefined,
          },
          "&.Mui-selected": {
            backgroundColor: isUnavailable
              ? (theme) => lighten(theme.palette.error.light, 0.5)
              : "transparent",
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            color: (theme) => theme.palette.common.black,
          },
          "&:hover.Mui-selected": {
            backgroundColor: isUnavailable
              ? (theme) => lighten(theme.palette.error.light, 0.5)
              : "transparent",
          },
          "&:focus.Mui-selected": {
            backgroundColor: isUnavailable
              ? (theme) => lighten(theme.palette.error.light, 0.5)
              : "transparent",
          },
        }}
      />
    );
  };

  useEffect(() => {
    fetchArtist();
  }, [artistId, fetchArtist]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog
        sx={{
          p: 0,
          minWidth: layout && layout === "fullscreen" ? undefined : 400,
          bgcolor: artist.color,
        }}
        layout={layout ? layout : "center"}
        size="lg"
        aria-labelledby="artist-detail-modal-title"
        aria-describedby="artist-detail-modal-description"
      >
        <Box
          display="flex"
          flexDirection="column"
          width={"100%"}
          height={"100%"}
        >
          <ModalClose color="neutral" variant="plain" />
          {/* Modal body */}
          <Box flex={"1 1"} width={"100%"} height={"100%"}>
            {/* Artist main info container */}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={2}
              p={2}
              height={100}
            >
              <Avatar
                color="neutral"
                size="lg"
                sx={{ width: "5rem", height: "5rem" }}
              >
                <TheaterComedy fontSize="large" sx={{ color: artist.color }} />
              </Avatar>

              <Box display="flex" flexDirection="column">
                <Typography level="h6">{artist.name}</Typography>
                <Typography level="body2">{artist.address}</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flex={"1 1"}
              height={"calc(100% - 100px)"}
              rowGap={1}
              bgcolor={(theme) => theme.palette.background.body}
              p={2}
              boxSizing={"border-box"}
            >
              <Box
                display="flex"
                flexDirection="row"
                columnGap={2}
                justifyContent={"space-between"}
                width={"100%"}
              >
                {/* Role */}
                <Box display="flex" flexDirection="column">
                  <Typography level="body2" fontWeight={600}>
                    {t("Unavailable Dates")}
                  </Typography>

                  <DateCalendar
                    sx={{ margin: "-12px 0 0 0" }}
                    slots={{
                      day: UnavailableDay,
                    }}
                    disableHighlightToday
                  />
                </Box>
              </Box>
            </Box>
          </Box>{" "}
          {/* Modal footer */}
          <Box
            display="flex"
            flexDirection="row"
            bgcolor={(theme) => theme.palette.background.body}
            borderRadius={
              layout && layout === "fullscreen" ? 0 : "0px 0px 10px 10px"
            }
            p={2}
            justifyContent="flex-end"
          >
            {isAllowed("artists.write") ? (
              <Button
                variant="plain"
                color="neutral"
                startDecorator={<EditIcon />}
                size="sm"
                onClick={() => {
                  navigate(artistId);
                }}
              >
                {t("Edit")}
              </Button>
            ) : null}
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default ArtistDetailsModal;
