import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Add, Delete, Search } from "@mui/icons-material";
import { Box, Button, Input, Typography } from "@mui/joy";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { EventLocation, ListResponse } from "types";
import axios from "axios";
import { LinearProgress } from "@mui/material";
import { parseListDate } from "utils/dateParser";
import DGContextMenu from "components/DGContextMenu";
import { StyledCheckbox } from "components/styled_components";
import { useNavigate } from "react-router-dom";
import { useDialogManger } from "context/DialogManagerContext";
import { usePrevious } from "hooks";
import { toast } from "sonner";
import { CustomNoRowsOverlay } from "components/styled_components/DataGridOverlays";
import LocationDetailsModal from "./DetailsModal";
import { useAuthorization } from "context/AuthorizationContext";

interface LocationsListProps {}

const LocationsList: React.FC<LocationsListProps> = () => {
  const [loading, setLoading] = useState(true);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [locations, setLocations] = useState<EventLocation[]>([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [totalItems, setTotalItems] = useState(0);
  const [sort, setSort] = useState<GridSortModel>([
    { field: "created_at", sort: "asc" },
  ]);
  const [filter, setFilter] = useState("");
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [selectedRow, setSelectedRow] = useState("");
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { showDialog } = useDialogManger();
  const { isAllowed } = useAuthorization();
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  let filterTimeout = useRef<NodeJS.Timeout>(null!);
  const prevFilter = usePrevious(filter);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setSelectedRow(event.currentTarget.getAttribute("data-id")!);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const columns = useMemo<GridColDef<EventLocation>[]>(
    () => [
      {
        field: "description",
        headerName: `${t("Name")}`,
        sortable: true,
        filterable: false,
        flex: 1,
        hideable: false,
      },
      {
        field: "address",
        headerName: `${t("Address")}`,
        sortable: true,
        filterable: false,
        flex: 1,
        hideable: false,
      },
      {
        field: "city",
        headerName: `${t("City")}`,
        sortable: true,
        filterable: false,
        flex: 1,
        hideable: false,
      },
      {
        field: "province",
        headerName: `${t("Province")}`,
        sortable: true,
        filterable: false,
        hideable: false,
      },
      {
        field: "region",
        headerName: `${t("Region")}`,
        sortable: true,
        filterable: false,
        hideable: false,
      },
      {
        field: "created_at",
        headerName: `${t("Created at")}`,
        sortable: true,
        filterable: false,
        hideable: false,
        flex: 1,
        renderCell: ({ row }) => <>{parseListDate(row.createdAt)}</>,
      },
      {
        field: "updated_at",
        headerName: `${t("Updated at")}`,
        sortable: true,
        filterable: false,
        hideable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <>{row.updatedAt ? parseListDate(row.updatedAt) : ""}</>
        ),
      },
    ],
    [t]
  );

  const fetchLocations = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get<ListResponse<EventLocation>>(
        "/api/locations",
        {
          params: {
            page: page + 1,
            limit,
            sort: `${sort[0].field}:${sort[0].sort}`,
            search: filter,
          },
        }
      );
      setLocations(data.data);
      setTotalItems(data.total);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot fetch locations"));
      }
    }
    setLoading(false);
  }, [page, sort, limit, filter, navigate, t]);

  const onDeleteLocations = async () => {
    try {
      const { status } = await axios.post(`/api/locations/delete`, {
        locationIds: selectedRows,
      });
      if (status === 200) {
        setSelectedRows([]);
        fetchLocations();
        toast.success(t("Locations successfully deleted"));
        //Close the context menu when item is deleted
        handleClose();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(
          t("Something went wrong! Cannot delete selected locations")
        );
      }
    }
    setLoading(false);
  };

  const onDeleteLocationsClick = () => {
    showDialog({
      title: "Delete the selected locations?",
      body: "If you proceed with this action, the selected locations will be deleted.",
      actions: [
        {
          label: "Cancel",
          color: "neutral",
          variant: "plain",
          onClick: (e, closeModal) => {
            closeModal();
          },
        },
        {
          label: "Delete",
          color: "danger",
          variant: "solid",
          onClick: (e, closeModal) => {
            onDeleteLocations();
            closeModal();
          },
        },
      ],
    });
  };

  const onDeleteLocation = async () => {
    try {
      const { status } = await axios.delete(`/api/locations/${selectedRow}`);
      if (status === 200) {
        setSelectedRow("");
        fetchLocations();
        toast.success(t("Location successfully deleted"));
        //Close the context menu when item is deleted
        handleClose();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.log(error.response?.data);
        toast.error(error.response?.data.error);
      } else {
        console.log(error);
        toast.error(t("Something went wrong! Cannot delete location"));
      }
    }
    setLoading(false);
  };

  const onDeleteLocationClick = () => {
    showDialog({
      title: "Delete the selected location?",
      body: "If you proceed with this action, the selected location will be deleted.",
      actions: [
        {
          label: "Cancel",
          color: "neutral",
          variant: "plain",
          onClick: (e, closeModal) => {
            closeModal();
          },
        },
        {
          label: "Delete",
          color: "danger",
          variant: "solid",
          onClick: (e, closeModal) => {
            onDeleteLocation();
            closeModal();
          },
        },
      ],
    });
  };

  const onSortChange = (
    model: GridSortModel,
    details: GridCallbackDetails<any>
  ) => {
    if (model.length === 0) {
      setSort([{ field: "created_at", sort: "asc" }]);
    } else {
      setSort(model);
    }
  };

  useEffect(() => {
    if (prevFilter !== filter && prevFilter !== undefined) {
      if (filterTimeout.current) {
        clearTimeout(filterTimeout.current);
      }
      filterTimeout.current = setTimeout(() => {
        fetchLocations();
      }, 500);

      return () => {
        clearTimeout(filterTimeout.current);
      };
    } else {
      fetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, limit, filter, fetchLocations]);

  return (
    <Box
      width={"100%"}
      height={"100%"}
      display="flex"
      flexDirection="column"
      rowGap={2}
    >
      <Typography level="h4" fontWeight={600}>
        {t("Locations")}
      </Typography>
      <Box
        display="flex"
        alignSelf="flex-end"
        flexDirection="row"
        width={"100%"}
      >
        <Box
          display="flex"
          alignSelf="flex-end"
          flexDirection="row"
          justifyContent="flex-start"
          flex={1}
          columnGap={2}
        >
          {isAllowed("locations.write") ? (
            <Button
              variant="solid"
              startDecorator={<Add />}
              color="primary"
              size="sm"
              onClick={() => navigate("new")}
            >
              {t("New")}
            </Button>
          ) : null}
          {isAllowed("locations.delete") ? (
            <Button
              variant="soft"
              startDecorator={<Delete />}
              color="danger"
              size="sm"
              disabled={selectedRows.length === 0}
              onClick={onDeleteLocationsClick}
            >
              {t("Delete")}
            </Button>
          ) : null}
        </Box>
        <Box
          display="flex"
          alignSelf="flex-end"
          flexDirection="row"
          justifyContent="flex-end"
          flex={1}
        >
          <Input
            size="sm"
            placeholder={`${t("Search")}...`}
            startDecorator={<Search />}
            fullWidth
            value={filter}
            onChange={(e) => setFilter(e.currentTarget.value)}
            sx={{ flex: "0.60 1" }}
          ></Input>
        </Box>
      </Box>
      <Box
        alignSelf="stretch"
        flex={"1 1"}
        maxHeight={"calc( 100% - 100px )"}
        boxSizing={"border-box"}
      >
        <DataGrid
          columns={columns}
          rows={locations}
          pagination
          checkboxSelection
          paginationModel={{ page: page, pageSize: limit }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setLimit(model.pageSize);
          }}
          rowCount={totalItems}
          disableColumnMenu
          density="compact"
          sortingMode="server"
          paginationMode="server"
          sortModel={sort}
          onSortModelChange={onSortChange}
          onRowSelectionModelChange={(selRows) => {
            setSelectedRows(selRows);
          }}
          localeText={{
            columnHeaderSortIconLabel: `${t("Sort")}`,
            noRowsLabel: `${t("No locations found")}`,
            MuiTablePagination: {
              labelDisplayedRows: ({ from, to, count }) =>
                t("{{from}}-{{to}} of {{count}}", {
                  from,
                  to,
                  count,
                }),
              labelRowsPerPage: `${t("Rows per page")}:`,
            },
          }}
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
          slots={{
            loadingOverlay: LinearProgress,
            baseCheckbox: StyledCheckbox,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            loadingOverlay: {
              color: "primary",
            },
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" },
            },
          }}
          loading={loading}
        />
        <DGContextMenu
          resource="locations"
          rowId={selectedRow}
          open={contextMenu !== null}
          onClose={handleClose}
          onDetail={() => {
            setDetailsOpen(true);
            handleClose();
          }}
          onEdit={() => navigate(selectedRow)}
          onDelete={onDeleteLocationClick}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          slotProps={{
            root: {
              onContextMenu: (e) => {
                e.preventDefault();
                handleClose();
              },
            },
          }}
        />
        <LocationDetailsModal
          open={detailsOpen}
          locationId={selectedRow}
          onClose={() => setDetailsOpen(false)}
        />
      </Box>
    </Box>
  );
};

export default LocationsList;
