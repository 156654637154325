import { DayHeaderContentArg } from "@fullcalendar/core";
import { Box, Typography } from "@mui/joy";
import { useAuthentication } from "context/AuthenticationContext";
import { format } from "date-fns";
import { enUS, it } from "date-fns/locale";
import React from "react";

interface CalendarDateHeaderProps extends DayHeaderContentArg {}

const CalendarDateHeader: React.FC<CalendarDateHeaderProps> = ({
  date,
  view,
}) => {
  const { user } = useAuthentication();
  const day = format(date, "d", {
    locale: user?.lang === "en_EN" ? enUS : it,
  });
  const dateString = format(date, "E", {
    locale: user?.lang === "en_EN" ? enUS : it,
  });

  switch (view.type) {
    case "dayGridDay":
      return (
        <Box
          display={"flex"}
          flexDirection={"row"}
          columnGap={1}
          flexWrap={"wrap"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography level="h6" fontWeight={600} textTransform={"capitalize"}>
            {format(date, "eeee", {
              locale: user?.lang === "en_EN" ? enUS : it,
            })}
          </Typography>
          <Typography level="h6" fontWeight={600}>
            {day}
          </Typography>
        </Box>
      );
    case "dayGridThreeDays":
    case "dayGridWeek":
      return (
        <Box
          display={"flex"}
          flexDirection={"column"}
          flexWrap={"wrap"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography level="h6" fontWeight={600} textTransform={"capitalize"}>
            {dateString}
          </Typography>
          <Typography level="body1">{day}</Typography>
        </Box>
      );
    case "dayGridMonth":
    case "multiMonthYear":
      return (
        <Box
          display={"flex"}
          flexDirection={"column"}
          flexWrap={"wrap"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography level="h6" fontWeight={600} textTransform={"capitalize"}>
            {dateString}
          </Typography>
        </Box>
      );
    default:
      return (
        <Box
          display={"flex"}
          flexDirection={"column"}
          flexWrap={"wrap"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography level="h6" fontWeight={600}>
            {dateString}
          </Typography>
          <Typography level="body1">{day}</Typography>
        </Box>
      );
  }
};

export default CalendarDateHeader;
