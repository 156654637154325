import React, { useCallback, useEffect, useState } from "react";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import { useTranslation } from "react-i18next";
import { Artist } from "types";
import axios from "axios";
import { toast } from "sonner";

interface PromotersCostChartProps {
  startDate: string;
  endDate: string;
  selectedArtists: Artist[] | undefined;
}

type PromotersCostData = {
  name: string;
  value: number;
};

const PromotersCostChart: React.FC<PromotersCostChartProps> = ({
  startDate,
  endDate,
  selectedArtists,
}) => {
  const [promotersCost, setPromotersCost] = useState<PromotersCostData[]>([]);
  const [t] = useTranslation();

  const getPromotersCost = useCallback(async () => {
    try {
      const { data } = await axios.get<{ data: PromotersCostData[] }>(
        "/api/analytics/promoters-cost",
        {
          params: {
            start: startDate,
            end: endDate,
            artistIds: selectedArtists?.map((a) => a.id).join(","),
          },
        }
      );
      setPromotersCost(data.data);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        //Fore the user logout if the request is unauthorized
        if (error.response?.status === 401) {
          window.location.href = "/login";
        }
        toast.error(error.response?.data);
      } else {
        toast.error("Something went wrong! Cannot fetch locations");
      }
    }
  }, [startDate, endDate, selectedArtists]);

  useEffect(() => {
    getPromotersCost();
  }, [startDate, endDate, selectedArtists, getPromotersCost]);

  const options: EChartsOption = {
    title: {
      text: t("Promoters Cost"),
      left: "start",
      textStyle: {
        fontFamily: "Public Sans",
        fontSize: "1rem",
        fontWeight: 600,
        color: "#25252D",
      },
    },
    tooltip: {
      trigger: "item",
    },
    legend: {
      orient: "horizontal",
      left: "left",
      bottom: "bottom",
    },
    series: [
      {
        type: "pie",
        radius: "50%",
        data: promotersCost,
        label: {
          formatter: "{c}",
        },
      },
    ],
  };
  return <ReactECharts option={options} />;
};

export default PromotersCostChart;
