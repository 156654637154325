import React from "react";
import { Popover, PopoverProps } from "@mui/material";
import { Avatar, Box, Button, Divider, Typography } from "@mui/joy";
import { useAuthentication } from "context/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "context/AuthorizationContext";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useNavigate } from "react-router-dom";

interface UserMenuProps extends PopoverProps {}

const UserMenu: React.FC<UserMenuProps> = (props) => {
  const { user, onLogout } = useAuthentication();
  const { isSomeAllowed } = useAuthorization();
  const [t] = useTranslation();
  const navigate = useNavigate();

  const renderAvatarText = () => {
    return user?.email[0].toUpperCase();
  };

  return (
    <Popover
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        elevation: 0,
        variant: "outlined",
        sx: {
          minWidth: 250,
          borderRadius: "10px",
          boxShadow:
            "var(--joy-shadowRing, 0 0 #000),0.3px 0.8px 1.1px rgba(var(--joy-shadowChannel, 187 187 187) / 0.11),0.5px 1.3px 1.8px -0.6px rgba(var(--joy-shadowChannel, 187 187 187) / 0.18),1.1px 2.7px 3.8px -1.2px rgba(var(--joy-shadowChannel, 187 187 187) / 0.26)",
        },
      }}
      {...props}
    >
      <Box
        p={2}
        width={"100%"}
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
        boxSizing={"border-box"}
        rowGap={1}
      >
        <Box
          display="flex"
          width="100%"
          alignItems="center"
          flexDirection="column"
        >
          <Avatar
            sx={{ "--Avatar-size": "55px", mb: 1, fontSize: "1.6rem" }}
            color="primary"
          >
            {renderAvatarText()}
          </Avatar>
          <Typography level="body1">
            {user!.firstName ? user!.firstName : ""}
            {user!.lastName ? user!.lastName : ""}
          </Typography>
          <Typography level="body3">{user!.email}</Typography>
        </Box>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("account-settings");
            if (props.onClose) props.onClose({}, "escapeKeyDown");
          }}
        >
          {t("Manage your account")}
        </Button>
        {isSomeAllowed(["artists.write", "users.read", "locations.write"]) ? (
          <Button
            variant="plain"
            color="neutral"
            startDecorator={<AdminPanelSettingsIcon />}
            onClick={() => {
              navigate("admin/users");
              if (props.onClose) props.onClose({}, "escapeKeyDown");
            }}
          >
            {t("Admin Panel")}
          </Button>
        ) : null}
        <Divider inset="context" />
        <Box textAlign={"center"}>
          <Button
            variant="plain"
            color="neutral"
            size="sm"
            startDecorator={<ExitToAppIcon />}
            onClick={onLogout}
          >
            {t("Logout")}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default UserMenu;
