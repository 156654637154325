import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { UserIdentity } from "types";
import { useAuthorization } from "./AuthorizationContext";

interface AuthenticationContexProps {
  isAuthenticated: boolean;
  user: UserIdentity | null;
  isLoading: boolean;
  onLogin: (
    email: string,
    password: string,
    erroCallback?: (error: string) => void
  ) => void;
  onLogout: () => void;
  getIdentity: () => void;
}

const AuthenticationContex = React.createContext<AuthenticationContexProps>({
  isAuthenticated: false,
  user: null,
  isLoading: true,
  onLogin: () => {},
  onLogout: () => {},
  getIdentity: () => {},
});

function AuthenticationProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<UserIdentity | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { setPermissions } = useAuthorization();
  const location = useLocation();
  const whiteRoutes = useMemo(() => {
    return ["/login", "/reset-password", "/recover-password"];
  }, []);
  const navigate = useNavigate();

  const getIdentity = useCallback(async () => {
    try {
      const { data } = await axios.get<{
        data: UserIdentity;
        permissions: string[];
      }>(`/auth/identity`);
      setIsAuthenticated(true);
      setUser(data.data);
      setPermissions(data.permissions);
      navigate(
        location.pathname === "/login" || location.pathname === "/"
          ? "/dashboard"
          : location.pathname,
        {
          replace: true,
        }
      );
      //return data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(
          error.response
            ? error.response.data.error
            : `Error retrieving user identity`
        );
        if (error.response?.status === 401)
          navigate("/login", {
            replace: true,
            state: {
              error: error.response
                ? error.response.data.error
                : `Error retrieving user identity`,
            },
          });
      } else {
        console.error(`Error retrieving user identity`);
        navigate("/login", {
          replace: true,
          state: { error: "Error retrieving user identity" },
        });
      }
    }
    setIsLoading(false);
  }, [location.pathname, navigate, setPermissions]);

  useEffect(() => {
    if (!whiteRoutes.includes(location.pathname)) getIdentity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogin = async (
    email: string,
    password: string,
    erroCallback?: (error: string) => void
  ) => {
    try {
      const { status } = await axios.post("/auth/login", {
        email,
        password,
      });
      if (status === 200) {
        getIdentity();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (erroCallback)
          erroCallback(
            error.response
              ? error.response.data.error
              : `Error during login process`
          );
      } else {
        if (erroCallback) erroCallback(`Error during login process`);
      }
    }
  };

  const onLogout = async () => {
    try {
      const { status } = await axios.post("/auth/logout");
      if (status === 200) {
        setIsAuthenticated(false);
        setUser(null);
        navigate("/login", { replace: true });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.error(
          error.response
            ? error.response.data.error
            : `Error during logout process`
        );
      } else {
        console.error(`Error during login process`);
      }
    }
  };

  const value: AuthenticationContexProps = {
    isAuthenticated,
    user,
    isLoading,
    onLogin,
    onLogout,
    getIdentity,
  };

  return (
    <AuthenticationContex.Provider value={value}>
      {children}
    </AuthenticationContex.Provider>
  );
}

export default AuthenticationProvider;

export const useAuthentication = () => {
  const auth = useContext(AuthenticationContex);
  return auth;
};
