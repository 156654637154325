import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { BrowserRouter } from "react-router-dom";
import "@fontsource/public-sans";
import AuthenticationProvider from "context/AuthenticationContext";
import AuthorizationProvider from "context/AuthorizationContext";
import DialogManagerProvider from "context/DialogManagerContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { it } from "date-fns/locale";
import { JoyToaster } from "components/styled_components";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/ErrorFallback";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window.location.reload()}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
          <BrowserRouter>
            <JoyToaster />
            <DialogManagerProvider>
              <AuthorizationProvider>
                <AuthenticationProvider>
                  <App />
                </AuthenticationProvider>
              </AuthorizationProvider>
            </DialogManagerProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </ErrorBoundary>
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
