import React from "react";
import { alpha } from "@mui/material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Modal,
  ModalDialog,
  ModalProps,
  Typography,
} from "@mui/joy";
import { useAuthentication } from "context/AuthenticationContext";
import { useTranslation } from "react-i18next";
import { useAuthorization } from "context/AuthorizationContext";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useNavigate } from "react-router-dom";

interface MobileUserMenuProps extends Omit<ModalProps, "children"> {}

const MobileUserMenu: React.FC<MobileUserMenuProps> = (props) => {
  const { user, onLogout } = useAuthentication();
  const { isSomeAllowed } = useAuthorization();
  const [t] = useTranslation();
  const navigate = useNavigate();

  const renderAvatarText = () => {
    return user?.email[0].toUpperCase();
  };

  return (
    <Modal
      {...props}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: (t) => alpha(t.palette.background.backdrop, 0.2),
            backdropFilter: "none",
          },
        },
      }}
    >
      <ModalDialog
        variant="plain"
        sx={(theme) => ({
          [theme.breakpoints.only("xs")]: {
            top: 220,
          },
        })}
      >
        <Box
          width={"100%"}
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
          boxSizing={"border-box"}
          rowGap={1}
        >
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            flexDirection="column"
          >
            <Avatar
              sx={{ "--Avatar-size": "55px", mb: 1, fontSize: "1.6rem" }}
              color="primary"
            >
              {renderAvatarText()}
            </Avatar>
            <Typography level="body1">
              {user!.firstName ? user!.firstName : ""}
              {user!.lastName ? user!.lastName : ""}
            </Typography>
            <Typography level="body3">{user!.email}</Typography>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("/account-settings");
              if (props.onClose) props.onClose({}, "escapeKeyDown");
            }}
          >
            {t("Manage your account")}
          </Button>
          {isSomeAllowed(["artists.write", "users.read", "locations.write"]) ? (
            <Button
              variant="plain"
              color="neutral"
              startDecorator={<AdminPanelSettingsIcon />}
              onClick={() => {
                navigate("/admin/users");
                if (props.onClose) props.onClose({}, "escapeKeyDown");
              }}
            >
              {t("Admin Panel")}
            </Button>
          ) : null}
          <Divider inset="context" />
          <Box textAlign={"center"}>
            <Button
              variant="plain"
              color="neutral"
              size="sm"
              startDecorator={<ExitToAppIcon />}
              onClick={onLogout}
            >
              {t("Logout")}
            </Button>
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default MobileUserMenu;
